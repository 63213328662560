.footer {
  display: flex;
  justify-content: center;
  padding: 100px 0 80px;
  background-color: #ffffff;
  font-family: 'Inter', sans-serif;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(224, 224, 224, 0.5);
  }

  .footer-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0 200px;
    gap: 80px;

    @media screen and (max-width: 1440px) {
      margin: 0 160px;
    }

    @media screen and (max-width: 1370px) {
      margin: 0 120px;
    }

    @media screen and (max-width: 1280px) {
      margin: 0 80px;
    }

    @media screen and (max-width: 1200px) {
      margin: 0 60px;
      gap: 60px;
    }

    @media screen and (max-width: 1024px) {
      margin: 0 40px;
      gap: 40px;
    }

    @media screen and (max-width: 768px) {
      margin: 0 24px;
      gap: 40px;
    }

    @media screen and (max-width: 640px) {
      margin: 0 24px;
    }
  }

  .footer__social-media {
    width: 20%;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .footer__logo {
      height: 42px;
      min-width: 140px;
      width: auto;
      margin-bottom: 1rem;
      object-fit: contain;
    }

    .footer__icons {
      display: flex;
      gap: 0.25rem;
      margin-bottom: 3.5rem;

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 50px;
        border-radius: 10px;
        text-decoration: none;

        &:hover {
          background-color: #D3D3D3;
          transition: background-color 0.3s ease;
          .social-icon { color: black; }
        }
      }
    }

    .dropdown {
      width: 150px;
      margin-bottom: 1rem;
    }

    p {
      color: #666;
      font-size: 0.9rem;
      margin: 0.4rem 0;
    }

    @media screen and (max-width: 768px) {
      min-width: 140px;
      .footer__logo {
        height: 42px;
        min-width: 140px;
      }
    }
  }

  .footer__links {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(3, minmax(140px, auto));
    column-gap: 60px;
    padding-left: 60px;
    margin-left: auto;

    div {
      display: flex;
      flex-direction: column;
      padding-right: 0;
      min-width: 0;

      &:first-child {
        min-width: 180px;
        flex-grow: 1;
      }

      h4 {
        color: #1F2937;
        font-size: 1.25rem;
        margin-bottom: 1.5rem;
        white-space: nowrap;
        font-weight: 600;
        padding-bottom: 4px;
      }

      ul {
        li {
          margin: 0.85rem 0;

          a {
            color: #4B5563;
            text-decoration: none;
            font-size: 16px;
            line-height: 1.5;
            white-space: nowrap;
            transition: color 0.2s ease;
            display: inline-block;
            width: auto;
            overflow: visible;
            text-overflow: clip;

            &:hover {
              color: #0033A0;
              text-decoration: underline;
              text-decoration-color: #0033A0;
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
      padding-left: 0;
      flex-wrap: wrap;
      gap: 2rem;

      div {
        width: calc(50% - 1rem);
      }
    }

    .coming-soon-tag {
      font-size: 12px;
      color: #0033A0;
      background: rgba(0, 51, 160, 0.1);
      padding: 2px 8px;
      border-radius: 12px;
      margin-left: 8px;
      white-space: nowrap;
    }

    span {
      color: #4B5563;
      font-size: 15px;
      cursor: default;
    }

    li {
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        margin: 0.6rem 0;
      }

      .coming-soon-tag {
        font-size: 11px;
        color: #0033A0;
        background: rgba(0, 51, 160, 0.1);
        padding: 2px 8px;
        border-radius: 12px;
        width: fit-content;
        margin: 0;
        letter-spacing: -0.1px;
        line-height: 16px;
      }

      .feature-text {
        color: #4B5563;
        font-size: 15px;
        cursor: default;
        margin: 0;
        padding: 0;
        line-height: 20px;
      }

      span {
        margin: 0;
        padding: 0;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 75%;
      grid-template-columns: minmax(180px, 1.2fr) minmax(140px, 1fr) minmax(120px, 0.8fr);
      column-gap: 40px;
      padding-left: 40px;
    }

    @media screen and (max-width: 1024px) {
      width: 80%;
      column-gap: 32px;
      padding-left: 32px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
      padding-left: 0;
    }

    @media screen and (max-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  @media (max-width: 480px) {
    padding: 60px 0;
    
    .footer__links {
      gap: 1.5rem;
      
      h4 { 
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }
      a { font-size: 0.95rem; }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 80px 0 60px;

    .footer-content-wrapper {
      margin: 0 24px;
      gap: 48px;
      flex-direction: column;
    }

    .footer__social-media {
      width: 100%;
      min-width: auto;
      align-items: flex-start;
      order: 1;

      .footer__logo {
        height: 45px;
        width: auto;
        min-width: 160px;
      }

      .footer__icons {
        margin-bottom: 2.5rem;
      }

      .dropdown {
        width: 180px;
      }
    }

    .footer__links {
      width: 100%;
      order: 2;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
      padding-left: 0;

      div {
        h4 {
          font-size: 1.2rem;
          margin-bottom: 1.2rem;
        }

        ul li {
          margin: 0.75rem 0;
          
          a {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .footer__links {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 24px;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 60px 0;
    
    .footer__links {
      grid-template-columns: 1fr;
      gap: 32px;
      
      div {
        text-align: left;
        
        h4 { 
          font-size: 1.15rem;
          margin-bottom: 0.8rem;
        }
        
        ul li {
          margin: 0.7rem 0;
        }
      }
    }
  }
}

