// Colors
$primary-color: #2C3E50;  // deep navy color
$secondary-color: #E74C3C;  // bright red

// Typography
// $base-font: 'Poppins', sans-serif;
// $heading-font: 'Montserrat', sans-serif;

$base-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$heading-font: $base-font;


// $app-bg-start: #07090d;
// $app-bg-end: #1c2128;

$app-bg-start: #0F111A;
$app-bg-end: #1B1D2A;

$app-header-color: #e4e4e4;
$btn-color: #1da54a;
