// Variables
$border-radius: 8px;  // Reduced roundness for a mature look

.job-tile {
  background: #fff;
  padding: 20px;
  border-radius: $border-radius;  // Uniform border radius
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  position: relative;
  transform: none !important;
  transition: none !important;

  &:hover {
    transform: none !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  }

  .job-type-tag {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px;
    border-radius: $border-radius;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Karla', sans-serif;

    &.full-time {
      background: #D0E2FF;
      color: #003366;
    }

    &.internship {
      background: #D4EDDA;
      color: #155724;
    }

    &.contract {
      background: #FFE5CC;
      color: #CC5500;
    }

    &.part-time {
      background: #EDE0F8;
      color: #4B0082;
    }

    &.demo {
      background: #FFE5E5;
      color: #990000;
      font-family: 'Karla', sans-serif;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .status-dot {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    
    &.recent {
      background-color: #4CAF50;
    }
    
    &.moderate {
      background-color: #FF9800;
    }
    
    &.old {
      background-color: #F44336;
    }
  }

  .company-logo-container {
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    top: 20px;
    left: 40px;
    width: 56px;  // Default square size
    height: 56px;

    // Updated horizontal logo container
    &.horizontal-logo-container {
      width: 72px;  // New width for horizontal logos
      height: 52px; // New height for horizontal logos
    }
  }

  .company-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.horizontal-logo {
      transform: scale(1);
    }
  }

  .job-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 5px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .company-location {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
    padding-left: 0;
    margin-left: 0;
    display: flex;
    align-items: center;

    .additional-locations {
        margin-left: -1px; // Slightly adjust spacing to look more natural
        color: inherit; // Use the same color as the parent
    }
  }

  .salary-placeholder {
    height: 1rem;  // Adjust based on desired space
    margin-bottom: 20px;
  }

  .job-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .apply-button {
      padding: 10px 20px;
      font-size: 1rem;
      color: #fff !important;
      background-color: #0033A0 !important;
      border: none;
      border-radius: $border-radius;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.15s ease;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.15) 0%,
          rgba(255, 255, 255, 0.05) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
      }

      &:hover {
        background-color: #0044CC !important;
        transform: scale(1.02);
        box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
      }

      &.applied {
        background-color: #6c757d !important;
        cursor: default;
        transform: none;

        &:hover {
          background-color: #6c757d !important;
          transform: none;
          box-shadow: none;
        }
      }

      &.demo-button {
        background-color: #28a745 !important;
        font-weight: 500;

        &:hover {
          background-color: #2ebd4e !important;
        }
      }

      &:disabled {
        cursor: default;
        opacity: 0.7;
      }
    }

    .view-details {
      padding: 5px 15px; // Adjusted padding for smaller button
      font-size: 0.9rem; // Slightly smaller font size
      color: #007bff;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #0056b3;

        .arrow {
          transform: translateX(3px);  // Slightly smaller translation on hover
        }
      }

      .arrow {
        margin-left: 5px;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
      }
    }
  }

  @media (min-width: 1024px) {
    max-width: calc(33.33% - 20px) !important;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: calc(50% - 20px) !important;
  }

  @media (max-width: 767px) {
    max-width: 100% !important;
  }
}

// Container for job tiles grid
.job-tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
