@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Roboto:wght@400;700&display=swap");

$color-background: #ffffff;
$color-text: #000000;
$color-navbar: #ffffff;
$color-navbar-text: #161617cc;
$color-button: #0033a0;
$color-button-text: #ffffff;
$font-size-navbar: 16px;
$spacing-container: 200px;
$navbar-height: 88px;
$spacing-small-screen: 50px;

$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1440px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == small {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  }
  @if $breakpoint == medium {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  }
  @if $breakpoint == large {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Karla", sans-serif;
  line-height: 1.6;
  background-color: $color-background;
  color: $color-text;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.navbar {
  position: fixed;
  width: 100%;
  height: $navbar-height;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 200px;

  &.scrolled {
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.blurred {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    pointer-events: none;
    z-index: 1;
  }

  @media screen and (max-width: 1440px) {
    padding: 0 160px;
  }

  @media screen and (max-width: 1370px) {
    padding: 0 120px;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 1100px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

.landing-container {
  width: 100%;
  padding-top: $navbar-height;
  padding-left: 200px;
  padding-right: 200px;
  overflow-x: hidden;
  padding-bottom: 0; /* Ensures no bottom padding */
  margin-bottom: 0; /* Ensures no bottom margin */
  transition: padding 0.3s ease;

  @media screen and (max-width: 1440px) {
    padding-left: 160px;
    padding-right: 160px;
  }

  @media screen and (max-width: 1370px) {
    padding-left: 120px;
    padding-right: 120px;
  }

  @media screen and (max-width: 1280px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.blurred {
    backdrop-filter: blur(5px);
    pointer-events: none;
  }
}

.hero-section,
.landing-footer {
  background-color: $color-background;
  padding-left: 200px;
  padding-right: 200px;
  overflow-x: hidden;

  @media screen and (max-width: 1370px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media screen and (max-width: 1024px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.hero-section {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 100px 0;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 50px 0;
    flex-direction: column;
    gap: 3rem;
  }

  .hero-content {
    width: 100%;
    max-width: 640px;
    margin-bottom: 40px;

    @media screen and (max-width: 1024px) {
      text-align: center;
      padding: 0;
      margin-bottom: 0;
    }
  }

  .hero-image-container {
    width: 680px;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: 1440px) {
      width: 620px;
    }
    
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 580px;
      margin: 0 auto;
    }
    
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0 20px;
      height: auto;
      min-height: 400px;
      transform-origin: center;
      transform: scale(1);
    }

    @media screen and (max-width: 480px) {
      min-height: 380px;
      padding: 0 12px;
    }
  }

  .progress-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-top: -20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 1024px) {
    padding: 50px 0;
    flex-direction: column;
  }
}

.hero-content {
  flex: 0 1 auto;
  max-width: 540px;
  text-align: left;

  @media screen and (max-width: 1024px) {
    text-align: center;
    padding: 0 20px;
    margin-bottom: 20px;
  }
}

.hero-content h1 {
  font-family: "Inter", sans-serif;
  font-size: 64px;
  font-weight: 650;
  margin: 0 0 24px;
  color: $color-text;
  line-height: 1.2;
  letter-spacing: -1px;

  br {
    display: block;
    margin-bottom: 0.5em;
  }

  @media only screen and (max-width: 1440px) {
    font-size: 56px !important;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 48px !important;
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px !important;
  }
  @media only screen and (max-width: 480px) {
    font-size: 28px !important;
  }
}

.hero-content p.enhance-your-application {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 80px;

  @media only screen and (max-width: 1440px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin: 0 0 40px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 13px;
  }
}

.hero-content .cta-btn {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  padding: 1rem 2rem;
  background-color: #ffffff;
  color: #0033a0;
  border: 2px solid #0033a0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 60px;
  margin-bottom: 40px;

  @media screen and (max-width: 1024px) {
    margin: 20px auto;
  }
}

.hero-content .cta-btn:hover {
  background-color: #0033a0;
  color: #ffffff;
}

.hero-image-container {
  position: relative;
  width: 680px;
  height: 420px;
  border-radius: 0;
  overflow: hidden;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  margin: 40px 0;
  margin-left: auto;
  right: 0;

  @media screen and (max-width: 1440px) {
    width: 620px;
    height: 383px;
  }

  @media screen and (max-width: 1024px) {
    width: 580px;
    height: 358px;
    margin: 30px auto;
  }

  @media screen and (max-width: 768px) {
    width: min(520px, calc(100vw - 64px));
    height: calc((min(520px, calc(100vw - 64px)) * 0.618));
    margin: 24px auto;
  }

  .progress-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
    img {
      width: 100%;
      max-width: 1502px;
      height: auto;
    }
  }
}
.credibility-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $color-background;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  // padding: 40px 0;
  margin-top: 0;

  @include respond-to(small) {
    padding: 20px 0;
  }
}

.interviews-heading {
  font-family: "Karla", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #63727e;
  padding: 20px;
  margin: 0 auto;
  max-width: 80%;

  @include respond-to(medium) {
    font-size: 18px;
  }

  @include respond-to(small) {
    font-size: 16px;
  }
}

.demo-section {
  width: 100%;
  text-align: center;
  padding: 100px 0;
  padding-left: 0;
  padding-right: 0;

  .demo-image {
    width: 100%;
    max-width: 100%; // Ensures the image takes full width
    margin: 0; // Remove all margins
    padding: 0; // Remove padding if any
    border-radius: 8px;
  }
}

.landing-footer {
  padding-top: var(--spacing-large);
  padding-bottom: var(--spacing-large);
  background-color: $color-background;
  color: $color-text;
  text-align: center;
}

:root {
  --spacing-large: 40px;
  --font-size-large: 3rem;
  --font-size-medium: 1.5rem;
  --font-size-small: 1rem;
}

.features-section {
  padding: 40px 0;
  background-color: $color-background;
  text-align: center;

  @include respond-to(small) {
    padding: 20px 0;
  }
}

.features-heading {
  font-family: "Karla", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -1px;
  margin-bottom: 40px;
  text-align: center;

  @include respond-to(medium) {
    font-size: 48px;
  }

  @include respond-to(small) {
    font-size: 32px;
  }
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;

  @include respond-to(small) {
    flex-direction: column;
    gap: 20px;
  }
}

.feature {
  flex: 1;
  text-align: left;
  padding: 20px;
  min-width: 250px;

  @include respond-to(small) {
    min-width: 100%;
  }

  .feature-icon {
    font-size: 48px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
  }

  h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: left;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    color: #63727e;
    text-align: left;
  }
}

/* Updated Feature Highlight section */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.feature-highlight-section {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;
  background-color: $color-background;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  position: relative;
  padding-left: 200px;
  padding-right: 200px;
  max-width: 100%;
  gap: 0;

  @media screen and (max-width: 1440px) {
    padding-left: 160px;
    padding-right: 160px;
  }

  @media screen and (max-width: 1370px) {
    padding-left: 120px;
    padding-right: 120px;
  }

  @media screen and (max-width: 1280px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 24px 16px;
    gap: 16px;
    overflow: visible;
  }
}

.scrolling-content {
  flex: 0 0 40%;
  overflow-y: auto;
  padding-right: 0;
  max-width: 512px;
  margin-right: auto;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    flex: 0 0 42%;
  }

  @media screen and (max-width: 1280px) {
    flex: 0 0 45%;
  }

  @media screen and (min-width: 851px) and (max-width: 1024px) {
    flex: 0 0 45%;
    max-width: 400px;
    padding-right: 40px;
  }

  @media screen and (min-width: 769px) and (max-width: 850px) {
    flex: 0 0 42%;
    max-width: 340px;
    padding-right: 32px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex: 1;
    max-width: 100%;
    margin-right: 0;
    overflow: visible;
    padding-right: 0;
  }
}

.feature-section {
  height: 640px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 512px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  text-align: left;

  @media screen and (min-width: 851px) and (max-width: 1024px) {
    height: 540px;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 80px;
    max-width: 400px;
  }

  @media screen and (min-width: 769px) and (max-width: 850px) {
    height: 480px;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 60px;
    max-width: 340px;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 32px 0;
    margin-bottom: 48px;
    max-width: 100%;
    opacity: 1;
    transform: none;
    transition: none;
    text-align: center;
  }

  &.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
    margin-bottom: 24px;
    
    @media screen and (min-width: 851px) and (max-width: 1024px) {
      font-size: 1.75rem;
      margin-bottom: 20px;
      line-height: 1.3;
    }
    
    @media screen and (min-width: 769px) and (max-width: 850px) {
      font-size: 1.5rem;
      margin-bottom: 16px;
      line-height: 1.3;
    }
    
    @media screen and (max-width: 768px) {
      font-size: 1.75rem;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  p {
    font-size: 1.25rem;
    text-align: left;
    color: #63727e;
    
    @media screen and (min-width: 851px) and (max-width: 1024px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
    
    @media screen and (min-width: 769px) and (max-width: 850px) {
      font-size: 1rem;
      line-height: 1.5;
    }
    
    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
      margin-bottom: 24px;
      text-align: center;
    }
  }
}

.static-image-container {
  position: fixed;
  top: 0;
  right: 200px;
  width: calc(50% - 200px);
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
  z-index: 0;

  @media screen and (max-width: 1440px) {
    right: 160px;
    width: calc(50% - 160px);
  }

  @media screen and (max-width: 1370px) {
    right: 120px;
    width: calc(50% - 120px);
  }

  @media screen and (max-width: 1280px) {
    right: 80px;
    width: calc(50% - 80px);
  }

  @media screen and (max-width: 1200px) {
    right: 50px;
    width: calc(50% - 50px);
  }

  @media screen and (max-width: 1100px) {
    right: 40px;
    width: calc(50% - 40px);
  }

  @media screen and (min-width: 851px) and (max-width: 1024px) {
    right: 32px;
    width: calc(52% - 32px);
    
    .feature-image {
      width: 360px;
      margin-right: 32px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 850px) {
    right: 32px;
    width: calc(54% - 32px);
    
    .feature-image {
      width: 320px;
      margin-right: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    display: none; // Hide the fixed container on mobile
  }

  .feature-image {
    width: 520px;
    height: auto;
    object-fit: contain;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
    opacity: 1;
    margin-right: 0;

    @media screen and (max-width: 1440px) {
      width: 480px;
    }

    @media screen and (max-width: 1280px) {
      width: 440px;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      width: 400px;
    }
  }

  &.scrolling .feature-image {
    transition: transform 0s linear;
    transform: translateY(calc(-50% + var(--scroll-y)));
  }
}

/* CarouselButtons.scss */

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.image-display {
  width: 77vw; // 77% of the viewport width
  height: auto; // Adjust height automatically to maintain aspect ratio
  max-height: 40vw; // Maximum height to prevent the image from becoming too tall
  border: 2px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto; // Maintain the aspect ratio of the image
    object-fit: cover;
  }
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.carousel-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* White background by default */
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 12px; /* Rounded corners */
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 800;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: none; /* No shadow by default */

  .icon {
    margin-right: 10px;
  }
}

.carousel-button:hover {
  background-color: rgba(0, 51, 160, 0.1); /* 10% opacity */
}

.carousel-button.active {
  background-color: rgba(0, 51, 160, 0.1); /* 10% opacity */
  border: 1px solid #ddd;
}

.resume-highlight-container {
  width: 580px;
  height: 762px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-10px);
  border: none;
  border-radius: 0;
  background: transparent;

  @media screen and (max-width: 1440px) {
    transform: translate(-50%, -50%) translateY(-10px) scale(0.9);
  }

  @media screen and (max-width: 1024px) {
    transform: translate(-50%, -50%) translateY(-10px) scale(0.8);
  }

  @media screen and (max-width: 768px) {
    transform: translate(-50%, -50%) translateY(-10px) scale(0.67);
  }
}

.hero-image-wrapper {
  width: 100%;
  height: 100%;

  .resumeBackground {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// Add new styles for mobile images
.feature-mobile-image {
  display: none;
  width: 100%;
  margin-top: 24px;
  
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0 16px;
    
    img {
      width: 100%;
      max-width: 400px;
      height: auto;
      margin: 0 auto;
      display: block;
      border-radius: 0;
      box-shadow: none;
      border: none;
      transition: none;
    }
  }
}

// Keep text styles consistent
.feature-text {
  text-align: left;
  
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  
  h2, p {
    text-align: inherit;
  }
}

@media screen and (max-width: 768px) {
  .feature-text, .feature-mobile-image {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    will-change: opacity, transform;

    &.animate {
      opacity: 0;
      transform: translateY(20px);
    }

    &.animate.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Stagger delays for mobile animations
  .feature-section {
    &:nth-child(1) {
      .feature-text { transition-delay: 0s; }
      .feature-mobile-image { transition-delay: 0.1s; }
    }
    &:nth-child(2) {
      .feature-text { transition-delay: 0.2s; }
      .feature-mobile-image { transition-delay: 0.3s; }
    }
    &:nth-child(3) {
      .feature-text { transition-delay: 0.4s; }
      .feature-mobile-image { transition-delay: 0.5s; }
    }
    &:nth-child(4) {
      .feature-text { transition-delay: 0.6s; }
      .feature-mobile-image { transition-delay: 0.7s; }
    }
    &:nth-child(5) {
      .feature-text { transition-delay: 0.8s; }
      .feature-mobile-image { transition-delay: 0.9s; }
    }
  }
}