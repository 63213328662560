$dropdown-border-color: #ccc;
$dropdown-bg-color: #fff;
$dropdown-hover-bg-color: #f0f0f0;
$dropdown-arrow-color: #333;

.dropdown {
  position: relative;
  width: 10px;

  &-header {
    padding: 8px 12px;
    border: 1px solid $dropdown-border-color;
    background-color: $dropdown-bg-color;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $dropdown-hover-bg-color;
    }
  }

  &-content {
    display: flex;
    align-items: center; /* Aligns the icon and text in the center vertically */
  }

  &-icon {
    margin-right: 8px;
  }

  &-arrow {
    // content: '▼'; /* Custom arrow */
    font-size: 40px;
    position: absolute;
    right: 8px;
    bottom: 10px;
    pointer-events: none;
    transition: transform 0.3s ease;

    &.open {
      transform: rotate(180deg); /* Rotate the arrow when the dropdown is open */
    }
  }

  &-list {
    visibility: hidden;
    position: absolute;
    left: 0;
    bottom: 100%; /* Open the dropdown upwards */
    width: 300px;
    background-color: $dropdown-bg-color;
    border: 1px solid $dropdown-border-color;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    /* Limit the dropdown height to 5 items */
    max-height: 400px; /* Assuming each item is about 40px tall */
    overflow-y: auto; /* Enable vertical scrolling */
    transition: visibility 0s, max-height 0.3s ease;
    z-index: 10;
  }

  &.open .dropdown-list {
    visibility: visible;
    max-height: 400px; /* This ensures only 5 items are visible */
    overflow-y: auto; /* Vertical scrolling enabled */
  }

  &-list-item {
    background-color: $dropdown-bg-color;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $dropdown-hover-bg-color;
    }
  }
  .dropdown-item-label{
  font-weight: 400; /* Bold */
    font-size: 16px;
    color: #000; /* Black color */
  }

  .dropdown-item-description {
    font-weight: 400; /* Regular weight */
    font-size: 14px;
    color: #888; /* Gray color */
  }
}
