.suggestions-container {
  width: 100% !important;
  height: calc(100vh - 160px);
  border: 1px solid #000000; // Changed from #e0e0e0 to #000000
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: none !important;
  margin-bottom: 16px; // Add space between container and buttons
  box-sizing: border-box; // Ensure padding is included in width calculation

  // Move all top-level & selectors inside here
  &.full-screen-mode {
    padding-top: 15px;
  }

  .suggestions-scroll {
    flex-grow: 1;
    width: calc(100% + 20px) !important; // Extend to include padding
    margin-right: -20px; // Pull back to align with container edge
    overflow-y: auto;
    height: calc(100% - 90px);
    position: relative;
    box-sizing: border-box;
    padding-right: 20px; // Add padding to content area

    // Scrollbar styling
    &::-webkit-scrollbar {
      width: 6px;
      position: absolute;
      right: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 4px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 51, 160, 0.5); // #0033a0 with 50% opacity
      border-radius: 20px;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    // Only show scrollbar when actually scrolling
    &.scrolling::-webkit-scrollbar-thumb {
      opacity: 1;
    }

    // Firefox support
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    &.scrolling {
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 51, 160, 0.5) transparent; // Match WebKit color
    }

    // Content wrapper with consistent padding
    .AccordionRoot {
      width: 100%;
      box-sizing: border-box;
      padding: 0; // Remove the extra padding
    }
  }

  .suggestions-buttons {
    display: flex !important;
    gap: 16px !important;
    padding: 20px 0 !important;
    margin-top: auto !important;
    background-color: white !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: space-between !important;

    .suggestion-btn, .suggestion-sec-btn {
      flex: 1 !important;  // Make both buttons take equal width
      height: 36px !important;
      padding: 0 20px !important;
      font-size: 14px !important;
      min-width: 120px !important;
      font-family: 'Inter', sans-serif !important;
      margin: 0 !important;
    }

    .suggestion-btn {
      border: 1px solid #0033a0 !important;
      color: #0033a0 !important;
      background-color: white !important;
      border-radius: 4px !important;

      &:hover {
        background-color: #0033a0 !important;
        color: white !important;
      }
    }

    .suggestion-sec-btn {
      border: none !important;
      background: none !important;
      color: #0033a0 !important;
    }
  }

  .loading-state {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; // Align to the left like actual content
    justify-content: flex-start; // Align to the top like actual content
    // padding: 20px;
    background-color: #fff; // Match the actual container background

    .suggestions-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 25px !important;
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;
      padding-top: 0;
      padding-bottom: 10px;

      .ai-icon {
        margin-right: auto;
      }

      .progress-circle {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        min-width: 70px;

        .loading-circle {
          position: relative;
          margin-left: auto;

          .loading-dots {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            gap: 5px;

            .dot {
              width: 8px;
              height: 8px;
              background-color: #cccccc;
              border-radius: 50%;
              animation: wave 0.8s ease-in-out infinite;

              &:nth-child(1) {
                animation-delay: 0s;
              }
              &:nth-child(2) {
                animation-delay: 0.1s;
              }
              &:nth-child(3) {
                animation-delay: 0.2s;
              }
            }
          }
        }
      }
    }

    .skeleton-score {
      margin-bottom: 20px;
    }

    .skeleton-suggestions {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: transparent; // Ensure parent has no background

      .skeleton-suggestion {
        padding: 1em;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        background-color: #ffffff !important;
        width: 100%;
        box-shadow: none;
        animation: pulse 1s ease-in-out infinite;

        .skeleton-line {
          background: #f0f0f0;
          height: 16px;
          border-radius: 4px;
          margin-bottom: 10px;

          &.long {
            width: 80%;
            margin-bottom: 12px; // Slightly larger gap between lines
          }

          &.short {
            width: 40%;
            margin-bottom: 0; // No margin on last line
          }
        }
      }
    }
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0033a0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .accordion-item {
    padding: 1em;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    .accordion-trigger {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      padding: 0 40px 0 0; // Keep this the same

      .suggestion-text {
        flex: 1;
        text-align: left;
        line-height: 1.5;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        font-family: 'Inter', sans-serif;
        // padding-right: 45px; // Keep this the same
        
        &[data-state='closed'] {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .dropdown-icon {
        position: absolute;
        right: 0px; // Reduced from 8px to 4px to push even more right
        top: 4px;
        width: 18px;
        height: 18px;
        color: #666;
        pointer-events: none;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); // Smoother rotation
      }

      &[data-state='open'] {
        .dropdown-icon {
          transform: rotate(180deg);
        }
      }
    }

    // Closed state
    &[data-state='closed'] {
      .suggestion-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    // Open state
    &[data-state='open'] {
      .dropdown-icon {
        transform: rotate(180deg);
      }
      
      .suggestion-text {
        white-space: normal;
        overflow: visible;
      }
    }

    .accordion-content {
      overflow: hidden;
      padding-top: 12px;

      font-family: 'Inter', sans-serif;  // Add Inter font

      .button-container {
        display: flex;
        gap: 16px;
        padding-top: 5px;
        padding-bottom: 0; // Remove bottom padding
        margin-bottom: 0; // Remove bottom margin

        button {
          height: 36px;
          padding: 0 20px;
          font-size: 14px;
          min-width: 85px;
          cursor: pointer;
          font-family: 'Inter', sans-serif;  // Add Inter font
          font-weight: 500;
          margin-bottom: 0; // Remove any bottom margin
            
          &.suggestion-btn {
            background-color: white;
            color: #0033a0;
            border: 1px solid #0033a0;
            border-radius: 4px;
            transition: all 0.2s ease;
            margin: 0; // Remove all margins

            &:hover {
              background-color: #0033a0;
              color: white;
            }
          }

          &.suggestion-secondary-btn {
            background: none;
            border: none;
            color: #666;
            padding: 0 20px;
            margin: 0; // Remove all margins
          }
        }
      }
    }
  }

  // Override Radix UI's default styles
  &[data-state='open'] {
    .dropdown-icon {
      transform: rotate(180deg) !important; // Force rotation
    }
  }

  .action-buttons-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;  // Two equal columns
    gap: 16px;
    padding: 20px;
    width: 100%;
    background-color: white;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #e0e0e0;

    .action-btn {
      height: 40px;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s ease;
      
      &.primary {
        background-color: white;
        color: #0033a0;
        border: 1px solid #0033a0;

        &:hover {
          background-color: #0033a0;
          color: white;
        }
      }
      
      &.secondary {
        background-color: transparent;
        color: #0033a0;
        border: none;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

// Remove any fixed widths from child elements
.accordion-item,
.AccordionItem {
  width: 100% !important;
  max-width: none !important;
}

.suggestion-btn {
  color: #0033a0;
  padding: 1rem;
  border: 1px solid #0033a0;
  border-radius: 10px;
  margin-bottom: 1em;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.suggestion-sec-btn {
  color: #0033a0;
  padding: 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

// Add to existing styles
.score-preview {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  
  .score {
    font-size: 18px;
    font-weight: bold;
    color: #216624;
  }
}

// Adjust existing styles for the expanded sidebar
.suggestions-container {
  height: 100%;
  
  // Add when in sidebar mode
  .expanded-sidebar & {
    padding-top: 40px; // Make room for close button
  }
}

// Pulse animation for loading effect
@keyframes pulse {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #f8f8f8;
  }
  100% {
    background-color: #ffffff;
  }
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

// Override Radix UI's accordion styles for smooth animation
.AccordionContent {
  overflow: hidden;

  &[data-state='open'] {
    animation: slideDown 300ms ease-out;
  }

  &[data-state='closed'] {
    animation: slideUp 300ms ease-out;
  }
}

// Clean animations for expanding/collapsing
@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

// Smooth arrow rotation
.accordion-trigger {
  .dropdown-icon {
    transition: transform 300ms ease;
  }

  &[data-state='open'] {
    .dropdown-icon {
      transform: rotate(180deg);
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .suggestions-container {
    height: calc(100vh - 140px);
  }

  .action-buttons-grid {
    gap: 12px;
    
    .action-btn {
      font-size: 13px;
      height: 36px;
    }
  }
}

// Keep all existing styles and add this new section for the action buttons

.action-buttons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
  margin-top: auto;

  .action-btn {
    height: 40px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0 20px;
    
    &.primary {
      background-color: white;
      color: #0033a0;
      border: 1px solid #0033a0;
      font-weight: 500;

      &:hover {
        background-color: #0033a0;
        color: white;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    
    &.secondary {
      background-color: white;
      color: #0033a0;
      border: 1px solid #0033a0;
      font-weight: 500;

      &:hover {
        background-color: #0033a0;
        color: white;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

// Add to existing media queries
@media (max-width: 768px) {
  .action-buttons-grid {
    gap: 12px;
    padding: 0 10px;
    
    .action-btn {
      font-size: 13px;
      height: 36px;
      padding: 0 15px;
    }
  }
}
