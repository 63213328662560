@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.resume-features-container {
  display: grid;
  grid-template-columns: 30% 70%;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  overflow: hidden;
  padding: 48px;
  column-gap: 64px;
}

.features-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.feature-item {
  position: relative;
  padding: 16px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 0px;
  border: none;
  background-color: transparent;
  text-align: left;
  
  &:hover {
    background-color: transparent;
    
    .feature-title {
      color: #1a73e8;
    }
  }
  
  &.active {
    background-color: transparent;
    
    .feature-title {
      color: #1a73e8;
      font-weight: 600;
    }
    
    .feature-description {
      max-height: 200px;
      opacity: 1;
      margin-top: 12px;
      transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    }
  }
  
  .feature-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 100%;
  }
  
  .feature-title {
    font-size: 22px;
    font-weight: 500;
    color: #202124;
    margin: 0;
    letter-spacing: -0.02em;
    line-height: 1.3;
    text-align: left;
    transition: color 0.3s ease;
  }
  
  .feature-description {
    font-size: 16px;
    line-height: 1.6;
    color: #5f6368;
    margin: 0;
    font-weight: 400;
    text-align: left;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: visible;
    transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1);
    transform-origin: top;
  }
}

.feature-content-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  width: 100%;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.feature-content {
  width: 100%;
  height: auto;
  min-height: 500px;
  max-width: 800px;
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(236, 242, 254, 0.8) 0%,
    rgba(232, 240, 254, 0.9) 50%,
    rgba(230, 238, 254, 0.8) 100%
  );
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(60, 64, 67, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 40px;
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: linear-gradient(
      135deg,
      rgba(0, 51, 160, 0.02) 0%,
      rgba(97, 97, 245, 0.08) 50%,
      rgba(0, 51, 160, 0.02) 100%
    );
    transform: rotate(15deg);
    pointer-events: none;
    animation: subtleShift 15s infinite linear;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(0, 51, 160, 0.03) 0%,
      rgba(103, 58, 183, 0.05) 50%,
      rgba(0, 51, 160, 0.03) 100%
    );
    border-radius: 12px;
    pointer-events: none;
  }
  
  @keyframes subtleShift {
    0% {
      transform: rotate(15deg) translateY(0);
    }
    50% {
      transform: rotate(15deg) translateY(2%);
    }
    100% {
      transform: rotate(15deg) translateY(0);
    }
  }
}

.feature-content-container {
  position: relative;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s ease, transform 0.4s ease;
  
  &.entering {
    opacity: 0;
    transform: translateY(10px);
  }
}

.feature-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    rgba(0, 51, 160, 0.1) 0%,
    rgba(103, 58, 183, 0.15) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(26, 115, 232, 0.2);
  position: relative;
  
  &.editable-icon::after {
    content: '';
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" fill="%231a73e8"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  &.tailor-icon::after {
    content: '';
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" fill="%231a73e8"><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1.99 6H7V7h10.01v2zm0 4H7v-2h10.01v2zm-3 4H7v-2h7.01v2z"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  &.suggestions-icon::after {
    content: '';
    width: 40px;
    height: 40px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" fill="%231a73e8"><path d="m9 16.2-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.content-title {
  font-size: 28px;
  font-weight: 600;
  color: #202124;
  margin: 0;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 8px;
}

.content-description {
  font-size: 18px;
  line-height: 1.6;
  color: #5f6368;
  margin: 0;
  font-weight: 400;
  text-align: center;
  max-width: 90%;
}

/* Responsive styles for different screen sizes */
@media (max-width: 1024px) {
  .resume-features-container {
    padding: 24px;
    grid-template-columns: 30% 70%;
    column-gap: 40px;
  }
  
  .content-title {
    font-size: 24px;
  }
  
  .content-description {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .resume-features-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding: 16px;
    row-gap: 32px;
  }
  
  .features-column {
    display: flex;
    flex-direction: column;
    overflow-x: visible;
    overflow-y: visible;
    white-space: normal;
    padding: 24px 16px;
    margin-bottom: 24px;
    justify-content: flex-start;
    gap: 24px;
    margin: 0 auto;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
    
    &::-webkit-scrollbar {
      height: 4px;
      width: auto;
    }
  }
  
  .feature-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 0;
    margin-right: 0;
    border-radius: 0px;
    background-color: transparent;
    width: 100%;
    text-align: left;
    
    &.active {
      background-color: transparent;
      border-left: none;
      border-bottom: 2px solid #1a73e8;
      
      .feature-description {
        max-height: 150px;
        opacity: 1;
        margin-top: 12px;
      }
    }
    
    .feature-title {
      font-size: 18px;
      margin: 0;
      white-space: normal;
      text-align: left;
    }
    
    .feature-description {
      display: block;
      font-size: 14px;
    }
  }
  
  .feature-content-column {
    padding: 0 8px;
    margin: 0 auto;
    max-width: 100%;
  }
  
  .feature-content {
    padding: 24px;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    
    .feature-icon {
      width: 60px;
      height: 60px;
      
      &::after {
        width: 30px;
        height: 30px;
      }
    }
  }
  
  .content-title {
    font-size: 20px;
  }
  
  .content-description {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .feature-item {
    padding: 8px 12px;
    margin-right: 6px;
    
    .feature-title {
      font-size: 16px;
    }
    
    .feature-description {
      font-size: 12px;
    }
  }
  
  .feature-content {
    padding: 16px;
  }
  
  .content-title {
    font-size: 18px;
  }
  
  .content-description {
    font-size: 13px;
  }
} 