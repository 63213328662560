.empty-job-details {
  background: #f9fafb;
  border: 1px dashed #e5e7eb;
  border-radius: 8px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  .empty-state {
    text-align: center;
    
    .icon {
      font-size: 48px;
      color: #9ca3af;
      margin-bottom: 16px;
    }
    
    h4 {
      font-size: 18px;
      font-weight: 600;
      color: #374151;
      margin-bottom: 8px;
    }
    
    p {
      color: #6b7280;
      font-size: 14px;
      max-width: 240px;
      margin: 0 auto;
    }
  }
} 