/* FileUploader.scss */
.file-uploader-container {
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
    text-align: left;
  
    .dropzone {
      border: 2px dashed #E5E7EB;
      border-radius: 12px;
      padding: 2.5rem;
      background: #FFFFFF;
      cursor: pointer;
      transition: all 0.2s ease;
      text-align: center;
      
      &:hover {
        border-color: #6C63FF;
        background: #FAFAFA;
      }
      
      &.active {
        border-color: #6C63FF;
        background: #F5F5FF;
        border-style: dashed;
      }

      &.has-file {
        border-style: solid;
        border-color: #10B981;
        background: #F0FDF4;
      }
    }

    .upload-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      .upload-icon-wrapper {
        width: 48px;
        height: 48px;
        color: #6B7280;

        svg {
          width: 100%;
          height: 100%;
          transition: all 0.2s ease;
        }

        &.success {
          color: #10B981;
        }
      }

      .upload-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;

        .main-text {
          font-size: 1.25rem;
          color: #374151;
          font-weight: 500;
        }

        .sub-text {
          font-size: 1rem;
          color: #6B7280;
        }
      }

      .upload-button {
        padding: 0.75rem 2rem;
        font-size: 1.1rem;
        font-weight: 600;
        color: white;
        background-color: #6C63FF;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: #5A52D0;
          transform: translateY(-1px);
        }
      }

      .file-types {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        font-size: 1rem;
        color: #6B7280;

        .file-type {
          color: #374151;
          font-weight: 500;
        }
      }
    }

    .upload-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .status-content {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .upload-icon-wrapper {
        width: 32px;
        height: 32px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .upload-text {
        .filename {
          font-size: 1.1rem;
          color: #111827;
          font-weight: 500;
        }

        .file-size {
          font-size: 0.9rem;
          color: #6B7280;
          margin-left: 0.75rem;
        }
      }

      .change-file {
        padding: 0.5rem 1.25rem;
        font-size: 0.9rem;
        font-weight: 500;
        color: #6C63FF;
        background: transparent;
        border: 1px solid #6C63FF;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.2s ease;
        
        &:hover {
          background: #F5F5FF;
          transform: translateY(-1px);
        }
      }
    }
  }
  
  .animate-spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  