.job-search-container {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1001;
  background: #0033A0;

  @media (max-width: 768px) {
    transform: translateX(-100%);
    width: 250px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  }
}

.sidebar.expanded {
  width: 250px;

  @media (max-width: 768px) {
    transform: translateX(0);
  }
}

.job-search {
  margin-left: 80px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
  }

  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 1000px 100%;
    animation: shimmer 3.5s infinite ease-in-out;
    height: 186px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #cccccc;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }

  .job-tile.skeleton {
    width: calc((100% - (2 * 20px)) / 3);
    margin-bottom: 20px;

    @media (max-width: 1200px) {
      width: calc((100% - 20px) / 2);
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.sidebar.expanded + .job-search {
  margin-left: 250px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.filter-container {
  position: fixed;
  top: 0;
  left: 80px;
  right: 0;
  background: transparent;
  padding: 16px 0;
  transition: all 0.3s ease;
  z-index: 99;

  &.scrolled {
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .sidebar.expanded + .job-search & {
    left: 250px;
  }

  @media (max-width: 768px) {
    padding: 12px 16px;
    z-index: 99;
    left: 0;

    .modal-open & {
      z-index: 99;
    }
  }

  .filter-content {
    @media (min-width: 1150px) {
      width: 90%;
      max-width: 1400px;
      display: flex;
      gap: 20px;
      position: relative;
      padding-right: 20px;
      margin: 0 auto;

      .dropdown {
        width: 200px;
        min-width: unset;
        max-width: unset;
        flex: none;

        &:nth-child(3) {
          width: 240px; // Make Industry dropdown slightly wider since it might have longer text
        }

        .dropdown-button {
          width: 100%;
          justify-content: space-between;
          padding: 10px 16px;
        }
      }

      .clear-filters-button {
        margin-left: auto;
        height: 42px;
        padding: 0 24px;
        border-radius: 8px;
        font-size: 0.95rem;
        font-weight: 500;
        transition: all 0.2s ease;
        background: #0033A0;
        color: white;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 51, 160, 0.2);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        &.has-filters {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }

        &:hover:not(:disabled) {
          background-color: #0044CC;
          transform: scale(1.02);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    @media (max-width: 1149px) {
      width: 100%;
      display: flex;
      align-items: flex-end;
      
      .filters-button {
        display: flex;
        margin-left: auto;
        padding: 10px 20px;
        background-color: white;
        border: 1px solid #2563eb;
        color: #2563eb;
        border-radius: 8px;
        font-weight: 500;
        cursor: pointer;
        align-items: center;
        gap: 8px;
        transition: all 0.2s ease;
        position: fixed;
        right: 32px;
        top: 24px;

        &:hover {
          background-color: #2563eb;
          color: white;
        }

        svg {
          font-size: 1.2rem;
        }
      }

      .dropdown, .clear-filters {
        display: none;
      }
    }

    .dropdown {
      position: relative;
      min-width: 220px;

      &.active .dropdown-button:not(:disabled) {
        background-color: rgba(37, 99, 235, 0.1);
        border-color: #2563eb;
        color: #2563eb;
      }

      .dropdown-button {
        background-color: white;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        padding: 10px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        font-size: 1rem;
        color: #374151;
        font-weight: 500;
        height: 42px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        svg {
          transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &:disabled {
          background-color: #f5f5f5;
          border-color: #e5e7eb;
          color: #9ca3af;
          cursor: not-allowed;

          .selected-count {
            background: rgba(156, 163, 175, 0.1);
            color: #9ca3af;
          }
        }

        &:not(:disabled):hover {
          border-color: #2563eb;
          background-color: rgba(37, 99, 235, 0.05);
        }

        .selected-count {
          margin-left: 8px;
          color: #2563eb;
          font-weight: 500;
          background: rgba(37, 99, 235, 0.1);
          padding: 3px 10px;
          border-radius: 12px;
          transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
      }

      .dropdown-content {
        position: absolute;
        top: calc(100% + 4px);
        left: 50%;
        transform: translateX(-50%);
        min-width: 320px; // Wider base width for all dropdowns
        background-color: white;
        border-radius: 12px;
        border: 1px solid #e5e7eb;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
        z-index: 1000;
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        display: flex;
        flex-direction: column;

        &.open {
          max-height: 400px;
          opacity: 1;
          visibility: visible;
          overflow-y: auto;
          padding: 8px 0;
        }

        // Industry dropdown needs to be wider for longer category names
        .dropdown:nth-child(3) & {
          min-width: 380px;
        }

        .checkbox-item {
          padding: 12px 20px;
          cursor: pointer;
          transition: all 0.15s ease;
          display: flex;
          align-items: center;
          width: 100%;

          &:hover {
            background-color: rgba(37, 99, 235, 0.05);
          }

          .checkbox-label {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 100%;

            input[type="checkbox"] {
              width: 18px;
              height: 18px;
              border: 2px solid #d1d5db;
              border-radius: 4px;
              transition: all 0.15s ease;
              cursor: pointer;
              position: relative;
              appearance: none;
              background-color: white;
              flex-shrink: 0;

              &:checked {
                background-color: #2563eb;
                border-color: #2563eb;

                &::after {
                  content: '';
                  position: absolute;
                  left: 5px;
                  top: 2px;
                  width: 6px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                }
              }
            }

            label {
              font-size: 0.95rem;
              color: #374151;
              cursor: pointer;
              user-select: none;
              line-height: 1.5;
              flex: 1;
            }
          }
        }

        .category-group {
          display: flex;
          flex-direction: column;
          width: 100%;

          .category-parent {
            font-weight: 600;
            background-color: #f8fafc;
            border-bottom: 1px solid #e5e7eb;
            padding: 14px 20px;
            color: #1e293b;
          }

          .subcategories {
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            padding: 4px 0;

            .subcategory {
              padding-left: 48px;
            }
          }
        }

        // Custom scrollbar
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 51, 160, 0.5);
          border-radius: 100px;
          border: none;

          &:hover {
            background: rgba(0, 51, 160, 0.7);
          }
        }

        // Firefox scrollbar (if needed)
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 51, 160, 0.5) transparent;
      }
    }

    &.filters-loading {
      opacity: 0.7;
      pointer-events: none;

      .filters-button,
      .dropdown-button,
      .clear-filters {
        background-color: #f5f5f5;
        border-color: #e5e7eb;
        color: #9ca3af;
        cursor: not-allowed;
      }
    }

    .filters-button {
      display: none;
    }

    .clear-filters-button {
      height: 42px;
      padding: 0 24px;
      border-radius: 8px;
      font-size: 0.95rem;
      font-weight: 500;
      transition: all 0.2s ease;
      background: #0033A0;
      color: white;
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 51, 160, 0.2);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      &.has-filters {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }

      &:hover:not(:disabled) {
        background-color: #0044CC;
        transform: scale(1.02);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
}

.total-count {
  font-size: 1rem;
  color: #2563eb;
  font-weight: 500;
  padding: 10px 16px;
  background: rgba(37, 99, 235, 0.1);
  border-radius: 8px;
  min-width: 140px;
  text-align: center;
  transition: all 0.2s ease;
}

@keyframes checkmark {
  0% {
    opacity: 0;
    transform: rotate(45deg) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
}

.job-search-content {
  background: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 700px;
  transition: all 0.3s ease;

  @media (max-width: 600px) {
    padding: 20px;
  }

  h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Karla', sans-serif;

    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  }

  .error-message {
    color: #ff6b6b;
    margin-bottom: 15px;
  }

  .success-message {
    color: #1dd1a1;
    margin-bottom: 15px;
  }

  .preferences-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .dropzone {
      border: 2px dashed #ddd;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      cursor: pointer;
      margin-bottom: 20px;
      transition: border-color 0.3s ease;

      @media (max-width: 600px) {
        padding: 15px;
      }

      &.dropzone-error {
        border-color: #ff6b6b;
      }

      p {
        font-size: 1rem;
        color: #666;

        @media (max-width: 600px) {
          font-size: 0.875rem;
        }
      }

      .file-display {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
        }

        .remove-file {
          color: #ff6b6b;
          cursor: pointer;
          margin-left: 10px;
          transition: color 0.3s ease;

          &:hover {
            color: #ff0000;
          }
        }
      }
    }

    .input-field {
      margin-bottom: 15px;

      .css-1wa3eu0-placeholder {
        color: #999 !important;
      }

      .css-1pahdxg-control {
        border: 1px solid #ddd !important;
        box-shadow: none !important;
        transition: border-color 0.3s ease;
      }

      .css-1pahdxg-control:hover {
        border-color: #333 !important;
      }

      .css-1pahdxg-control--is-focused {
        border-color: #333 !important;
      }

      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      .css-tlfecz-indicatorContainer {
        color: #999;
        transition: color 0.3s ease;
      }

      .css-tlfecz-indicatorContainer:hover {
        color: #333;
      }
    }

    .save-button {
      padding: 15px;
      font-size: 1rem;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      @media (max-width: 600px) {
        padding: 12px;
        font-size: 0.875rem;
      }

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.job-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 60px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  min-height: 500px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 92px !important;
    padding: 0 20px;
    gap: 12px;
    min-height: auto;
  }

  .job-result {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: calc((100% / 3) - 40px);
    transition: all 0.3s ease;
    text-align: left;
    position: relative;

    @media (max-width: 1200px) {
      width: calc((100% / 2) - 30px) !important;
    }

    @media (max-width: 768px) {
      width: 100% !important;
      margin: 0;
      padding: 16px;
      border-radius: 0;
      box-shadow: none;
      border-bottom: 1px solid #e5e7eb;
      min-height: auto;
      height: auto;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0; // Ensure it stays at the bottom
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;

  li {
    margin: 0 5px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #007bff;
      cursor: pointer;
      text-decoration: none;
      width: 35px; // Ensures circular shape
      height: 35px; // Ensures circular shape
      border-radius: 50%;
      border: 1px solid transparent;
      transition: background-color 0.3s, border-color 0.3s, transform 0.3s;

      &:hover {
        background-color: #f0f0f0;
        border-color: #ddd;
        transform: translateY(-2px); // Lift effect on hover
      }
    }

    &.active a {
      background-color: #007bff;
      color: white;
      border-color: #007bff;
      transform: translateY(0); // Ensure no lift effect on active
    }

    &.disabled a {
      color: #ccc;
      cursor: not-allowed;
    }
  }

  .prev, .next {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      padding: 0;
      font-size: 1.5rem; // Increase the size of the side arrows
    }
  }
}

@media (max-width: 768px) {
  .job-results {
    flex-direction: column;
    gap: 15px;
  }

  .pagination {
    flex-wrap: wrap;

    li {
      margin: 5px;
    }
  }
}

// Variables
$tile-gap: 20px;
$tile-padding: 20px;
$tile-border-radius: 8px;
$tile-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
$tile-border: 1px solid #cccccc;

.company-filter {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  font-size: 14px;
  color: #2d3748;
  min-width: 200px;
  cursor: pointer;
  outline: none;
  transition: all 0.2s;

  &:hover {
    border-color: #cbd5e0;
  }

  &:focus {
    border-color: #4299e1;
    box-shadow: 0 0 0 1px rgba(66, 153, 225, 0.5);
  }

  option {
    padding: 8px;
  }
}

.job-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  padding: 20px;
  margin-top: 80px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0;
    margin: 60px 20px 0;
    gap: 0;
    width: calc(100% - 40px);
  }
}

.job-tile-wrapper {
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  @media (max-width: 768px) {
    min-height: unset;
    height: auto;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #e5e7eb;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
}

.loading-more {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
}

.job-search-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dropdown {
  .dropdown-content {
    .checkbox-item {
      .checkbox-label {
        justify-content: flex-start !important;
        
        label {
          text-align: left !important;
          padding-left: 12px;
          justify-content: flex-start !important;
          margin-right: auto !important;
        }
      }
    }
  }
}

// Filter Modal Styles
.filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.modal-open {
    opacity: 1;
    visibility: visible;
  }

  &.closing {
    opacity: 0;
  }
}

.filter-modal {
  background: white;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  transform: translateY(20px);
  transition: all 0.3s ease;

  .modal-open & {
    transform: translateY(0);
  }

  .closing & {
    transform: translateY(20px);
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 16px;
    padding-right: 32px;
  }

  .modal-close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #6b7280;
    transition: color 0.2s ease;

    &:hover {
      color: #111827;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .filter-section {
    margin-bottom: 32px;

    .filter-label {
      font-size: 1.1rem;
      font-weight: 600;
      color: #111827;
      margin-bottom: 16px;
      display: block;
    }

    .dropdown {
      width: 100%;
      min-width: 100%;
      margin-bottom: 8px;
      border: none;
      background: none;

      .dropdown-button {
        width: 100%;
        text-align: left;
        padding: 12px 16px;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        background: white;
        color: #374151;
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.2s ease;

        &:hover {
          border-color: #2563eb;
          color: #2563eb;
        }

        svg {
          font-size: 1.2rem;
          margin-left: 8px;
        }
      }

      .dropdown-content {
        width: 100%;
        position: relative;
        background: white;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        margin-top: 8px;
        padding: 8px 0;
        max-height: 300px;
        overflow-y: auto;
        display: grid;
        grid-template-columns: 1fr;
        gap: 4px;

        .checkbox-item {
          padding: 12px 16px;
          display: grid;
          grid-template-columns: 24px 1fr;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          transition: background 0.2s ease;
          width: 100%;

          &:hover {
            background: #f3f4f6;
          }

          input[type="checkbox"] {
            width: 20px;
            height: 20px;
            border: 2px solid #d1d5db;
            border-radius: 4px;
            cursor: pointer;
            margin: 0;
            padding: 0;
            appearance: none;
            -webkit-appearance: none;
            position: relative;

            &:checked {
              background-color: #2563eb;
              border-color: #2563eb;

              &:after {
                content: '';
                position: absolute;
                left: 6px;
                top: 2px;
                width: 4px;
                height: 9px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }
          }

          label {
            font-size: 0.95rem;
            color: #374151;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;

            img, svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 32px;
    padding-top: 20px;
    border-top: 1px solid #e5e7eb;

    button {
      padding: 12px 24px;
      border-radius: 8px;
      font-weight: 500;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.2s ease;

      &.cancel-button {
        background-color: white;
        border: 1px solid #e5e7eb;
        color: #374151;

        &:hover {
          background-color: #f3f4f6;
        }
      }

      &.apply-button {
        background-color: #2563eb;
        border: 1px solid #2563eb;
        color: white;

        &:hover {
          background-color: #1d4ed8;
        }
      }
    }
  }
}

// Scrollbar styles for the dropdown content
.dropdown-content::-webkit-scrollbar {
  width: 8px;
}

.dropdown-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

.job-count {
  width: 100%;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

