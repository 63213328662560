.cover-letter-container {
  // Change from A4 to US Letter dimensions
  width: 8.5in; 
  min-height: 11in;
  margin: 0 auto;
  padding: 1.25in 1in 0.75in 1in; // Increased top padding to 1.25in
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px; // Larger font for better screen readability
  line-height: 1.6;
  max-width: 100%; // Ensure no horizontal overflow
  position: relative;
  
  // Default state with black border
  border: 1px solid #000000;
  
  // Loading state with gradient border
  &.loading {
    border: none;
    
    &::before, &::after {
      content: '';
      position: absolute;
      inset: 0; // Shorthand for top: 0, right: 0, bottom: 0, left: 0
      border-radius: 8px;
    }

    &::before {
      background: linear-gradient(90deg, #0033A0, #16A000);
      background-size: 200% 100%;
      animation: gradient-move 4s linear infinite;
      z-index: 0;
    }

    &::after {
      background: #ffffff;
      margin: 1px;
      z-index: 1;
    }
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 1.25in 0.75in 0.75in 0.75in; // Maintain increased top padding
    margin: 1rem auto;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 1.25in 0.5in 0.75in 0.5in; // Maintain increased top padding
    margin: 0.5rem auto;
  }

  .cover-letter-content {
    height: 100%;
    position: relative;
    z-index: 2;
    
    .content {
      font-size: 12pt; // Match print size
      line-height: 1.6; // Standard line spacing
      letter-spacing: 0.01em; // Subtle letter spacing for readability
      
      .loading-placeholder {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 0;

        .placeholder-line {
          background: rgba(0, 0, 0, 0.1);
          height: 16px;
          border-radius: 4px;
          animation: pulse 1.5s ease-in-out infinite;
          width: 100%;

          &.short { width: 40%; }
          &.medium { width: 60%; }
          &.long { width: 80%; }
        }
      }

      .editable-content {
        outline: none;
        min-height: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
        line-height: inherit; // Ensure line spacing is consistent
        
        &:not([contenteditable="true"]) {
          cursor: default;
          user-select: none;
        }
        
        &[contenteditable="true"] {
          cursor: text;
          user-select: text;
        }
      }
    }
  }
}

.action-btn {
  height: 40px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.primary {
    background-color: white;
    color: #0033a0;
    border: 1px solid #0033a0;
    font-weight: 500;

    &:hover {
      background-color: #0033a0;
      color: white;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  
  &.secondary {
    background-color: white;
    color: #0033a0;
    border: 1px solid #0033a0;
    font-weight: 500;
    margin-bottom: 8px; // Add spacing between buttons

    &:hover {
      background-color: #0033a0;
      color: white;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

// Add animation for the appearing button
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.right-container {
  .action-btn.secondary {
    animation: fadeIn 0.3s ease-out forwards;
  }

  .external-link {
    color: #0033A0;
    text-decoration: none;
    transition: color 0.2s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .arrow {
      display: inline-flex;
      transition: transform 0.2s ease;
      margin-left: 4px;
      font-size: 1.2em;
    }

    &:hover {
      color: #0056b3;

      .arrow {
        transform: translate(2px, -2px);
      }
    }
  }

  .action-btn {
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;
    animation-delay: 0.1s; // Slight delay after the link appears
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.resume-container {
  display: flex;
  position: relative;
  width: 100%;
  gap: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: auto; // Allow container to expand
  min-height: 100vh;

  .left-container {
    width: 60%;
    min-width: 0;
    margin-right: 20px;
    height: auto; // Allow container to expand
    overflow: visible; // Show all content
    // Remove overflow-y: auto from here
    isolation: isolate;
    z-index: 1;
    
    .resume-rectangle {
      // Ensure resume content stays independent
      position: relative;
      z-index: 1;
      
      // Ensure header text remains visible during loading
      .resume-header {
        opacity: 1 !important; // Override any inherited opacity
        visibility: visible !important;
        z-index: 2;
        position: relative;
        
        .contact-info {
          opacity: 1 !important;
          visibility: visible !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          flex-wrap: wrap !important;
          gap: 3px !important;
          margin-top: 5px !important;
          
          > div {
            opacity: 1 !important;
            visibility: visible !important;
            display: inline-flex !important;
            align-items: center !important;
          }
          
          .divider {
            opacity: 1 !important;
            visibility: visible !important;
            display: inline-block !important;
            margin: 0 5px !important;
          }
        }
      }
    }
  }

  .right-container {
    width: calc(40% - 25px);
    min-width: 0;
    height: 100%;
    overflow-y: auto;

    .suggestions-container {
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1); // Right and bottom shadow only
    }
  }

  @media (max-width: 1200px) {
    .left-container {
      width: 100%;
      margin-right: 60px;
    }

    .right-container {
      display: none;
    }

    .expanded-sidebar {
      width: min(95%, 450px);
      padding: 20px;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;

      .suggestions-container {
        max-height: 70vh; // Even shorter on mobile
        height: auto;
        overflow-y: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .resume-container {
      padding: 10px;
      
      .left-container {
        margin-right: 50px;
        padding: 15px;
      }

      .expanded-sidebar {
        width: min(95%, 350px);
        margin: 80px 15px;
        padding: 15px;

        .suggestions-container {
          max-height: 65vh; // Further reduced for smaller screens
          padding: 15px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .resume-container {
      padding: 5px;
      
      .left-container {
        margin-right: 40px;
        padding: 10px;
      }

      .expanded-sidebar {
        width: min(95%, 300px);
        margin: 80px 10px;
        padding: 10px;

        .suggestions-container {
          max-height: calc(100vh - 100px);
          padding: 10px;
        }
      }
    }
  }

  // Screen size breakpoints
  @media (min-width: 1921px) { // 4K and larger screens
    .resume-container {
      max-width: 2000px;
      margin: 0 auto;
      
      .right-container {
        max-width: none !important;
        width: calc(40% - 25px) !important;
      }
    }
  }

  @media (max-width: 1920px) { // Standard desktop and laptop screens
    .resume-container {
      .right-container {
        max-width: none !important;
        width: calc(40% - 25px) !important;
      }
    }
  }

  @media (max-width: 1440px) { // Smaller laptop screens
    .resume-container {
      .right-container {
        max-width: none !important;
        width: calc(40% - 25px) !important;
      }
    }
  }
}
@keyframes pulse {
  0% { background-color: rgba(0, 0, 0, 0.1); }
  50% { background-color: rgba(0, 0, 0, 0.2); }
  100% { background-color: rgba(0, 0, 0, 0.1); }
}

@keyframes gradient-move {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}

body {
  background-color: #f5f5f5;
  padding: 2rem 0;
}

.tailor-ai-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #91918e !important;
  font-family: "Inter", sans-serif !important;
  padding: 10px 20px !important;
  min-width: 160px;
  cursor: pointer !important;
  border: 2px solid transparent;
  border-radius: 8px;
  background-image: linear-gradient(white, white), linear-gradient(90deg, #0033a0 0%, #16a000 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  .ai-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .btn-text {
    flex-grow: 1;
    text-align: center;
  }
}

.external-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #0033A0;
  text-decoration: none;
  transition: all 0.2s ease;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  gap: 6px;
  padding: 8px 0;
  font-size: 14px;
  min-width: 180px;
  
  .external-icon {
    transition: transform 0.2s ease;
  }

  &:hover {
    color: #0033A0;
    
    .external-icon {
      transform: translate(2px, -2px);
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
