.auth-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 0 20px; /* Just a small fallback padding around the wrapper */
  position: relative;
  font-family: 'Inter', sans-serif;

  .auth-header {
    position: absolute;
    top: 1px;
    left: 0;
    padding: 20px 200px;

    @media screen and (max-width: 1370px) {
      padding: 20px 100px;
    }
    @media screen and (max-width: 1024px) {
      padding: 20px 50px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 20px;
    }

    .auth-logo {
      height: 40px;
    }
  }

  .auth-container {
    /* Main large padding around the content */
    background: #ffffff;
    padding: 46px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 550px;

    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 14px;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      font-family: 'Inter', sans-serif;
    }

    .error-message {
      color: #ff0000;
      margin-bottom: 10px;
      font-family: 'Inter', sans-serif;
    }

    .success-message {
      color: #28a745;
      margin-bottom: 10px;
      font-family: 'Inter', sans-serif;
    }

    /* 
      Removed extra padding around the form 
      so only the container's 46px is in effect.
    */
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;

      .name-fields {
        display: flex;
        gap: 8px;
        margin-bottom: 10px;
        width: 100%;

        @media screen and (max-width: 480px) {
          flex-direction: column;
          gap: 15px;
        }
      }

      /* Common input styling: 40px tall, minimal side padding */
      input {
        width: 100%;
        border: 2px solid #dddddd;
        border-radius: 4px;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        color: #000;
        outline: none;
        box-sizing: border-box;
        height: 40px;
        line-height: 20px;  /* Enough for 16px text + a bit of breathing room */
        padding: 0 12px;    /* Minimal horizontal padding */
        margin-bottom: 10px;

        &::placeholder {
          color: #9CA3AF;
          font-family: 'Inter', sans-serif;
        }

        &:focus {
          border-color: #0033A0;
        }
      }

      .email-field-with-suggestion {
        position: relative;
        width: 100%;
        margin-bottom: 10px;

        input {
          /* Just ensuring caret is black, otherwise inherits above styles */
          caret-color: black;
        }

        .suggestion-overlay {
          position: absolute;
          top: 10.5px;
          left: 14px;
          pointer-events: none;
          font-size: 16px;
          font-family: 'Inter', sans-serif;
          line-height: 20px;
          display: inline-flex;
          align-items: center;
          white-space: pre;

          .typed-text {
            visibility: hidden;
          }
          .suggested-part {
            color: #9CA3AF;
            margin-left: 0;
          }
        }
      }

      .password-field {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        user-select: none;      /* Prevent selection of any text within */

        input {
          /* Same 40px tall with space for the icon */
          padding-right: 40px;
          user-select: text;    /* Re-enable text selection for the input field */
        }

        span {
          position: absolute;
          width: 40px;
          height: 40px;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6B7280;
          cursor: pointer;
          user-select: none;    /* Prevent selection when clicking icon */

          svg {
            width: 20px;
            height: 20px;
            display: block;
            pointer-events: none;  /* Prevent any svg-specific events */
          }
        }
      }

      /* 
        Shared button styling so all rectangular buttons match 
        (Sign In, Sign Up, etc.); 40px tall, minimal side padding 
      */
      .auth-button {
        margin-top: 10px;
        padding: 0 16px;
        background-color: transparent;
        border: 2px solid #0033A0;
        color: #0033A0;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease;
        height: 40px;
        width: 100%;            /* Full width inside form container */
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'Inter', sans-serif;

        &:hover {
          background-color: #0033A0;
          color: #ffffff;
        }
      }
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      font-family: 'Inter', sans-serif;

      a {
        color: #0033A0;
        text-decoration: none;
        font-family: 'Inter', sans-serif;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .forgot-password {
      text-align: center;
      margin: 12px 0;
      font-size: 14px;
      color: #6B7280;
      width: 100%;
      font-family: 'Inter', sans-serif;
    }

    .or-divider {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 16px 0;
      color: #6B7280;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Inter', sans-serif;
      width: 100%;

      &::before,
      &::after {
        content: '';
        flex: 1;
        border-top: 1px solid #E5E7EB;
        margin: 0 16px;
      }
    }

    /* 
      Make "Sign in with Google" button same style as .auth-button 
      by placing an actual button inside .google-button-container 
      or simply applying the .auth-button class to it. 
    */
    .google-button-container {
      width: 100%;
      margin: 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        /* Match the .auth-button styling for a consistent 40px height, etc. */
        padding: 0 16px;
        background-color: transparent;
        border: 2px solid #0033A0;
        color: #0033A0;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease;
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'Inter', sans-serif;

        &:hover {
          background-color: #0033A0;
          color: #ffffff;
        }
      }
    }

    .signup-link {
      color: #6B7280;
      margin-top: 12px;
      font-size: 14px;
      font-family: 'Inter', sans-serif;

      a {
        color: #0033A0;
        font-weight: 500;
        text-decoration: none;
        margin-left: 4px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .terms-text {
    text-align: center;
    color: #6B7280;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    margin-top: 12px;
    padding: 0 20px;

    a {
      color: #6B7280;
      text-decoration: underline;
      font-family: 'Inter', sans-serif;
      
      &:hover {
        color: #4B5563;
      }
    }
  }
}

.verification-card {
  background: white;
  border-radius: 12px;
  width: 100%;
  max-width: 480px;
  text-align: center;

  h2 {
      color: #333;
      margin-bottom: 1rem;
      font-size: 1.75rem;
  }

  .subtitle {
      color: #666;
      margin-bottom: 2rem;
      font-size: 0.95rem;
      line-height: 1.5;
  }
}

.code-input-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.code-input {
  width: 48px;
  height: 48px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  text-align: center;
  font-size: 1.25rem;
  transition: all 0.3s ease;

  &:focus {
      border-color: #0b349d;
      outline: none;
  }
}

.verify-button {
  width: 100%;
  padding: 1rem;
  background-color: #0b349d;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
      background-color: darken(#0b349d, 10%);
  }
}

.resend-text {
  margin-top: 1.5rem;
  color: #666;
  font-size: 0.9rem;

  .resend-link {
      color: #0b349d;
      text-decoration: none;
      font-weight: 500;

      &:hover {
          text-decoration: underline;
      }
  }
}

.footer-links {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 2rem;

  .footer-link {
      color: #666;
      text-decoration: none;
      font-size: 0.9rem;

      &:hover {
          color: #0b349d;
      }
  }
}
