@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibri.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibrib.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibrii.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibriz.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

.application-cover-letter {
  width: 8.5in;
  min-height: 11in;
  margin: 0 auto;
  padding: 1.25in 1in 0.75in 1in;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Calibri', Helvetica, Arial, sans-serif;
  font-size: 12pt;
  line-height: 1.6;
  max-width: 100%;
  position: relative;
  border: 1px solid #000000;

  .cover-letter-paragraph {
    margin-bottom: 1rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: 'Calibri', Helvetica, Arial, sans-serif;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 1.25in 0.75in 0.75in 0.75in;
    margin: 1rem auto;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 1.25in 0.5in 0.75in 0.5in;
    margin: 0.5rem auto;
  }
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #666;
  font-family: 'Calibri', Helvetica, Arial, sans-serif;
  font-size: 15px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #666;
  font-family: 'Calibri', Helvetica, Arial, sans-serif;

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #0033A0;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 