.preferences-page {
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: width 0.3s ease;
  z-index: 100;
  background-color: #0033A0;
}

.sidebar.expanded {
  width: 250px;
}

.preferences-content {
  margin-left: 80px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sidebar.expanded + & {
    margin-left: 250px;
  }

  .progress-dots {
    display: flex;
    gap: 25px; // 25px gap between dots
    margin-bottom: 20px;
    position: relative;
    width: calc(4 * 12px + 3 * 25px); // Adjusted width
    justify-content: space-between;

    .progress-bar {
      position: absolute;
      top: 50%;
      left: 0;
      height: 12px; /* Match the circle height */
      background-color: #0033A0;
      transition: width 0.3s ease;
      transform: translateY(-50%);
      border-radius: 6px; /* Match the circle border radius */
    }

    span {
      width: 12px;
      height: 12px;
      border-radius: 50%; // Ensure circular dots
      background-color: #ddd;
      z-index: 1;
      transition: background-color 0.3s ease;

      &.active {
        background-color: #0033A0;
      }
    }
  }

  .onboarding-content {
    width: 100%;
    max-width: 900px;
    padding: 0 20px 60px; // Add bottom padding to ensure space for the buttons
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1; // Ensure content grows to take available space
    height: calc(100vh - 60px); // Adjust height to fit the screen minus some padding

    .step-content {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; // Center the content vertically
      flex-grow: 1;
      width: 100%;
      max-width: 800px;

      &.welcome-screen {
        margin-top: -10vh; // Move the entire content higher

        h2 {
          margin-top: 2vh; // Adjust margin to move content higher
          margin-bottom: 20px;
        }

        p {
          margin-bottom: 30px; // Increase margin to create more distance from button
        }

        .styled-button {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          font-size: 1rem;
          color: #0033A0;
          background: none;
          border: 2px solid #0033A0;
          border-radius: 5px;
          cursor: pointer;
          text-transform: none;
          transition: background-color 0.3s ease, color 0.3s ease;
          margin-top: 20px;

          &:hover {
            background-color: #0033A0;
            color: #fff;
          }
        }
      }

      &.consistent-title {
        h2 {
          margin-top: 40px;
          margin-bottom: 40px; // Add margin below the title for consistency
        }
      }

      &.screen-2,
      &.screen-3,
      &.screen-4 {
        .options-container,
        .dropzone {
          margin-top: 40px; // Add margin to ensure consistent starting height
        }
      }

      h2 {
        font-size: 2.5rem;
        color: #333;
        font-family: 'Karla', sans-serif;
      }

      p {
        font-size: 1rem;
        color: #666;
        margin-bottom: 20px;
      }

      .dropzone {
        border: 2px dashed #ddd;
        border-radius: 10px;
        padding: 40px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 20px;
        transition: border-color 0.3s ease;
        width: 100%;
        max-width: 500px;  // Adjust max-width for a rectangular shape
        height: 350px;     // Adjust height for a rectangular shape

        .upload-instructions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;

          .upload-image {
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
          }

          p {
            font-size: 1rem;
            color: #666;
            margin: 5px 0;
            font-family: 'Karla', sans-serif;

            &:first-of-type {
              font-weight: bold;
              color: #000;
            }
          }

          .select-file-button {
            margin-top: 10px;
            padding: 10px 20px;
            font-size: 1rem;
            color: #0033A0;
            background-color: #f0f8ff;
            border: 2px solid #0033A0;
            border-radius: 5px;
            cursor: pointer;
            text-transform: uppercase;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #0033A0;
              color: #fff;
            }
          }
        }

        .file-display {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 0;
          }

          .remove-file {
            color: #ff6b6b;
            cursor: pointer;
            margin-left: 10px;
            transition: color 0.3s ease;

            &:hover {
              color: #ff0000;
            }
          }
        }
      }

      .navigation-buttons {
        display: flex;
        justify-content: space-between; // Evenly space the buttons
        width: 100%;
        max-width: 800px; // Increased max-width for more spacing between buttons
        align-items: center; // Center align items vertically
        margin-top: auto; // Push the buttons to the bottom of the container
        margin-bottom: 40px; // Ensure 40px gap from the bottom

        .back-button, .next-button, .confirm-button {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          font-size: 1rem;
          cursor: pointer;
          text-transform: none;
          transition: background-color 0.3s ease, color 0.3s ease;
          border-radius: 5px;
          width: 120px; // Ensuring consistent button width
          justify-content: center;
        }

        .back-button {
          color: #666;
          background: none;
          border: 2px solid transparent;

          &:hover {
            color: #555;
          }

          .back-arrow {
            margin-right: 5px;
          }
        }

        .next-button, .confirm-button {
          color: #0033A0;
          background: none;
          border: 2px solid #0033A0;

          &:hover {
            background-color: #0033A0;
            color: #fff;
          }

          .next-arrow, .confirm-arrow {
            margin-left: 5px;
          }
        }
      }

      @media (max-width: 1200px) {
        .navigation-buttons .back-button, .next-button, .confirm-button {
          padding: 8px 16px; // Adjust padding for medium screens
          margin: 0 15px; // Adjust horizontal margin for medium screens
        }
      }

      @media (max-width: 768px) {
        .navigation-buttons .back-button, .next-button, .confirm-button {
          padding: 6px 12px; // Adjust padding for small screens
          margin: 0 10px; // Adjust horizontal margin for small screens
        }
      }

      @media (max-width: 480px) {
        .navigation-buttons .back-button, .next-button, .confirm-button {
          padding: 4px 8px; // Adjust padding for extra small screens
          margin: 0 5px; // Adjust horizontal margin for extra small screens
        }
      }

      .divider {
        width: 80%; // Adjust as necessary
        height: 1px;
        margin: 30px 0; // Add some vertical space around the divider
        background: linear-gradient(to right, transparent, #ccc, transparent);
      }
    }

    .options-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px; // Increased gap for better spacing
      margin-bottom: 20px;

      .option {
        padding: 14px 28px; // Increased padding for bigger buttons
        border: 1px solid #ddd;
        border-radius: 12px; // Consistent border radius
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
        font-weight: 500; // Slightly bolder font
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow

        &.selected {
          background-color: #0033A0;
          color: #fff;
        }

        &:hover {
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); // Enhanced shadow on hover
        }
      }
    }

    ul {
      text-align: left;

      li {
        font-size: 1rem;
        color: #333;
        margin-bottom: 10px;
      }
    }
  }
}
