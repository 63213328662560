@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap'); /* Inter Font Import */

/* Global Variables */
$tile-padding: 20px;
$tile-border-radius: 8px;
$tile-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
$tile-border: 1px solid #cccccc;
$tile-gap: 20px;

/* Main container */
.dashboard-container {
  display: flex;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  background-color: #ffffff;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: width 0.3s ease;
  z-index: 1001;
  background: #0033A0;

  @media (max-width: 768px) {
    transform: translateX(-100%);
    width: 250px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  }
}

.sidebar.expanded {
  width: 250px;

  @media (max-width: 768px) {
    transform: translateX(0);
  }
}

/* Dashboard Content */
.dashboard {
  margin-left: 80px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 40px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
  }
}

.sidebar.expanded + .dashboard {
  margin-left: 250px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

/* Header Section */
.dashboard-header {
  text-align: left;
  margin-bottom: 40px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;
    margin: 0 0 20px 0;
  }
}

/* Good Morning Greeting */
.greeting {
  font-size: 36px;
  font-weight: 700;
  color: #333333;
  margin: 0;
}

/* Job Matches Header */
.job-matches-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-right: $tile-gap; /* Just adding the last gap padding */

  .title-section {
    display: flex;
    align-items: center;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    color: #333;
    margin: 0;
    line-height: 1.2;
  }

  .view-all-jobs {
    display: inline-flex;
    align-items: center;
    color: #1a73e8;
    text-decoration: none;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
    padding-top: 6px;
    
    &:hover {
      color: #0056b3;
  
      .arrow {
        transform: translateX(3px);
      }
    }
  
    .arrow {
      margin-left: 5px;
      display: flex;
      transition: transform 0.3s ease;
      font-size: 0.8rem;
      align-items: center;
    }
  }

  @media (max-width: 1200px) {
    width: 90%;
    padding-right: calc($tile-gap / 2); /* Adjust the gap for tablet view */
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
    margin: 20px 0;
  }
}

/* Metrics Section */
.dashboard-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px 20px;
    gap: 16px;
    margin: 0;
  }
}

/* Stat Box Styling */
.stat {
  background-color: #ffffff;
  padding: $tile-padding;
  border-radius: $tile-border-radius;
  box-shadow: $tile-box-shadow;
  border: $tile-border;
  width: calc((100% / 3) - 40px); /* Three tiles per row */
  height: 186px; /* Consistent height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space between the title and the stat container */
  transition: all 0.3s ease;
  position: relative;
  margin-bottom: $tile-gap;

  @media (max-width: 1200px) {
    width: calc((100% / 2) - 30px); /* Two tiles per row on smaller screens */
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 16px 0;
    padding: 16px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #cccccc;
    min-height: unset;
    height: auto;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
  }
}

/* Stat Box Text */
.stat-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  flex-grow: 1; /* Ensure it occupies the available space */
}

/* Title Styling */
.stat h2 {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}

/* Stat Number and Goal Container */
.stat-number-container {
  display: flex;
  align-items: baseline; /* Aligns the number and goal on the same line */
  justify-content: flex-start;
  width: 100%;
  margin-top: auto; /* Pushes it to the bottom */
}

/* Stat Number Styling */
.stat-number {
  font-size: 30px !important;
  font-weight: 700;
  color: #333333 !important;
  text-align: left !important;
  margin-left: 0 !important; /* Remove any left margin */
  margin-bottom: 2px !important; /* Small gap between number and label */
}

/* Stat Goal Styling */
.stat-goal {
  display: flex;
  align-items: center;
  margin-left: 15px !important; /* Reduced space between number and goal */
  font-size: 16px; /* Set goal size to 16px */
}

.arrow-icon {
  margin-right: 4px; /* Space between arrow and percentage */
}

.increase {
  color: #4CAF50 !important; /* Green for increase */
}

.decrease {
  color: #FF9800 !important; /* Orange for decrease */
}

.neutral {
  color: #A2A2A2 !important; /* Grey for neutral/no change */
}

.dash-placeholder {
  margin-left: 24px; /* Aligns with where arrow would be */
  font-size: 20px; /* Slightly larger dash */
  font-weight: bold;
}

/* Stat Label Styling */
.stat-label {
  font-size: 16px !important; /* Slightly increased size */
  color: #A2A2A2 !important;
  text-align: left !important;
  margin-left: 0 !important; /* Ensure left alignment */
  padding-left: 0 !important;
  margin-top: 0px !important; /* No space between number and label */
  margin-bottom: 0px !important; /* Remove bottom margin */
}

/* Progress Circle */
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
}

/* Job Tiles Section */
.job-tiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: $tile-gap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 16px 20px;
    gap: 16px;
  }

  /* Add loading state styles */
  &.loading {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Loading Spinner */
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error State */
.error-message {
  width: 100%;
  padding: 20px;
  text-align: center;
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: $tile-border-radius;
  margin: 20px 0;
}

/* Job Tile Styles */
.job-tile {
  background-color: #ffffff;
  padding: $tile-padding;
  border-radius: $tile-border-radius;
  box-shadow: $tile-box-shadow;
  border: $tile-border;
  width: calc((100% - (2 * #{$tile-gap})) / 3);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  position: relative;
  margin-bottom: $tile-gap;

  @media (max-width: 1200px) {
    width: calc((100% - #{$tile-gap}) / 2);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 16px 0;
    padding: 16px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #cccccc;
    min-height: unset;
    height: auto;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.stat {
    @media (max-width: 768px) {
      margin: 0 0 16px 0;
      padding: 16px 20px;
      border-radius: 8px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      border: 1px solid #cccccc;
      min-height: unset;
      height: auto;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .company-logo-container {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
    margin-top: 60px;
  }

  p {
    font-size: 14px;
    color: #666;
    margin-left: 60px;
  }

  .apply-button {
    display: inline-block;
    margin-top: 15px;
    padding: 8px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }
  }

  .view-details {
    display: inline-block;
    margin-top: 15px;
    font-size: 1rem;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;

    &:hover {
      color: #0056b3;

      .arrow {
        transform: translateX(5px);
      }
    }

    .arrow {
      display: inline-block;
      transition: transform 0.3s ease;
    }
  }

  /* Add placeholder loading styles */
  &.placeholder {
    animation: pulse 1.5s infinite;
    
    .company-logo-container,
    h3,
    p {
      background: #f0f0f0;
      color: transparent;
    }
    
    h3 {
      height: 24px;
      width: 80%;
    }
    
    p {
      height: 16px;
      width: 60%;
      margin-top: 8px;
    }
  }
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .job-tiles-container {
    flex-direction: column;
    gap: 15px;
  }

  .dashboard-header {
    padding-left: 20px; /* Reduced padding for smaller screens */
  }

  .view-all-jobs {
    padding-top: 2px; /* Adjust the padding on smaller screens for alignment */
  }

  .stat {
    width: 100%; /* Full width for stat boxes on small screens */
  }
}

.stat-content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-top: auto;
}

.metric-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns with other metrics */
}

.visualization-section {
  display: flex;
  align-items: center;
  width: 90px; /* Adjust as needed */
  height: 90px; /* Adjust as needed */
}

/* Weekly Goal specific styles */
.weekly-goal-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  margin-top: auto;
}

.goal-visualization {
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Adjust the renderProgressCircle size */
.progress-circle {
  width: 100%;
  height: 100%;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background: #f5f5f5;
  height: 186px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #cccccc;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

// Remove all other skeleton-related styles since we're using simple tiles
.job-tile.skeleton {
  width: calc((100% - (2 * #{$tile-gap})) / 3);
  margin-bottom: $tile-gap;

  @media (max-width: 1200px) {
    width: calc((100% - #{$tile-gap}) / 2);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 16px 0;
    padding: 16px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #cccccc;
    min-height: unset;
    height: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

