.not-found-container {
  min-height: calc(100vh - 88px - 400px); // Accounting for navbar and footer
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  margin-top: 88px;

  .not-found-content {
    text-align: center;
    max-width: 600px;

    h1 {
      font-size: 120px;
      color: #0033A0;
      margin: 0;
      line-height: 1;
      font-weight: 700;
    }

    h2 {
      font-size: 32px;
      margin: 20px 0;
      color: #1F2937;
    }

    p {
      font-size: 18px;
      color: #6B7280;
      margin-bottom: 32px;
    }

    .actions {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;

      .primary-button, .secondary-button {
        padding: 12px 24px;
        border-radius: 6px;
        font-weight: 500;
        transition: all 0.2s ease;
        text-decoration: none;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .primary-button {
        background: #0033A0;
        color: white;
        border: 2px solid #0033A0;
        
        &:hover {
          background: darken(#0033A0, 5%);
          transform: translateY(-1px);
        }
      }

      .secondary-button {
        border: 2px solid #0033A0;
        color: #0033A0;
        
        &:hover {
          background: rgba(0, 51, 160, 0.05);
          transform: translateY(-1px);
        }
      }
    }
  }
} 