.resume-container {
  display: flex;
  position: relative;
  width: 100%;
  gap: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: auto; // Allow container to expand
  min-height: 100vh;

  .left-container {
    width: 60%;
    min-width: 0;
    margin-right: 20px;
    height: auto; // Allow container to expand
    overflow: visible; // Show all content
    // Remove overflow-y: auto from here
    isolation: isolate;
    z-index: 1;
    
    .resume-rectangle {
      // Ensure resume content stays independent
      position: relative;
      z-index: 1;
      
      // Ensure header text remains visible during loading
      .resume-header {
        opacity: 1 !important; // Override any inherited opacity
        visibility: visible !important;
        z-index: 2;
        position: relative;
        
        .contact-info {
          opacity: 1 !important;
          visibility: visible !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          flex-wrap: wrap !important;
          gap: 3px !important;
          margin-top: 5px !important;
          
          > div {
            opacity: 1 !important;
            visibility: visible !important;
            display: inline-flex !important;
            align-items: center !important;
          }
          
          .divider {
            opacity: 1 !important;
            visibility: visible !important;
            display: inline-block !important;
            margin: 0 5px !important;
          }
        }
      }
    }
  }

  .right-container {
    width: calc(40% - 25px);
    min-width: 0;
    height: 100%;
    overflow-y: auto;

    .suggestions-container {
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1); // Right and bottom shadow only
    }
  }

  // Collapsed sidebar styles
  .collapsed-sidebar {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 120px;
    border: 1px solid #e0e0e0;
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    z-index: 100;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Expanded sidebar styles
  .expanded-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: calc(40% - 25px);
    z-index: 100;
    transition: all 0.3s ease;
    background: transparent;
    border: none; // Remove any border
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: none; // Remove any shadow
    
    .suggestions-container {
      background: white;
      border-radius: 12px;
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1); // Right and bottom shadow only
      padding: 20px;
      max-height: 75vh; // Reduced height
      width: 100%;
      overflow-y: auto;
      position: relative;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: white;
      border: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease;
      z-index: 101;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
      }

      span {
        font-size: 16px;
        line-height: 1;
        color: #666;
      }
    }
  }

  @media (max-width: 1200px) {
    .left-container {
      width: 100%;
      margin-right: 60px;
    }

    .right-container {
      display: none;
    }

    .expanded-sidebar {
      width: min(95%, 450px);
      padding: 20px;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;

      .suggestions-container {
        max-height: 70vh; // Even shorter on mobile
        height: auto;
        overflow-y: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .resume-container {
      padding: 10px;
      
      .left-container {
        margin-right: 50px;
        padding: 15px;
      }

      .expanded-sidebar {
        width: min(95%, 350px);
        margin: 80px 15px;
        padding: 15px;

        .suggestions-container {
          max-height: 65vh; // Further reduced for smaller screens
          padding: 15px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .resume-container {
      padding: 5px;
      
      .left-container {
        margin-right: 40px;
        padding: 10px;
      }

      .expanded-sidebar {
        width: min(95%, 300px);
        margin: 80px 10px;
        padding: 10px;

        .suggestions-container {
          max-height: calc(100vh - 100px);
          padding: 10px;
        }
      }
    }
  }

  // Screen size breakpoints
  @media (min-width: 1921px) { // 4K and larger screens
    .resume-container {
      max-width: 2000px;
      margin: 0 auto;
      
      .right-container {
        max-width: none !important;
        width: calc(40% - 25px) !important;
      }
    }
  }

  @media (max-width: 1920px) { // Standard desktop and laptop screens
    .resume-container {
      .right-container {
        max-width: none !important;
        width: calc(40% - 25px) !important;
      }
    }
  }

  @media (max-width: 1440px) { // Smaller laptop screens
    .resume-container {
      .right-container {
        max-width: none !important;
        width: calc(40% - 25px) !important;
      }
    }
  }
}
