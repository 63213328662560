.job-page-container {
  width: 100%;
  padding-left: 200px;
  padding-right: 200px;
  overflow-x: hidden;
  margin-top: 88px;
  transition: padding 0.3s ease;
  display: flex;
  flex-direction: column;

  > *:not(footer) {
    padding-left: inherit;
    padding-right: inherit;
  }

  footer {
    padding-left: 0;
    padding-right: 0;
    margin-left: -200px;
    margin-right: -200px;
    width: calc(100% + 400px);
  }

  @media screen and (max-width: 1440px) {
    padding-left: 160px;
    padding-right: 160px;

    footer {
      margin-left: -160px;
      margin-right: -160px;
      width: calc(100% + 320px);
    }
  }

  @media screen and (max-width: 1370px) {
    padding-left: 120px;
    padding-right: 120px;

    footer {
      margin-left: -120px;
      margin-right: -120px;
      width: calc(100% + 240px);
    }
  }

  @media screen and (max-width: 1280px) {
    padding-left: 80px;
    padding-right: 80px;

    footer {
      margin-left: -80px;
      margin-right: -80px;
      width: calc(100% + 160px);
    }
  }

  @media screen and (max-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;

    footer {
      margin-left: -50px;
      margin-right: -50px;
      width: calc(100% + 100px);
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 40px;
    padding-right: 40px;

    footer {
      margin-left: -40px;
      margin-right: -40px;
      width: calc(100% + 80px);
    }
  }

  @media screen and (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;

    footer {
      margin-left: -32px;
      margin-right: -32px;
      width: calc(100% + 64px);
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;

    footer {
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
    }
  }

  .hero-image-container {
    position: relative;
    width: 580px;
    height: 326px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e5e7eb;
    background: #FAFAFA;
    margin: 40px 0;
    margin-left: auto;
    right: 0;

    @media screen and (max-width: 1440px) {
      width: 520px;
      height: 292px;
    }

    @media screen and (max-width: 1024px) {
      width: 480px;
      height: 270px;
      margin: 30px auto;
    }

    @media screen and (max-width: 768px) {
      width: min(400px, calc(100vw - 40px));
      height: calc(min(400px, calc(100vw - 40px)) / 1.78);
      margin: 24px auto;
    }
  }

  // Hero section styles
  .job-features-hero-section {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 100px 0;

    .hero-content {
      flex: 1 1 auto;
      max-width: 640px;
      margin: 0;

      .hero-heading {
        font-family: "Inter", sans-serif;
        font-size: 64px;
        font-weight: 650;
        margin: 0 0 24px;
        line-height: 1.2;
        letter-spacing: -1px;
        color: #161617;

        @media only screen and (max-width: 1024px) {
          font-size: 48px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 32px;
        }
      }

      .hero-subheading {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: 300;
        margin: 0;
        color: #161617cc;

        @media only screen and (max-width: 1024px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      .cta-btn {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        padding: 1rem 2rem;
        background-color: #ffffff;
        color: #0033a0;
        border: 2px solid #0033a0;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        text-decoration: none;
        display: inline-block;
        margin-top: 40px;

        &:hover {
          background-color: #0033a0;
          color: #ffffff;
        }

        @media screen and (max-width: 1024px) {
          margin: 20px auto;
        }
      }
    }

    .hero-image-container {
      flex: 0 0 auto;
      margin: 0;

      @media screen and (max-width: 1024px) {
        margin: 30px auto;
      }

      @media screen and (max-width: 768px) {
        margin: 24px auto;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 50px 0;
      text-align: center;

      .hero-content {
        margin: 0 auto;
      }
    }
  }

  // Demo section styles
  .job-demo-section {
    position: relative;
    z-index: 2;
    padding: 60px 0;
    width: 100%;

    .demo-container {
      width: 100%;
      text-align: center;

      .demo-heading {
        font-size: 48px;
        font-weight: 700;
        color: #202124;
        margin-bottom: 40px;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 32px;
          margin-bottom: 30px;
        }
      }

      .demo-animation-container {
        position: relative;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .demo-icon {
          width: 100%;
          height: auto;
          object-fit: contain;
          border-radius: 8px;
          box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;

          @media screen and (max-width: 768px) {
            border-radius: 6px;
          }
        }

        /* Animation styles commented out for future use
        .demo-icon.expanded {
          transform: scale(var(--scale, 1));
          transition: transform 0.5s ease-out;
        }
        */
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 40px 0;
    }

    @media screen and (max-width: 768px) {
      padding: 32px 0;
    }
  }

  // Features section styles
  .job-features-section {
    padding: 80px 0;

    .feature-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 120px;
      gap: 80px;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      will-change: opacity, transform;

      &.animate {
        opacity: 0;
        transform: translateY(20px);
      }

      &.animate.visible {
        opacity: 1;
        transform: translateY(0);
      }

      // Stagger delays for all screen sizes
      &:nth-child(1) { transition-delay: 0s; }
      &:nth-child(2) { transition-delay: 0.1s; }
      &:nth-child(3) { transition-delay: 0.2s; }
      &:nth-child(4) { transition-delay: 0.3s; }

      &:last-child {
        margin-bottom: 0;
      }

      // Default state (odd rows): text on left, image on right
      .feature-content {
        flex: 1;
        max-width: 500px;
        text-align: left;

        h2 {
          font-size: 48px;
          font-weight: 700;
          margin-bottom: 24px;
          line-height: 1.2;
          color: #202124;
          transition: font-size 0.3s ease;
        }

        p {
          font-size: 20px;
          line-height: 1.6;
          color: #5f6368;
          transition: font-size 0.3s ease;
        }
      }

      .feature-image {
        flex: 1;
        max-width: 500px;

        img, .job-tile-animation {
          width: 100%;
          height: auto;
          border-radius: 8px;
          transition: transform 0.3s ease;
        }
      }

      // Even rows: image on left, text on right
      &:nth-child(even) {
        .feature-content {
          order: 2;
          text-align: right;
        }

        .feature-image {
          order: 1;
        }
      }

      @media screen and (max-width: 1024px) {
        gap: 60px;
        margin-bottom: 100px;

        .feature-content {
          h2 {
            font-size: 40px;
          }

          p {
            font-size: 18px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column !important;
        gap: 32px;
        margin-bottom: 64px;
        padding: 0 16px;

        .feature-content {
          order: 1 !important;
          text-align: left !important;
          max-width: 100%;

          h2 {
            font-size: 32px;
            margin-bottom: 16px;
          }

          p {
            font-size: 16px;
            line-height: 1.5;
            color: #5f6368;
            opacity: 0.9;
          }
        }

        .feature-image {
          order: 2 !important;
          max-width: 100%;
          margin: 8px 0;

          &:nth-child(1) { transition-delay: 0s; }
          &:nth-child(2) { transition-delay: 0.1s; }
          &:nth-child(3) { transition-delay: 0.2s; }
          &:nth-child(4) { transition-delay: 0.3s; }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 60px 0;
    }

    @media screen and (max-width: 768px) {
      padding: 48px 0;
    }
  }

  // FAQ Section Styles
  .faq-section {
    padding: 100px 0 120px;
    background: white;
    margin-bottom: -20px;

    @media screen and (max-width: 1024px) {
      padding: 80px 0 100px;
    }

    @media screen and (max-width: 768px) {
      padding: 60px 0 80px;
    }
  }

  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .faq-heading {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 60px;
    color: #202124;

    @media screen and (max-width: 1024px) {
      font-size: 40px;
      margin-bottom: 48px;
    }

    @media screen and (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 40px;
    }
  }

  .faq-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 0;
  }

  .faq-item {
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    background: white;
    overflow: visible;
    transition: border-color 0.2s ease;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out, border-color 0.2s ease;
    will-change: opacity, transform;

    &.animate {
      opacity: 0;
      transform: translateY(20px);
    }

    &.animate.visible {
      opacity: 1;
      transform: translateY(0);
    }

    // Stagger delays for FAQ items
    &:nth-child(1) { transition-delay: 0s; }
    &:nth-child(2) { transition-delay: 0.1s; }
    &:nth-child(3) { transition-delay: 0.2s; }
    &:nth-child(4) { transition-delay: 0.3s; }
    &:nth-child(5) { transition-delay: 0.4s; }
    &:nth-child(6) { transition-delay: 0.5s; }

    &:hover {
      border-color: rgba(0, 51, 160, 0.2);
    }
  }

  .faq-question {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 28px;
    cursor: pointer;
    background: white;
    border: none;
    text-align: left;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    border-radius: 12px;

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #202124;
      margin: 0;
      padding-right: 32px;
      line-height: 1.5;
      pointer-events: none;
    }

    .faq-icon {
      font-size: 24px;
      color: #5f6368;
      transition: transform 0.2s ease;
      pointer-events: none;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      position: relative;
      right: 4px;
    }
  }

  .faq-answer {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    
    p {
      color: #5f6368;
      font-size: 16px;
      line-height: 1.6;
      padding: 0 28px 24px;
      margin: 0;
    }
  }

  .faq-item.active {
    border-color: #0033a0;
    box-shadow: 0 2px 8px rgba(0, 51, 160, 0.08);

    .faq-question {
      h3 {
        color: #0033a0;
      }

      .faq-icon {
        transform: rotate(45deg);
        color: #0033a0;
      }
    }

    .faq-answer {
      height: auto;
    }
  }
}


