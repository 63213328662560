#job-details-modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99999;
}

#job-details-modal-root .modal-backdrop {
    pointer-events: all;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

body.modal-open {
    overflow: hidden;
    
    .sidebar {
        // Handled in sidebar.scss
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;

    @media (max-width: 768px) {
        z-index: 1002;
    }
}

.modal-backdrop.show {
    opacity: 1;
}

.modal-content {
    background: white;
    width: 99%;
    height: 95vh;
    padding: 0 40px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    max-height: 95vh;
    transform: scale(0.8);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), 
                opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;

    @media (max-width: 768px) {
        z-index: 1003;
    }
}

.modal-content.show {
    transform: scale(1);
    opacity: 1;
}

.sticky-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10001;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media (max-width: 768px) {
        z-index: 1004;
    }
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.tabs {
    display: flex;
    flex-wrap: wrap;
}

.tab-icon {
    margin-right: 0.35em;
}

.tab-icon img {
    filter: brightness(0) invert(1);
}

.tab {
    border: 1px solid #0033A0;
    color: #0033A0;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 6px !important;
    background: white;
    font-family: 'Inter', sans-serif;
    position: relative;
    text-align: left;
    transition: background 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    width: 200px;
    height: 40px;
    padding: 0 10px;
    font-weight: 500;
    font-size: 14px;

    .tab-icon img {
        filter: none;
    }

    &.selected {
        background: #0033A0;
        color: white;

        .tab-icon img {
            filter: brightness(0) invert(1);
        }
    }

    &:hover {
        background-color: #0033A0;
        color: #ffffff;

        .tab-icon img {
            filter: brightness(0) invert(1);
        }
    }
}

.close-button {
    background: none;
    border: none;
    font-size: 2em !important;
    cursor: pointer;
    color: #666;
    font-weight: 300;
    line-height: 1;
    transition: color 0.2s, transform 0.2s ease-in-out;

    &:hover {
        color: #000;
        transform: scale(1.1);
    }
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    transition: visibility 0s linear 0.3s, height 0s linear 0.3s;

    &.visible {
        visibility: visible;
        height: auto;
        overflow: visible;
        transition: visibility 0s linear 0s, height 0s linear 0s;
    }

    &.animate {
        animation: fadeIn 0.3s ease-in-out forwards;
        animation-delay: 0.1s;
    }

    .job-title-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .top-row {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        .bottom-row {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.company-logo-wrapper {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    .company-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;

        &.horizontal-logo {
            transform: scale(1.02);
        }

        &.fallback-logo {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.job-type-tag {
    font-family: 'Inter', sans-serif;
    display: inline-block;
    background-color: #D0E2FF;
    color: #003366;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;

    &.full-time {
        background-color: #D0E2FF;
        color: #003366;
    }

    &.internship {
        background-color: #D4EDDA;
        color: #155724;
    }

    &.contract {
        background-color: #FFE5CC;
        color: #CC5500;
    }

    &.part-time {
        background-color: #EDE0F8;
        color: #4B0082;
    }

    &.demo {
        background: #FFE5E5;
        color: #990000;
        font-family: 'Karla', sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }
}

.job-title h2 {
    font-family: 'Inter', sans-serif !important;
    font-weight: bold !important;
    font-size: 22px !important;
    margin: 0 0 4px 0;
    color: #333 !important;
}

.company-location {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #717171 !important;

    .external-link {
        color: #0033A0;
        text-decoration: none;
        transition: color 0.2s ease;
        margin-left: 4px;
        font-weight: 500;
        
        .arrow {
            display: inline-block;
            transition: transform 0.2s ease;
            margin-left: 2px;
            font-size: 1.2em;
            position: relative;
            top: -1px;
        }

        &:hover {
            color: #0056b3;
            
            .arrow {
                transform: translate(2px, -2px);
            }
        }
    }
}

.tailor-button {
    background: #0033A0 !important;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px !important;
    cursor: pointer;
    transition: all 0.15s ease;
    font-weight: 600;
    white-space: nowrap;

    &:hover {
        background: #0044CC !important;
        transform: scale(1.02);
        box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
    }
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;

    .delete-job-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #ef4444;
        text-decoration: none;
        transition: all 0.2s ease;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        gap: 6px;
        padding: 8px 0;
        font-size: 14px;
        min-width: 180px;
        border: none;
        background: none;
        cursor: pointer;
        
        .trash-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease;
            transform-origin: center;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &:hover {
            color: #dc2626;

            .trash-icon {
                animation: wiggle 0.3s ease-in-out;
            }
        }
    }

    .external-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #0033A0;
        text-decoration: none;
        transition: all 0.2s ease;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        gap: 6px;
        padding: 8px 0;
        font-size: 14px;
        min-width: 180px;
        
        .external-icon {
            transition: transform 0.2s ease;
        }

        &:hover {
            color: #0033A0;
            
            .external-icon {
                transform: translate(2px, -2px);
            }
        }
    }

    .tailor-button {
        background: #0033A0 !important;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.15s ease;
        font-weight: 600;
        white-space: nowrap;
        font-family: 'Inter', sans-serif;

        &:hover {
            background: #0044CC !important;
            transform: scale(1.02);
            box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
        }
    }
}

.separator-line {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 10px 0;
}

.modal-body {
    flex-grow: 1;
    overflow-y: auto !important;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-height: calc(95vh - 150px) !important;
    opacity: 0;
    animation: fadeIn 0.3s ease-in-out forwards;
    animation-delay: 0.2s;
    transition: background-color 0.3s ease;
    
    /* Ensure smooth transition for all children */
    * {
        backface-visibility: hidden;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.left-column {
    flex: 2;
    padding-right: 20px;

    h2, h3 {
        margin-top: 0;
        font-weight: bold;
        font-family: 'Inter', sans-serif;
    }

    h3 {
        margin-bottom: 5px;
        font-size: 1.1em;
    }

    p, ul {
        margin-top: 0;
        margin-bottom: 20px;
        font-family: 'Inter', sans-serif;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        margin-left: 0;

        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 0.5rem;
            line-height: 1.5;

            .bullet-point {
                position: absolute;
                left: 8px;
                color: #333;
                font-size: 18px;
                line-height: 1.2;
                display: inline-block;
                width: 12px;
                text-align: center;
            }

            .requirement-text,
            .responsibility-text {
                display: inline-block;
                color: #000;
                width: auto;
                vertical-align: top;
                text-align: left;
            }
        }
    }

    .requirements-list,
    .responsibilities-list {
        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 0.5rem;
        }
    }

    .skills {
        display: flex;
        flex-wrap: wrap;
    }

    .skill-badge {
        background: #e0e0e0;
        border-radius: 20px;
        padding: 5px 10px;
        margin: 5px;
    }

    .tailor-button {
        background: #0033A0 !important;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.15s ease;
        font-weight: 600;
        white-space: nowrap;
        margin-top: 20px;

        &:hover {
            background: #0044CC !important;
            transform: scale(1.02);
            box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
        }
    }
}

.right-column {
    flex: 1;
    padding-left: 20px;
    margin-top: 0;
    margin-left: auto;

    .job-info-box {
        width: 100%;
        padding: 15px;
        background: white;
        border: 1px solid black;
        border-radius: 8px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;

        h4 {
            margin: 0;
            font-size: 0.9em;
            font-weight: bold;
            font-family: 'Inter', sans-serif;
            color: grey;
            display: flex;
            align-items: center;
            gap: 6px;

            .status-dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-left: 2px;
                flex-shrink: 0;

                &.recent {
                    background-color: #4CAF50;
                }

                &.moderate {
                    background-color: #FF9800;
                }

                &.old {
                    background-color: #F44336;
                }
            }

            &:not(:first-child) {
                margin-top: 20px; // Space between sections
            }
        }

        p {
            margin: 0.5rem 0 1rem;
            color: #000000;
            font-size: 1.2em;
            font-weight: bold;
            font-family: 'Inter', sans-serif;
        }

        .about-company-text {
            font-size: 1em;
            font-weight: normal;
            font-family: 'Inter', sans-serif;
        }

        & > h4:not(:first-child) {
            margin-top: 20px;
        }

        & > p:not(:first-of-type) {
            margin-top: 0px;
        }

        .about-company {
            margin-top: 40px;
        }

        .locations-section {
            margin-top: 20px;

            .locations-list {
                transition: all 0.3s ease-in-out;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 8px;

                p {
                    margin: 0;
                    color: #000000;
                    font-size: 1.2em;
                    font-weight: bold;
                    font-family: 'Inter', sans-serif;
                    transition: all 0.3s ease-in-out;

                    &.ellipsis {
                        color: #666;
                        margin-top: -4px;
                    }
                }

                .show-more-button {
                    background: none;
                    border: none;
                    color: #0033A0;
                    padding: 4px 0;
                    margin-top: 8px;
                    cursor: pointer;
                    font-family: 'Inter', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    transition: all 0.2s ease;
                    align-self: flex-start;

                    &:hover {
                        color: #0044CC;
                        text-decoration: underline;
                    }
                }

                &.expanded {
                    p {
                        &.ellipsis {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .header-section {
        align-items: flex-end;

        .company-logo-wrapper {
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
        }

        .job-title h2 {
            font-size: 1.5em;
        }

        .tailor-button {
            width: auto;
        }
    }

    .modal-body {
        flex-direction: column;

        .left-column {
            padding-right: 0;
            margin-bottom: 20px;
        }

        .right-column {
            padding-left: 0;
        }
    }

    .tabs {
        justify-content: center;
        flex-wrap: wrap;

        .tab {
            max-width: none;
        }
    }
}

.content-container {
    display: flex;
    gap: 40px;
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
    
    /* Remove staggered animations and use a unified, professional fade-in */
    .left-column {
        .detail-section {
            p, ul.requirements-list li, ul.responsibilities-list li, .skills .skill-badge {
                animation: smoothFadeIn 0.4s ease-out forwards;
                animation-delay: 0.05s !important; /* Single unified delay */
                opacity: 0;
            }
        }
    }
    
    .right-column {
        .job-info-box {
            p, .locations-section, .about-company-text {
                animation: smoothFadeIn 0.4s ease-out forwards;
                animation-delay: 0.05s !important; /* Single unified delay */
                opacity: 0;
            }
        }
    }
}

.demo-notification {
    background: rgba(0, 51, 160, 0.1);
    border: 1px solid rgba(0, 51, 160, 0.2);
    padding: 16px 24px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;

    .demo-notification-content {
        max-width: 100%;
        display: block;

        .demo-text {
            strong {
                display: block;
                color: #0033A0;
                margin-bottom: 4px;
                font-size: 16px;
                font-weight: 600;
                font-family: 'Inter', sans-serif;
            }

            p {
                color: #555;
                margin: 0;
                font-size: 14px;
                line-height: 1.5;
                font-family: 'Inter', sans-serif;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 14px 20px;
        margin-bottom: 12px;

        .demo-notification-content {
            .demo-text {
                strong {
                    font-size: 15px;
                }

                p {
                    font-size: 13px;
                }
            }
        }
    }
}

.demo-description {
    color: #666;
    font-style: italic;
    background: #f8f9fa;
    padding: 12px 16px;
    border-radius: 8px;
    border-left: 4px solid #FFE5E5;
    margin: 8px 0;
}

.company-location {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #717171 !important;
    display: flex;
    align-items: center;
    gap: 4px;

    .external-link {
        color: #0033A0;
        text-decoration: none;
        transition: color 0.2s ease;
        font-weight: 500;
        display: inline-flex;
        align-items: center;

        .arrow {
            display: inline-flex;
            transition: transform 0.2s ease;
            margin-left: 2px;
            font-size: 1.2em;
        }

        &:hover {
            color: #0056b3;

            .arrow {
                transform: translate(2px, -2px);
            }
        }
    }
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1008;

    .loading-spinner {
        width: 40px;
        height: 40px;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        margin-bottom: 1rem;
    }

    p {
        color: #666;
        font-size: 1rem;
    }
}

.error-message {
    background-color: #fff3f3;
    border: 1px solid #ffcdd2;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        color: #d32f2f;
        margin: 0;
    }

    button {
        background: none;
        border: none;
        color: #666;
        cursor: pointer;
        padding: 0.5rem;
        font-size: 0.875rem;
        
        &:hover {
            color: #d32f2f;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.delete-confirm-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    &.closing {
        animation: fadeOut 0.2s ease-in-out forwards;
    }
}

.delete-confirm-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    z-index: 1000000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h2 {
        margin: 0 0 12px 0;
        font-size: 20px;
        color: #111827;
    }

    p {
        margin: 0 0 24px 0;
        color: #6b7280;
        font-size: 14px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        gap: 12px;

        button {
            padding: 8px 24px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;

            &.keep-button {
                background: white;
                color: #374151;
                border: 1px solid #d1d5db;

                &:hover {
                    background: #f3f4f6;
                }
            }

            &.discard-button {
                background: #ef4444;
                color: white;
                border: none;

                &:hover {
                    background: #dc2626;
                }
            }

            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-10deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
}

/* Processing container and spinner styles */
.processing-container {
    width: 100%;
    padding: 2rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    
    .spinner {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 4px solid #e5e7eb;
        border-top-color: #3b82f6;
        animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}

.error-container {
    width: 100%;
    margin-bottom: 1rem;
}

/* Pulse animation for skeleton loading */
.animate-pulse {
    animation: pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    
    @keyframes pulse {
        0%, 100% {
            opacity: 0.9;
        }
        50% {
            opacity: 0.5;
        }
    }
}

/* Add a balanced pulse animation for skills and info boxes */
@keyframes balanced-pulse {
    0%, 100% {
        opacity: 1;
        background-color: #c7c7c7; /* Even darker start color for more contrast */
    }
    50% {
        opacity: 0.7;
        background-color: #e8e8e8; /* Lighter end color for more contrast */
    }
}

.detail-section {
    margin-bottom: 1.5rem;
    
    h3 {
        margin-bottom: 0.75rem;
        font-weight: 600;
        color: #333;
    }
    
    ul.requirements-list,
    ul.responsibilities-list,
    ul.preferred-list,
    ul.benefits-list {
        list-style: none;
        padding-left: 0;
        margin-top: 0.5rem;
        
        li {
            position: relative;
            display: flex;
            margin-bottom: 0.5rem;
            line-height: 1.5;
            color: #444;
            padding-left: 1.5em;
            
            .bullet-point {
                position: absolute;
                left: 8px;
                color: #444;
                font-weight: normal;
                display: inline-block;
                width: 1em;
                text-align: center;
            }
            
            .requirement-text,
            .responsibility-text,
            .benefit-text {
                flex: 1;
                color: #000;
                display: inline-block;
                text-align: left;
                text-indent: 0;
                padding-left: 0;
            }
        }
    }
}

.skills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
    
    .skill-badge {
        background-color: #f0f7ff;
        color: #0057b8;
        padding: 0.3rem 0.6rem;
        border-radius: 1rem;
        font-size: 0.85rem;
        display: inline-block;
        border: 1px solid #e0ecff;
    }
}

.job-description {
    width: 100%;

    .content-container {
        display: flex;
        gap: 40px;
        width: 100%;
        
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
}

/* Skeleton loading animation styles */
.skeleton-line {
  height: 14px;
  background: #eaeaea;
  border-radius: 4px;
  margin-bottom: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shimmer 1.8s infinite;
  }
  
  &:nth-child(2) {
    width: 92%;
  }
  
  &:nth-child(3) {
    width: 75%;
  }
}

.skeleton-list {
  padding-left: 0;
  margin-top: 12px;
  
  .skeleton-item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 14px;
    
    .bullet-point {
      position: absolute;
      left: 8px;
      color: #d1d5db;
      font-size: 18px;
      line-height: 1.2;
    }
    
    .skeleton-line {
      height: 14px;
      background: #eaeaea;
      border-radius: 4px;
      margin-bottom: 0;
    }
    
    &:nth-child(1) .skeleton-line {
      width: 85%;
      animation-delay: 0.1s;
    }
    
    &:nth-child(2) .skeleton-line {
      width: 90%;
      animation-delay: 0.2s;
    }
    
    &:nth-child(3) .skeleton-line {
      width: 80%;
      animation-delay: 0.3s;
    }
    
    &:nth-child(4) .skeleton-line {
      width: 88%;
      animation-delay: 0.4s;
    }
  }
}

.skeleton-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
  
  .skeleton-skill {
    height: 32px;
    width: 80px;
    background: #eaeaea;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    
    /* Apply the balanced pulse animation for skills */
    animation: balanced-pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
      animation: shimmer 1.8s infinite;
    }
    
    &:nth-child(1) {
      width: 70px;
      animation-delay: 0.1s;
    }
    
    &:nth-child(2) {
      width: 120px;
      animation-delay: 0.3s;
    }
    
    &:nth-child(3) {
      width: 95px;
      animation-delay: 0.5s;
    }
    
    &:nth-child(4) {
      width: 110px;
      animation-delay: 0.7s;
    }
    
    &:nth-child(5) {
      width: 85px;
      animation-delay: 0.9s;
    }
  }
}

.skeleton-data {
  height: 22px;
  background: #eaeaea;
  border-radius: 4px;
  margin: 8px 0 20px 0;
  width: 70%;
  position: relative;
  overflow: hidden;
  
  /* Apply the balanced pulse animation for job info boxes */
  animation: balanced-pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shimmer 1.8s infinite;
  }
  
  &:nth-of-type(1) {
    width: 40%;
    animation-delay: 0.2s;
  }
  
  &:nth-of-type(2) {
    width: 65%;
    animation-delay: 0.4s;
  }
  
  &:nth-of-type(3) {
    width: 55%;
    animation-delay: 0.6s;
  }
  
  &:nth-of-type(4) {
    width: 45%;
    animation-delay: 0.8s;
  }
  
  &:nth-of-type(5) {
    width: 60%;
    animation-delay: 1s;
  }
}

.skeleton-text {
  height: auto;
  margin-bottom: 0;
  background: transparent;
  
  &::before, &::after {
    content: "";
    display: block;
    height: 14px;
    background: #eaeaea;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  &::before {
    margin-bottom: 8px;
    width: 100%;
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
      animation: shimmer 1.8s infinite;
    }
  }
  
  &::after {
    margin-top: 8px;
    width: 80%;
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
      animation: shimmer 1.8s infinite;
    }
  }
}

/* Add a middle line for the skeleton text */
.skeleton-text::before {
  position: relative;
  
  &::before {
    content: "";
    display: block;
    height: 14px;
    background: #eaeaea;
    border-radius: 4px;
    margin: 8px 0;
    width: 90%;
    position: relative;
    overflow: hidden;
  }
}

/* Shimmer animation */
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Update the animate-pulse classes to respect the dedicated animations */
.animate-pulse .skeleton-line,
.animate-pulse .skeleton-text::before,
.animate-pulse .skeleton-text::after {
  animation: pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  
  &::after {
    display: none;
  }
}

/* Separate rules for skills and data to use the balanced pulse animation */
.animate-pulse .skeleton-skill,
.animate-pulse .skeleton-data {
  animation: balanced-pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  
  &::after {
    display: none;
  }
}

.animate-pulse .skeleton-text::before::before {
  animation: pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
}

/* Skeleton loading styles */
.skeleton-loading {
  /* Make sure the skeleton container has positioning for stacking context */
  position: relative;
  width: 100%;
  
  .skeleton-line,
  .skeleton-text::before,
  .skeleton-text::after,
  .skeleton-text::before::before {
    animation: balanced-pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: #d1d1d1; /* Darker base color */
    
    &::after {
      display: none;
    }
  }
  
  /* Explicitly improve the job info box skeleton styling */
  .right-column .job-info-box {
    .skeleton-content {
      display: block;
      margin-bottom: 4px; /* Further reduced */
    }
    
    .skeleton-data {
      display: block;
      height: 20px; /* Slightly smaller height */
      width: 70%;
      background-color: #c7c7c7; /* Darker for better visibility */
      margin: 4px 0 10px; /* Even smaller bottom margin */
      animation: balanced-pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
    
    h4.section-heading {
      margin-top: 14px; /* Adjusted spacing */
      
      &:first-child {
        margin-top: 0;
      }
    }
  }
  
  /* Fix the about the job section */
  .left-column .detail-section:first-child {
    .skeleton-content {
      margin-top: 10px;
    }
    
    .skeleton-line {
      height: 14px;
      margin-bottom: 12px;
      background-color: #d1d1d1;
      border-radius: 4px;
    }
  }
  
  /* Apply balanced pulse for skills and data in skeleton-loading class too */
  .skeleton-skill,
  .skeleton-data {
    animation: balanced-pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: #d1d1d1; /* Darker base color */
    
    &::after {
      display: none;
    }
  }
  
  /* Add styles for persistent headings */
  .section-heading {
    transition: opacity 0.3s ease;
    position: relative;
    z-index: 5; /* Ensure headings are always above everything */
    
    &.persistent {
      opacity: 1 !important;
    }
  }
  
  /* Status dot should maintain its correct color */
  .status-dot {
    opacity: 1 !important;
  }
  
  /* Give skeleton sections proper spacing */
  .detail-section {
    min-height: 70px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .skeleton-content {
    min-height: 50px;
    position: relative;
    width: 100%;
  }
  
  /* Add fade-out animation for skeleton content only */
  &.fade-out {
    .skeleton-content {
      animation: fadeOutSkeletonContent 0.6s forwards;
    }
    
    /* Keep section headings visible */
    .section-heading {
      animation: none;
      opacity: 1 !important;
    }
  }
}

/* Fade out animation for skeleton content - make it more gradual */
@keyframes fadeOutSkeletonContent {
  0% {
    opacity: 1;
    visibility: visible;
  }
  99% {
    opacity: 0.3;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Add a smooth fade-in animation for content - more subtle and gradual */
@keyframes smoothFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Ensure section content transitions smoothly */
.section-content {
  animation: smoothFadeIn 0.4s ease-out forwards;
  will-change: opacity; /* Optimize rendering */
  backface-visibility: hidden; /* Reduce flickering */
  position: relative;
  width: 100%;
  
  &[data-replacing-skeleton="true"] {
    animation-delay: 0.15s; /* Shorter delay for better synchronization */
    z-index: 3;
  }
}

/* Prevent overlap between skeleton and content */
.detail-section {
  position: relative;
  min-height: 60px;
  margin-bottom: 24px; /* Add more spacing between sections */
  
  /* Ensure proper content stacking */
  .section-content {
    position: relative;
  }
  
  .skeleton-content {
    position: relative;
  }
}

/* Improve skeleton items appearance */
.skeleton-item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  
  .bullet-point {
    margin-right: 8px;
    display: inline-block;
    flex-shrink: 0;
  }
  
  .skeleton-line {
    flex: 1;
  }
}

/* Explicitly prevent any overlap in job info boxes */
.job-info-box {
  .section-content {
    margin-bottom: 20px;
  }
  
  h4.section-heading {
    margin-bottom: 8px;
  }
}

/* Fix spacing in About the Company section */
.about-company {
  margin-top: 20px;
}

/* Improve global transitioning state */
body.skeleton-transitioning {
  /* Keep content below from flashing */
  .job-description {
    position: relative;
    
    /* Prevent any unexpected layout shifts */
    .left-column, .right-column {
      position: relative;
    }
  }
  
  /* Prevent section content from flashing in too early */
  .section-content {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  /* Fix the job info box loading pipes to prevent flickering */
  .right-column .job-info-box .skeleton-content {
    opacity: 1; /* Fully visible during transition */
    position: relative;
    margin-bottom: 4px; /* Reduced margin */
    transition: opacity 0.4s ease; /* Add transition to prevent abrupt changes */
    
    /* Ensure pipes are prominent */
    .skeleton-data {
      position: relative;
      display: block;
      height: 20px; /* Slightly smaller height */
      background-color: #c7c7c7; /* Darker for better visibility */
      margin: 4px 0 10px; /* Even smaller bottom margin */
      animation: balanced-pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
    }
  }
  
  /* Fix overlap in the About the Job section to prevent flickering */
  .left-column .detail-section .skeleton-content {
    opacity: 1; /* Fully visible */
    z-index: 2;
    transition: opacity 0.4s ease; /* Add transition to prevent abrupt changes */
    
    .skeleton-line {
      margin-bottom: 12px;
      background-color: #c7c7c7; /* Darker for better visibility */
      height: 14px;
    }
  }
  
  /* Proper positioning for sections */
  .detail-section {
    position: relative;
    margin-bottom: 20px; /* Less space between sections */
  }
  
  /* Keep section headings visible */
  .detail-section h3.section-heading,
  .job-info-box h4.section-heading {
    opacity: 1 !important;
    position: relative;
    z-index: 10;
    visibility: visible !important;
  }
  
  /* Status dot should maintain its correct color */
  .status-dot {
    opacity: 1 !important;
  }
}

/* Add a more subtle pulse animation for error states */
.animate-subtle-pulse {
  animation: subtlePulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 20px;
  background-color: #fff8f8;
  border-left: 4px solid #e53e3e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #fff0f0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  p {
    margin: 0;
    color: #e53e3e;
    font-weight: 500;
  }
}

@keyframes subtlePulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

/* Make all skeleton elements use the improved pulse animation */
.skeleton-loading {
  .skeleton-line,
  .skeleton-text::before,
  .skeleton-text::after,
  .skeleton-text::before::before {
    animation: balanced-pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: #d1d1d1; /* Darker base color */
    
    &::after {
      display: none;
    }
  }
  
  /* Apply balanced pulse for skills and data in skeleton-loading class too */
  .skeleton-skill,
  .skeleton-data {
    animation: balanced-pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: #d1d1d1; /* Darker base color */
    
    &::after {
      display: none;
    }
  }
}

/* Enhance the bullet points contrast */
.skeleton-item .bullet-point {
  color: #999; /* Darker bullet point color */
}

/* Improve the visibility of the pulse animation */
@keyframes pulse {
  0%, 100% {
    opacity: 0.95;
    background-color: #d1d1d1; /* Darker state */
  }
  50% {
    opacity: 0.6;
    background-color: #e5e5e5; /* Lighter state */
  }
}

/* Make skeleton items more compact in both columns */
.detail-section {
  min-height: 70px; /* Reduced from 80px */
  margin-bottom: 20px; /* Reduced from 24px */
}

/* Fix for left column skeleton items */
.left-column .detail-section {
  .skeleton-content {
    min-height: 50px; /* Less minimum height */
  }
  
  .skeleton-list {
    .skeleton-item {
      margin-bottom: 10px; /* Reduced from 14px */
    }
  }
}

/* Reduce spacing in right column for loading state */
.right-column .job-info-box h4.section-heading:not(:first-child) {
  margin-top: 16px; /* Less space between sections */
}

/* Add missing fix for about-company heading */
.right-column .job-info-box h4.about-company {
  margin-top: 14px !important; /* Ensure consistent spacing */
}
