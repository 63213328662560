@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.container {
  max-width: 400px;
  margin: 0 auto;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  text-decoration: none;
  margin-bottom: 24px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  padding: 12px;
  font-size: 32px;
  font-weight: 600;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.2s;
  background-color: #F3F4F6;
  caret-color: transparent;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: calc(var(--caret-position, 0) + 12px);
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 32px;
    background-color: #0033A0;
    animation: blink-caret 1s infinite;
  }
}

.input:focus {
  border-color: #6366F1;
  background-color: white;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.onboarding-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;

  .progress-bar-container {
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
    margin-bottom: 1rem;
    border-radius: 10px;
    overflow: hidden;
  }

  .progress-bar-filled {
    height: 100%;
    background-color: #0033A0;
    transition: width 0.6s ease-in-out;
    border-radius: 10px;
  }

  .step-content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;

    &.fade-in {
      animation: fadeIn 0.4s ease-in;
    }

    &.fade-out {
      animation: fadeOut 0.4s ease-out;
    }

    .step-left-align {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 2rem;
      max-width: 1200px;
      margin-left: 5rem;
      margin-right: auto;

      .back-button {
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        background-color: transparent;
        color: black;
        border: none;
        display: flex;
        align-items: center;
        gap: 0.3rem;

        .back-arrow {
          display: inline-block;
          width: 14px;
          height: 14px;

          .back-svg {
            width: 100%;
            height: 100%;
            stroke: #666;
          }
        }

        &:hover {
          color: #555;
        }
      }

      h1 {
        font-size: 3rem;
        font-weight: 700;
        color: #0033A0;
        margin-bottom: 1rem;
        text-align: left;
        line-height: 1.2;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 0.5rem;
          color: #000;
          display: flex;
          align-items: center;

          input {
            margin-right: 0.5rem;
          }
        }
      }

      .next-wrapper {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        .next-button {
          background-color: #6C63FF;
          color: white;
          font-size: 1.1rem;
          font-weight: 600;
          border: none;
          border-radius: 50px;
          padding: 0.8rem 2rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
          text-align: center;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

          &:hover {
            background-color: #5A52D0;
          }
        }

        .enter-shortcut {
          display: flex;
          align-items: center;
          margin-left: 1rem;
          opacity: 0.6;

          .enter-icon {
            background-color: #F0F0F0;
            border-radius: 4px;
            padding: 0.15rem 0.4rem;
            font-size: 0.85rem;
            color: #666;
            margin-right: 0.5rem;
          }

          .enter-text {
            font-size: 0.85rem;
            color: #666;
            font-weight: 400;
          }
        }
      }

      .bubble-group {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 1.5rem;
        width: 100%;
        max-width: 900px;
      }

      .bubble {
        padding: 0.6rem 1.4rem;
        border: 1px solid #0033A0;
        border-radius: 50px;
        font-size: 1.1rem;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.3s, border-color 0.3s, color 0.3s;
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        background-color: #f8f8f8;
        white-space: nowrap;
        flex: 0 1 auto;

        &.selected {
          background-color: #0033A0;
          color: white;
          border-color: transparent;
        }

        &:hover {
          background-color: #e6e6ff;
        }
      }

      @media (max-width: 480px) {
        .bubble-group {
          gap: 10px;
        }

        .bubble {
          font-size: 1rem;
          padding: 0.5rem 1.2rem;
        }
      }

      // Job role input styles
      .job-role-input {
        width: 100%;
        padding: 0.75rem 0;
        font-size: 32px;
        font-weight: 600;
        border: none;
        background: none;
        color: #000;
        caret-color: #0033A0;

        &::placeholder {
          color: #999;
          opacity: 0.5;
          text-transform: lowercase;
        }

        &:focus {
          outline: none;
          background: none;
          border: none;
        }
      }

      @media (max-width: 768px) {
        .job-role-input {
          font-size: 28px;
        }
      }

      @media (max-width: 480px) {
        .job-role-input {
          font-size: 24px;
        }
      }

      // Ghost input styles
      .ghost-input-wrapper {
        position: relative;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-x: hidden; /* IE and Edge */
        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera */
        }

        .ghost-input-container {
          position: relative;
          min-width: min-content;
          display: inline-block;

          .job-role-input {
            position: relative;
            z-index: 2;
            background: transparent;
            white-space: nowrap;
            width: auto;
            min-width: 500px;
          }

          .ghost-text {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            white-space: nowrap;
            padding: 0.75rem 0;
            font-size: 32px;
            font-weight: 600;
            color: #000;
            pointer-events: none;
            user-select: none;
            z-index: 1;

            .suggested-text {
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }

      .autocomplete-instruction {
        margin-top: 0.5rem;
        color: #666;
        font-size: 0.9rem;
      }
    }

    .welcome-container,
    .centered-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      max-width: 800px;
      margin: 0 auto;

      h1 {
        font-size: 2.8rem;
        font-weight: 700;
        color: #0033A0;
        text-align: center;
        margin-bottom: 1.5rem;
        line-height: 1.2;
      }

      p {
        font-size: 1.3rem;
        font-weight: 400;
        color: #555;
        text-align: center;
        max-width: 700px;
        margin-bottom: 3rem;
        line-height: 1.6;
      }

      .start-button,
      .finish-button {
        background-color: #0033A0;
        color: white;
        font-size: 1.1rem;
        font-weight: 600;
        border: none;
        border-radius: 50px;
        padding: 1rem 3rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-align: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          background-color: #00297A;
        }
      }

      @media (max-width: 768px) {
        padding: 0 1.5rem;

        h1 {
          font-size: 2.4rem;
        }

        p {
          font-size: 1.2rem;
          margin-bottom: 2.5rem;
        }
      }

      @media (max-width: 480px) {
        padding: 0 1rem;

        h1 {
          font-size: 2rem;
        }

        p {
          font-size: 1.1rem;
          margin-bottom: 2rem;
        }

        .start-button,
        .finish-button {
          font-size: 1rem;
          padding: 0.8rem 2rem;
        }
      }
    }
  }

  .floating-emoji-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    perspective: 1000px;

    .emoji {
      position: absolute;
      width: 80px;
      height: 80px;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      opacity: 0;
      animation: comeTowards 4s forwards ease-out, float 5s infinite ease-in-out;
      transform-style: preserve-3d;

      &.emoji-1 {
        top: 20%;
        left: 10%;
        animation-delay: 0s, 4s;
      }

      &.emoji-2 {
        top: 50%;
        right: 15%;
        animation-delay: 0.5s, 4.5s;
      }

      &.emoji-3 {
        top: 70%;
        left: 25%;
        animation-delay: 1s, 5s;
      }
    }

    @keyframes comeTowards {
      0% {
        transform: translateZ(-300px) scale(0.5);
        opacity: 0;
      }
      100% {
        transform: translateZ(0px) scale(1);
        opacity: 1;
      }
    }

    @keyframes float {
      0% {
        transform: translateY(0) translateZ(0);
      }
      50% {
        transform: translateY(-10px) translateZ(0);
      }
      100% {
        transform: translateY(0) translateZ(0);
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes blink-caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}

.loading-state-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    font-size: 16px;
    color: #666;
  }

  .error-message {
    color: #dc3545;
  }
}

.resume-upload-description {
  font-family: 'Inter', sans-serif;
  color: #6B7280;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0 0 2rem;
  max-width: 500px;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.01em;
}

.required {
  color: #FF4B55;
  margin-left: 4px;
  font-size: 1.2rem;
}
