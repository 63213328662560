.resume-rectangle {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 20px;
  height: auto; // Allow container to expand to content height
  overflow: visible; // Ensure content is not cut off
  min-height: fit-content; // Ensure container fits content

  &.locked {
    animation: glowing 1.5s infinite alternate;
    
    .resume-header {
      isolation: isolate;  // Isolate header from parent effects
      z-index: 2;         // Ensure it stays above the glow effect
      
      .resume-name, 
      .contact-info > div {
        transform: none !important;
        backface-visibility: hidden;    // Prevent 3D rendering issues
        perspective: none;              // Disable 3D transformations
        transform-style: flat;          // Force flat rendering
        text-rendering: geometricPrecision;
        font-kerning: normal;           // Maintain letter spacing
        font-feature-settings: "kern" 1;  // Enable kerning
      }
    }
  }

  // Set base font family
  font-family: Helvetica, Arial, sans-serif;

  .resume-header {
    text-align: center;
    margin-bottom: 20px;  // This controls space between header and next section

    .resume-name {
      font-size: 18pt;
      font-weight: bold;
      border: none;
      outline: none;
      width: 100%;
      text-align: center;
      background: transparent;
      color: #000;
      margin: 0;         // Add this to remove any default margins
      padding: 0;        // Add this to remove any default padding
      line-height: 1.2;  // Add this to control exact spacing
    }

    .contact-info {
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      margin-top: 5px;  // Add small space between name and contact info

      .divider {
        transform: scaleY(1.5);
        display: inline-block;
        margin: 0 5px;
      }

      // Target the text node containing |
      > div:not([contentEditable="true"]) {
        transform: scaleY(1.5);
        display: inline-block;
        margin: 0 5px;
      }
    }
  }

  .resume-section {
    margin-bottom: 20px;

    // Target all section headings directly
    h2 {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: uppercase;
      border-bottom: 1px solid #000000 !important; // Force black border
      padding-bottom: 5px;
    }

    .resume-item {
      margin-bottom: 20px; // Increased from 12px
      padding-bottom: 20px; // Added padding
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); // Optional subtle separator

      .resume-experience-header,
      .resume-experience-subheader {
        display: flex;
        justify-content: space-between;

        input {
          font-size: 10.5pt;
          border: none;
          outline: none;
          background: transparent;
          color: #000;
        }

        .resume-degree,
        .resume-title {
          width: calc(50% - 10px);
          font-weight: bold;
          font-size: 11pt;
        }

        .resume-graduation,
        .resume-duration {
          text-align: right;
          width: calc(50% - 10px);
          margin-right: 20px;
          font-size: 10.5pt;
        }
      }

      .resume-university {
        font-size: 10.5pt;
        font-weight: bold;
        margin-bottom: 5px;
        border: none;
        outline: none;
        width: 100%;
        background: transparent;
        color: #000;
        text-align: left;
      }

      .resume-description,
      .resume-project-description {
        margin: 0; // Remove all margins, including the 5px left margin
        font-size: 15px;
        line-height: 1.4;
      }

      ul {
        list-style: disc;
        padding-left: 20px;

        li {
          font-size: 10.5pt;
          color: #000;
          position: relative;
          line-height: 1.4;
          margin-bottom: 4px;

          input {
            border: none;
            outline: none;
            font-size: 10.5pt;
            color: #000;
            background: transparent;
            width: 100%;
          }

          &:focus {
            outline: none;
            background: transparent;
          }
        }
      }
    }
  }

  .education-section {
    .education-item {
      margin-bottom: 20px;  // Same spacing as experience items

      .education-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        .education-title {
          font-size: 15px;
          font-weight: bold;
        }

        .gpa.right-aligned {
          font-size: 15px;
          text-align: right;
        }
      }

      .education-subheader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .degree-field {
          font-size: 15px;
        }

        .graduation-date.right-aligned {
          font-size: 15px;
          text-align: right;
        }
      }
    }
  }

  .experience-section {
    .experience-item {
      .job-title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 2px; // Reduced from 5px to match education spacing
      }

      .experience-header {
        margin-top: 0; // Ensure no extra space
        display: flex;
        justify-content: space-between;
        align-items: center;

        .duration.right-aligned {
          font-size: 15px;
          text-align: right;
        }
      }

      .experience-subheader {
        margin-top: 0; // Ensure no extra space
        display: flex;
        justify-content: space-between;

        .company-name {
          font-size: 15px;
        }

        .location.right-aligned {
          font-size: 15px;
          text-align: right;
        }
      }

      .resume-description,
      ul li {  // Bullet points
        font-size: 15px;
        line-height: 1.4;
        margin-bottom: 4px;
        
        input {
          font-size: 15px;
        }
      }

      // Company info already at 15px
      .resume-company,
      .resume-location,
      .resume-duration {
        font-size: 15px;
      }

      // Keep standard spacing between different experience items
      margin-bottom: 20px;
    }
  }

  // Projects section specific styling
  .projects-section {
    .project-item {
      margin-bottom: 20px;  // Match experience section spacing

      .project-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;  // Match experience section spacing

        .project-title-container {
          display: flex;
          align-items: center;  // Align items vertically
          
          .project-title {
            font-size: 15px;
            font-weight: bold;
            margin-right: 4px;  // Small space before the dash
          }

          .project-name {
            font-size: 15px;
            &::before {
              content: "-";
              margin: 0 4px;  // Equal spacing around the dash
            }
          }
        }

        .project-duration.right-aligned {
          font-size: 15px;
          text-align: right;
        }
      }

      // Match experience section's description and bullet points spacing
      .resume-description,
      .resume-project-description,
      ul li {
        font-size: 15px;
        line-height: 1.4;
        margin-bottom: 4px;
      }
    }
  }

  // Skills and Interests sections
  .resume-skills,
  .resume-interests {
    font-size: 15px;
    line-height: 1.4;
  }

  // Remove container highlight on focus/click
  [contentEditable="true"] {
    outline: none;
    border-color: transparent;
    
    &:focus {
      outline: none;
      border-color: transparent;
    }

  }

  // Custom text selection styling with !important
  *::selection {
    background-color: rgba(0, 51, 160, 0.3) !important;
    color: inherit !important;
    border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
  }

  *::-moz-selection {
    background-color: rgba(0, 51, 160, 0.3) !important;
    color: inherit !important;
    border-radius: 2px !important;
    -moz-border-radius: 2px !important;
  }

  // More specific selectors with !important
  p::selection, div::selection, span::selection, 
  li::selection, h2::selection, input::selection {
    background-color: rgba(0, 51, 160, 0.3) !important;
    color: inherit !important;
    border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
  }

  p::-moz-selection, div::-moz-selection, span::-moz-selection, 
  li::-moz-selection, h2::-moz-selection, input::-moz-selection {
    background-color: rgba(0, 51, 160, 0.3) !important;
    color: inherit !important;
    border-radius: 2px !important;
    -moz-border-radius: 2px !important;
  }

  // Ensure the tailor-ai-btn remains visible
  .tailor-ai-btn-container {
    z-index: 9999;
    pointer-events: auto;
  }

  // Override and prevent hover effects, set proper cursors
  .education-item,
  .experience-item,
  .project-item {
    cursor: default;
    transform: none !important;
    box-shadow: none !important;
    transition: none !important;

    &:hover {
      transform: none !important;
      box-shadow: none !important;
    }

    // Make text areas and editable content feel like Google Docs
    input,
    [contenteditable="true"],
    .resume-description,
    .description-input,
    .item-title,
    .item-subtitle,
    .item-company,
    .item-location,
    .resume-skills,
    .resume-interests {
      cursor: text;
      
      &:hover {
        cursor: text;
      }
    }
  }

  // Ensure proper cursor for all editable content
  [contenteditable="true"] {
    cursor: text;
    
    &:hover {
      cursor: text;
    }
  }
}

.tailor-ai-btn-container {
  position: absolute;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s ease;
  margin-top: 5px;    // Add this to match bullet point suggestion spacing
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  // Add these to ensure proper width behavior
  box-sizing: border-box;
  min-width: 200px;
}

.tailor-ai-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #91918e !important;
  font-family: "Inter", sans-serif !important;
  padding: 10px 20px !important;
  min-width: 160px;
  cursor: pointer !important;
  border: 2px solid transparent;
  border-radius: 8px;
  background-image: linear-gradient(white, white), linear-gradient(90deg, #0033a0 0%, #16a000 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .btn-text {
    flex-grow: 1;
    text-align: center;
  }
}

.bullet-point-suggestion {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 1.5rem;
  margin-top: 5px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  position: absolute;
  z-index: 1000;
  animation: expandSuggestion 0.3s ease;
  
  // Remove the width: 100% and add these constraints
  min-width: 200px; // Minimum reasonable width
  box-sizing: border-box;
  
  .suggestion-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: #91918E;
    
    img {
      margin-right: 8px;
    }
    
    .suggestion-title {
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
}

@keyframes expandSuggestion {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-2px);
  }
}

.bullet-point-suggestion-title {
  color: #91918E;
  margin-bottom: 10px;
}

.bullet-point-button {
  color: #0033A0;
  border: 1px solid #0033A0;
  padding: 0.5em 1em;
  border-radius: 4px;
  margin: 1em 1em 0 0;
}

.bullet-points li.selected {
  background-color: #fff;
  border: none;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.4);
  }
  100% {
    box-shadow: 0 0 20px rgba(76, 175, 80, 1);
  }
}

.skills-interests-section {
  margin-bottom: 20px;

  h2 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    border-bottom: 1px solid #000000 !important; // Force black border
    padding-bottom: 5px;
  }

  .skills-interests-content {
    .skills-line,
    .interests-line {
      font-size: 15px;
      line-height: 1.4;
      margin-bottom: 5px;
      display: flex;
      align-items: flex-start;

      .label {
        font-weight: bold !important;  // Added !important to ensure boldness
        margin-right: 5px;
        min-width: 65px;
      }

      .resume-skills,
      .resume-interests {
        flex: 1;
        
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.resume-rectangle {
  .skills-interests-content {
    .skills-line,
    .interests-line {
      .label.bold-label {
        font-weight: 700 !important;
        font-family: inherit !important;
        margin-right: 2px !important;  // Reduced from 5px to 2px
        min-width: auto !important;    // Remove fixed width
        display: inline !important;     // Changed from inline-block to inline
      }

      .resume-skills,
      .resume-interests {
        display: inline !important;     // Make sure content flows naturally
      }
    }
  }
}

// Additional backup rule
span.label.bold-label {
  font-weight: 700 !important;
  font-family: inherit !important;
}

// Add these skeleton loading animations
@keyframes pulse {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.bullet-point-suggestion {
  // ... existing styles ...

  .suggestion-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    
    img {
      margin-right: 8px;
    }
    
    .suggestion-title {
      color: #91918e;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .skeleton-loader {
    height: 16px;
    margin: 8px 0;
    border-radius: 4px;
    background: linear-gradient(90deg, 
      #f0f0f0 25%, 
      #e0e0e0 50%, 
      #f0f0f0 75%
    );
    background-size: 200% 100%;
    animation: pulse 1.5s ease-in-out infinite;
  }

  .skeleton-lines {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

// Add to existing styles
.bullet-points li {
  &.highlighted {
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      left: -0.25rem; // Reduced from -1em to -0.25rem for less left spacing
      right: auto;
      width: calc(100% - 1.25rem); // Adjusted to account for the smaller left offset
      height: 100%;
      background-color: rgba(0, 51, 160, 0.1);
      border-radius: 4px;
      transition: background-color 0.2s ease;
      z-index: -1;
    }
  }
}
