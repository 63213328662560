@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.font-sans {
  font-family: "Inter", sans-serif !important;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: width 0.3s ease;
  z-index: 100;
}

.sidebar.expanded {
  width: 250px;
}

.sidebar.expanded + .mock-interview {
  margin-left: 250px;
}

.mock-interview-container {
  display: flex;
  min-height: 100vh;
}

.mock-interview {
  margin-left: 80px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Inter", sans-serif !important;
  position: relative;
  font-feature-settings: "ss01" 1, "ss02" 1, "cv01" 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.011em;

  h1,
  h2,
  h3,
  h4 {
    letter-spacing: -0.02em;
    line-height: 1.2;
  }
}
