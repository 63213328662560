@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  width: 98%;
  height: 98%;
  // padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black; /* Black line under close button */
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  color: gray; /* Gray close button */
}

.tabs {
  display: flex;
}

.tab {
  border: 1px solid #0033A0;
  color: #0033A0;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 8px;
  background: white;
  // padding: 10px 30px;
  transition: background 0.3s, color 0.3s;

  &.selected {
    background: #0033A0;
    color: white;
  }

  &:hover {
    background-color: #0033A0;
    color: #ffffff;
  }
}

.modal-body {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.resume-container-with-border {
  flex: 2;
  border: 1px solid black; /* Matching suggestions border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  margin-right: 20px;
  overflow-y: auto; /* Re-enable scrolling for resume content */
  max-height: 100%;
  scroll-behavior: smooth; /* Smooth scrolling */
}

.resume-container {
  font-family: 'EB Garamond', 'Garamond', serif;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.6;
}

.resume-name {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  color: black;
}

.resume-info {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  color: black;
  line-height: 1.5;
  
  a {
    text-decoration: none;
    color: black;
  }
}

.education-section, .experience-section, .projects-section, .skills-section {
  margin-top: 20px;
}

.education-section h2,
.experience-section h2,
.projects-section h2,
.skills-section h2 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: black;
  margin-bottom: 5px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 3px;
}

.education-title, .degree-name, .job-title {
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 1.2;
}

h3.education-title {
  margin-bottom: 2px;
  padding-bottom: 0;
}

p.degree-name {
  margin-top: 0;
  padding-top: 0;
  line-height: 1.2;
}

.company-name {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  color: black;
}

.education-header, .experience-header, .project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.degree-details, .experience-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.education-item, .experience-item, .project-item {
  margin-bottom: 10px;
}

.project-title {
  font-size: 16px;
  font-weight: 700;
}

.project-description {
  font-weight: 400;
  margin-left: 5px; /* Adjust spacing for the description */
}

/* Left-align the bullet points */
.bullet-points {
  list-style-type: disc;
  margin-left: 0;
  padding-left: 20px;
  font-size: 16px;
  text-align: left; /* Left-aligned bullet points */
}

.bullet-points li {
  text-indent: 0;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 5px;
}

/* Left-aligned Skills and Interests, no bullet points */
.skills-section p {
  margin-top: 20px; /* Consistent margin like other sections */
  font-size: 16px;
  text-align: left;
}

/* Reduced gap between project name and first bullet point */
.project-item .bullet-points {
  margin-top: -5px; /* Reduced gap */
}

/* Responsive font sizing */
@media (min-width: 768px) {
  .resume-name {
    font-size: 24px;
  }

  .resume-info {
    font-size: 16px;
  }

  .education-section h2,
  .experience-section h2,
  .projects-section h2,
  .skills-section h2 {
    font-size: 18px;
  }

  .degree-name, .job-title, .company-name, .project-title {
    font-size: 16px;
  }

  .bullet-points li {
    font-size: 16px;
  }
}

/* Right container for suggestions */
.right-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  overflow-y: auto;
  max-height: 100%;
  scrollbar-width: thin;
  scrollbar-color: #2b2b2b #e1e1e1;
  scroll-behavior: smooth; /* Smooth scrolling */
}

.right-container::-webkit-scrollbar {
  width: 8px;
}

.right-container::-webkit-scrollbar-track {
  background: #e1e1e1;
}

.right-container::-webkit-scrollbar-thumb {
  background-color: #2b2b2b;
  border-radius: 10px;
  border: 2px solid #e1e1e1;
}

/* Suggestions container */
.suggestions-container {
  flex-grow: 1;
  background: #ffffff;
  border: 1px solid black; /* Black border */
  border-radius: 8px;
  padding: 0 20px 20px 20px; /* No padding on top, 20px on the other sides */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Black shadow */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 20px;
}

.suggestions-title {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Left-align title and icon */
  font-size: 25px; /* Adjusted size for Suggestions title */
  font-weight: bold;
  margin-bottom: 10px;
}

.ai-icon {
  width: 25px; /* Set to 25x25px */
  height: 25px;
  margin-right: 10px; /* Space between icon and text */
}

.progress-circle {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: end;
}

/* Left-align the suggestion items */
.suggestion-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 15px;
  background: #f9f9f9;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #f0f0f0;
  }
}

.suggestion-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;

  .dropdown-icon {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
  }

  .dropdown-icon.expanded {
    transform: rotate(180deg);
  }
}

.suggestion-details {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
  padding-left: 10px;
}

/* Suggestions buttons */
.suggestions-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.approve-suggestions-btn {
  background-color: white;
  color: #0033A0;
  padding: 15px 20px;
  border: 2px solid #0033A0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
}

.approve-suggestions-btn:hover {
  background-color: #0033A0;
  color: white;
}

/* Removed hover effect from Tailor Cover Letter button */
.tailor-cover-letter-btn {
  background-color: white;
  color: #0033A0;
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
}

.arrow {
  margin-left: 10px;
  transition: none;
}

.tailor-cover-letter-btn:hover {
  background-color: white;
  color: #0033A0;
}