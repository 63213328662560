#job-details-modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99999;
}

#job-details-modal-root .modal-backdrop {
    pointer-events: all;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

body.modal-open {
    overflow: hidden;
    
    .sidebar {
        // Handled in sidebar.scss
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;

    @media (max-width: 768px) {
        z-index: 1002;
    }
}

.modal-backdrop.show {
    opacity: 1;
}

.modal-content {
    background: white;
    width: 99%;
    height: 95vh;
    padding: 0 40px 20px 40px;
    border-radius: 10px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    max-height: 95vh;
    transform: scale(0.8);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), 
                opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;

    @media (max-width: 768px) {
        z-index: 1003;
    }
}

.modal-content.show {
    transform: scale(1);
    opacity: 1;
}

.sticky-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10001;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media (max-width: 768px) {
        z-index: 1004;
    }
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 0;
}

.tabs {
    display: flex;
    flex-wrap: wrap;
}

.tab-icon {
    margin-right: 0.35em;
}

.tab-icon img {
    filter: brightness(0) invert(1);
}

.tab {
    border: 1px solid #0033A0;
    color: #0033A0;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 6px !important;
    background: white;
    font-family: 'Inter', sans-serif;
    position: relative;
    text-align: left;
    transition: background 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    width: 200px;
    height: 40px;
    padding: 0 10px;
    font-weight: 500;
    font-size: 14px;

    .tab-icon {
        margin-right: 0.35em;
    }

    // Only target document icons
    .tab-icon img[alt="Document Icon"] {
        stroke: #0033A0 !important;
    }

    &.selected {
        background: #0033A0;
        color: #ffffff;

        .tab-icon img[alt="Document Icon"] {
            stroke: #ffffff;
        }
    }

    &:hover {
        background-color: #0033A0;
        color: #ffffff;

        .tab-icon img[alt="Document Icon"] {
            stroke: #ffffff;
        }
    }
}

.close-button {
    background: none;
    border: none;
    font-size: 2em !important;
    cursor: pointer;
    color: #666;
    font-weight: 300;
    line-height: 1;
    transition: color 0.2s, transform 0.2s ease-in-out;

    &:hover {
        color: #000;
        transform: scale(1.1);
    }
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    transition: visibility 0s linear 0.3s, height 0s linear 0.3s;

    &.visible {
        visibility: visible;
        height: auto;
        overflow: visible;
        transition: visibility 0s linear 0s, height 0s linear 0s;
    }

    &.animate {
        animation: fadeIn 0.3s ease-in-out forwards;
        animation-delay: 0.1s;
    }

    .job-title-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .top-row {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        .bottom-row {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.company-logo-wrapper {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    .company-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;

        &.horizontal-logo {
            transform: scale(1.02);
        }

        &.fallback-logo {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.job-type-tag {
    font-family: 'Inter', sans-serif;
    display: inline-block;
    background-color: #D0E2FF;
    color: #003366;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;

    &.full-time {
        background-color: #D0E2FF;
        color: #003366;
    }

    &.internship {
        background-color: #D4EDDA;
        color: #155724;
    }

    &.contract {
        background-color: #FFE5CC;
        color: #CC5500;
    }

    &.part-time {
        background-color: #EDE0F8;
        color: #4B0082;
    }

    &.demo {
        background: #FFE5E5;
        color: #990000;
        font-family: 'Karla', sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }
}

.job-title h2 {
    font-family: 'Inter', sans-serif !important;
    font-weight: bold !important;
    font-size: 22px !important;
    margin: 0 0 4px 0;
    color: #333 !important;
}

.company-location {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #717171 !important;

    .external-link {
        color: #0033A0;
        text-decoration: none;
        transition: color 0.2s ease;
        margin-left: 4px;
        font-weight: 500;
        
        .arrow {
            display: inline-block;
            transition: transform 0.2s ease;
            margin-left: 2px;
            font-size: 1.2em;
            position: relative;
            top: -1px;
        }

        &:hover {
            color: #0056b3;
            
            .arrow {
                transform: translate(2px, -2px);
            }
        }
    }
}

.tailor-button {
    background: #0033A0 !important;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px !important;
    cursor: pointer;
    transition: all 0.15s ease;
    font-weight: 600;
    white-space: nowrap;

    &:hover {
        background: #0044CC !important;
        transform: scale(1.02);
        box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
    }
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;

    .delete-job-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #ef4444;
        text-decoration: none;
        transition: all 0.2s ease;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        gap: 6px;
        padding: 8px 0;
        font-size: 14px;
        min-width: 180px;
        border: none;
        background: none;
        cursor: pointer;
        
        .trash-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease;
            transform-origin: center;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &:hover {
            color: #dc2626;

            .trash-icon {
                animation: wiggle 0.3s ease-in-out;
            }
        }
    }

    .external-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #0033A0;
        text-decoration: none;
        transition: all 0.2s ease;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        gap: 6px;
        padding: 8px 0;
        font-size: 14px;
        min-width: 180px;
        
        .external-icon {
            transition: transform 0.2s ease;
        }

        &:hover {
            color: #0033A0;
            
            .external-icon {
                transform: translate(2px, -2px);
            }
        }
    }

    .tailor-button {
        background: #0033A0 !important;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.15s ease;
        font-weight: 600;
        white-space: nowrap;
        font-family: 'Inter', sans-serif;

        &:hover {
            background: #0044CC !important;
            transform: scale(1.02);
            box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
        }
    }
}

.separator-line {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 10px 0;
}

.modal-body {
    flex-grow: 1;
    overflow-y: auto !important;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-height: calc(95vh - 150px) !important;
    opacity: 0;
    animation: fadeIn 0.3s ease-in-out forwards;
    animation-delay: 0.2s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.left-column {
    flex: 2;
    padding-right: 20px;

    h2, h3 {
        margin-top: 0;
        font-weight: bold;
        font-family: 'Inter', sans-serif;
    }

    h3 {
        margin-bottom: 5px;
        font-size: 1.1em;
    }

    p, ul {
        margin-top: 0;
        margin-bottom: 20px;
        font-family: 'Inter', sans-serif;
    }

    ul {
        list-style-position: outside;
        margin-left: 1.5rem;

        li {
            line-height: 1.5;
            text-indent: -0.84rem;
            margin-bottom: 0.5rem;

            &::marker {
                font-size: 1rem;
            }
        }
    }

    .skills {
        display: flex;
        flex-wrap: wrap;
    }

    .skill-badge {
        background: #e0e0e0;
        border-radius: 20px;
        padding: 5px 10px;
        margin: 5px;
    }

    .tailor-button {
        background: #0033A0 !important;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.15s ease;
        font-weight: 600;
        white-space: nowrap;
        margin-top: 20px;

        &:hover {
            background: #0044CC !important;
            transform: scale(1.02);
            box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
        }
    }
}

.right-column {
    flex: 1;
    padding-left: 20px;
    margin-top: 0;
    margin-left: auto;

    .job-info-box {
        width: 100%;
        padding: 15px;
        background: white;
        border: 1px solid black;
        border-radius: 8px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;

        h4 {
            margin: 0;
            font-size: 0.9em;
            font-weight: bold;
            font-family: 'Inter', sans-serif;
            color: grey;

            &:not(:first-child) {
                margin-top: 20px; // Space between sections
            }
        }

        p {
            margin: 0;
            font-size: 1.2em;
            font-weight: bold;
            font-family: 'Inter', sans-serif;
        }

        .about-company-text {
            font-size: 1em;
            font-weight: normal;
            font-family: 'Inter', sans-serif;
        }

        & > h4:not(:first-child) {
            margin-top: 20px;
        }

        & > p:not(:first-of-type) {
            margin-top: 0px;
        }

        .about-company {
            margin-top: 40px;
        }
    }
}

@media (max-width: 1200px) {
    .header-section {
        align-items: flex-end;

        .company-logo-wrapper {
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
        }

        .job-title h2 {
            font-size: 1.5em;
        }

        .tailor-button {
            width: auto;
        }
    }

    .modal-body {
        flex-direction: column;

        .left-column {
            padding-right: 0;
            margin-bottom: 20px;
        }

        .right-column {
            padding-left: 0;
        }
    }

    .tabs {
        justify-content: center;
        flex-wrap: wrap;

        .tab {
            max-width: none;
        }
    }
}

.content-container {
    display: flex;
    gap: 40px;
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.demo-notification {
    background: rgba(0, 51, 160, 0.1);
    border: 1px solid rgba(0, 51, 160, 0.2);
    padding: 16px 24px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;

    .demo-notification-content {
        max-width: 100%;
        display: block;

        .demo-text {
            strong {
                display: block;
                color: #0033A0;
                margin-bottom: 4px;
                font-size: 16px;
                font-weight: 600;
                font-family: 'Inter', sans-serif;
            }

            p {
                color: #555;
                margin: 0;
                font-size: 14px;
                line-height: 1.5;
                font-family: 'Inter', sans-serif;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 14px 20px;
        margin-bottom: 12px;

        .demo-notification-content {
            .demo-text {
                strong {
                    font-size: 15px;
                }

                p {
                    font-size: 13px;
                }
            }
        }
    }
}

.demo-description {
    color: #666;
    font-style: italic;
    background: #f8f9fa;
    padding: 12px 16px;
    border-radius: 8px;
    border-left: 4px solid #FFE5E5;
    margin: 8px 0;
}

.company-location {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #717171 !important;
    display: flex;
    align-items: center;
    gap: 4px;

    .external-link {
        color: #0033A0;
        text-decoration: none;
        transition: color 0.2s ease;
        font-weight: 500;
        display: inline-flex;
        align-items: center;

        .arrow {
            display: inline-flex;
            transition: transform 0.2s ease;
            margin-left: 2px;
            font-size: 1.2em;
        }

        &:hover {
            color: #0056b3;

            .arrow {
                transform: translate(2px, -2px);
            }
        }
    }
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1008;

    .loading-spinner {
        width: 40px;
        height: 40px;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        margin-bottom: 1rem;
    }

    p {
        color: #666;
        font-size: 1rem;
    }
}

.error-message {
    background-color: #fff3f3;
    border: 1px solid #ffcdd2;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        color: #d32f2f;
        margin: 0;
    }

    button {
        background: none;
        border: none;
        color: #666;
        cursor: pointer;
        padding: 0.5rem;
        font-size: 0.875rem;
        
        &:hover {
            color: #d32f2f;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.delete-confirm-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    &.closing {
        animation: fadeOut 0.2s ease-in-out forwards;
    }
}

.delete-confirm-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    z-index: 1000000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h2 {
        margin: 0 0 12px 0;
        font-size: 20px;
        color: #111827;
    }

    p {
        margin: 0 0 24px 0;
        color: #6b7280;
        font-size: 14px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        gap: 12px;

        button {
            padding: 8px 24px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;

            &.keep-button {
                background: white;
                color: #374151;
                border: 1px solid #d1d5db;

                &:hover {
                    background: #f3f4f6;
                }
            }

            &.discard-button {
                background: #ef4444;
                color: white;
                border: none;

                &:hover {
                    background: #dc2626;
                }
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-10deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
}
