@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.font-sans {
  font-family: 'Inter', sans-serif !important;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1001;
  background: #0033A0;

  @media (max-width: 768px) {
    transform: translateX(-100%);
    width: 250px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    
    &.expanded {
      transform: translateX(0);
    }
  }
}

.account-info-container {
  display: flex;
  min-height: 100vh;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

.account-info {
  margin-left: 80px;
  flex-grow: 1;
  transition: none;
  padding: 40px;
  width: calc(100% - 80px);
  max-width: 100%;
  overflow-x: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Inter', sans-serif !important;
  position: relative;
  font-feature-settings: "ss01" 1, "ss02" 1, "cv01" 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.011em;

  h1, h2, h3, h4 {
    letter-spacing: -0.02em;
    line-height: 1.2;
  }

  .section {
    &:not(.resume-section) {
      .education-item:not(.editing),
      .experience-item:not(.editing),
      .project-item:not(.editing) {
        cursor: pointer;
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  /* Typography Scale */
  --font-scale-xs: clamp(0.75rem, 1.5vw, 0.875rem);
  --font-scale-sm: clamp(0.875rem, 2vw, 0.95rem);
  --font-scale-base: clamp(1rem, 2.5vw, 1.1rem);
  --font-scale-lg: clamp(1.1rem, 2.5vw, 1.25rem);
  --font-scale-xl: clamp(1.25rem, 3vw, 1.75rem);
  --font-scale-2xl: clamp(1.75rem, 4vw, 2.25rem);

  @media (max-width: 768px) {
    margin-left: 0 !important;
    padding: 0px;
    width: 100%;
    margin: 0;
    max-width: 100vw;
    transition: none;
  }
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background: linear-gradient(to right, #ffffff 0%, #d6d8ff 95%);
  margin-bottom: 20px;
  border-radius: 10px;
  width: 100%;
  position: relative;
  border: 1px solid #000;

  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;

    .profile-picture {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      border: 4px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f0f0f0;
      margin-bottom: 0;
      cursor: default;
      position: relative !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .initials {
        font-size: 2.5rem;
        font-family: 'Inter', sans-serif !important;
        font-weight: 450 !important;
        color: #333;
        user-select: none;
        line-height: 1;
        letter-spacing: -0.025em;
        font-feature-settings: "ss01" 1, "ss02" 1, "cv01" 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
      }

      @media (max-width: 768px) {
        width: 100px;
        height: 100px;
        
        .initials {
          font-size: 2.25rem;
        }
      }
    }

    h1 {
      font-size: var(--font-scale-2xl);
      margin: 0;
      font-weight: 450 !important;
      letter-spacing: -0.02em;
      line-height: 1.2;
      color: #000;
      font-family: 'Inter', sans-serif !important;
      font-feature-settings: "ss01" 1, "ss02" 1, "cv01" 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
    }

    input {
      font-size: 1rem;
      margin-bottom: 10px;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }

    button {
      background-color: #007BFF;
      color: white;
      padding: 8px 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 10px;
    }
  }

  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 1000px 100%;
    animation: shimmer 2s infinite linear;
    border-radius: 4px;
  }

  .skeleton-circle {
    @extend .skeleton;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  .name-skeleton {
    height: 32px;
    width: 200px;
    margin-top: 16px;
  }

  .skill-skeleton {
    height: 28px;
    width: 100px;
    margin-right: 8px;
    display: inline-block;
    border-radius: 14px;
  }

  .info-value-skeleton {
    height: 24px;
    width: 120px;
    margin-bottom: 4px;
  }

  .info-label-skeleton {
    height: 16px;
    width: 80px;
  }

  .icon-skeleton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: 0 8px;
  }

  .button-skeleton {
    height: 36px;
    width: 140px;
    margin: 8px 0;
    border-radius: 18px;
  }

  .profile-info {
    .skeleton-circle {
      margin-bottom: 16px;
    }
  }

  .skills-section {
    margin: 16px 0;
  }

  .experience-location {
    .info-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 0px;
    width: 100%;
  }
}

.profile-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 2px 0 !important;
  margin: 0 20px 0 20px;

  .skills-section {
    display: flex;
    gap: 10px;
    margin-bottom: 0;
    padding: 0;
  }

  .experience-location {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;

    .divider {
      width: 1px;
      height: 20px;
      background-color: #ccc;
      margin: 0 20px !important; //spacing for divider in profile details
    }
  }

  .info-item {
    padding: 0;
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0 0 20px 0 !important;
  }

  @media (max-width: 480px) {
    margin: 0 0 20px 0 !important;
  }
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;

    a {
      font-size: 1.8rem;
      color: #0033A0;
      transition: color 0.3s ease;

      &:hover {
        color: #0056b3;
      }
    }
  }

  .share-button,
  .resume-button {
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    background-color: #ffffff;
    color: #0033a0;
    border: 2px solid #0033a0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #0033a0;
      color: #ffffff;
    }

    &.loading {
      opacity: 0.7;
      cursor: not-allowed;
      position: relative;
      
      &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        border: 2px solid transparent;
        border-top-color: currentColor;
        border-radius: 50%;
        animation: spin 0.8s linear infinite;
      }
    }
  }
}

form {
  padding: 20px 0 !important;
  width: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0 !important;
    width: 100%;
  }
}

.input-field {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    font-size: clamp(0.875rem, 2vw, 0.925rem);
    font-weight: 500;
    letter-spacing: -0.01em;
    color: #444;
    margin-bottom: 8px;
    width: 100%;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 12px;
    font-size: clamp(0.875rem, 2vw, 1rem);
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: -0.011em;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    background-color: #f9f9f9;
    resize: none;
    height: 44px;
    overflow: hidden;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: -0.011em;

    &:focus {
      outline: none;
      border-color: #0033A0;
      box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
    }

    &.grad-input,
    &.gpa-input,
    &[type="date"],
    &.graduation-date,
    &[name="graduationDate"],
    &[name="gpa"] {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
      flex: none;
    }
  }

  textarea {
    overflow-y: hidden;
  }

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 15px;
    
    label {
      font-size: clamp(0.8rem, 2.5vw, 0.9rem);
      margin-bottom: 4px;
    }

    input,
    select,
    textarea {
      padding: 8px;
      font-size: clamp(0.85rem, 2.5vw, 0.95rem);
      height: 40px;
    }
  }
}

.description-input {
  resize: none;
  height: 44px; /* Matches the height of input fields */
  overflow-y: hidden; /* Hides the scrollbar */
  min-height: 44px;
}

/* Auto-grow textarea height based on content */
textarea.description-input {
  max-height: 150px;
}

.edit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;

    &.save-button {
      background-color: #0033A0;
      color: white;
      border: none;

      &:hover {
        background-color: #007BFF;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &.cancel-button {
      background-color: #fff;
      color: #000;
      border: 1px solid #ddd;

      &:hover {
        background-color: #e2e6ea;
      }
    }
  }

  [role="button"] {
    background-color: transparent;
    color: #dc2626;
    border: 1px solid #dc2626;
    font-size: 0.875rem;
    padding: 10px 20px;
    border-radius: 6px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: #fee2e2;
      border-color: #ef4444;
    }

    &:active {
      background-color: #fecaca;
      border-color: #dc2626;
    }
  }
}

.section-add-button {
  background-color: #0033A0;
  color: white;
  padding: 8px 12px;
  margin: 0 20px 20px 20px;
  font-size: 0.875rem;
  cursor: pointer;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: background-color 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 37px; /* Match the height of add-button */

  &:hover {
    background-color: #007BFF;
  }

  &:active {
    transform: translateY(1px);
  }

  @media (max-width: 768px) {
    margin: 20px 0 20px 0 !important; /* Added top margin to match skills container */
    padding: 8px 12px;
    font-size: 0.875rem;
    height: 37px; /* Match the height of add-button */
    display: inline-flex;
  }

  svg {
    font-size: 1.2rem;
  }
}

.add-button {
  padding: 8px 12px;
  font-size: 0.875rem; /* Made consistent with section-add-button */
  background-color: #0033A0;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 37px;

  &:hover {
    background-color: #007BFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: #0056b3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}

.description-item {
  display: flex;
  align-items: center;  /* Aligns icons and text vertically in the center */
  position: relative;
  width: 100%;  /* Ensure the item takes up the full width */
  margin-bottom: 25px;  /* Matches the margin-bottom of the input-field class */
  padding: 0;  /* Reset padding to avoid additional spacing issues */

  .description-controls {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .add-icon {
    color: #0033A0;
    font-size: 18px;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  .drag-icon {
    color: #666;
    cursor: grab; /* Show move cursor when hovering over the icon */
    margin-right: 10px;
    flex-shrink: 0;  /* Prevent the icon from shrinking */
    font-size: 1.2rem;
    height: 44px;  /* Ensure the icon height matches the input field */
    display: flex;
    align-items: center;  /* Vertically center the icon */
  }

  .description-input {
    flex-grow: 1;  /* Allow the input to take up available space */
    min-width: 0;  /* Prevent overflow */
    width: 100%;  /* Ensure input spans full width */
    padding: 12px;  /* Match padding with other input fields */
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    background-color: #f9f9f9;
    height: 44px;  /* Matches the height of other input fields */
    line-height: 20px;  /* Adjusts line height to better align text */
    overflow: hidden; /* Hides the scrollbar */
    color: #000; /* Changed text color to black */
  }

  .delete-icon {
    color: red;
    cursor: pointer;
    margin-left: 10px;
    flex-shrink: 0;  /* Prevent the icon from shrinking */
    height: 44px;  /* Ensure the icon height matches the input field */
    display: flex;
    align-items: center;  /* Vertically center the icon */
  }

  &[draggable] {
    cursor: move;
  }

  &.dragging {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 15px;
  }
}

.section {
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    max-width: 1200px;
    margin: 0;
    padding: 0 !important;
  }

  .section-title {
    font-size: var(--font-scale-xl);
    font-weight: 600;
    letter-spacing: -0.015em;
    color: #111;
    margin-bottom: 20px;
    padding: 0 20px 5px 20px;
    position: relative;

    @media (max-width: 768px) {
      font-size: clamp(1.5rem, 2.5vw, 1.75rem);
      margin-bottom: 10px;
      padding: 0;
    }
  }

  .education-item,
  .experience-item,
  .project-item {
    padding: 8px 0;
    margin-bottom: 8px;
    background-color: transparent;
    border-radius: 10px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:not(.editing):hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .info-content {
      .degree-date,
      .top-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 5px;

        .item-title {
          font-size: 1.1rem !important;
          font-weight: 600;
          flex: 1;
          line-height: 1.3;

          @media (max-width: 768px) {
            font-size: 1.1rem !important;
            line-height: 1.2;
            letter-spacing: -0.01em;
          }
        }

        .item-subtitle,
        .item-duration {
          font-size: 1.1rem !important;
          white-space: nowrap;
          text-align: right;

          @media (max-width: 768px) {
            font-size: 1.1rem !important;
          }
        }
      }

      .bottom-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        font-size: var(--font-scale-sm);

        .item-company,
        .item-location {
          @media (max-width: 768px) {
            font-size: clamp(0.8rem, 1.5vw, 0.9rem);
          }
        }

        .item-location {
          text-align: right;
          white-space: nowrap;
        }
      }

      ul.item-description {
        margin-top: 10px;
        padding-left: 20px;
        
        li {
          font-size: var(--font-scale-base);
          line-height: 1.4;
          margin-bottom: 5px;
          color: #333;

          @media (max-width: 768px) {
            font-size: clamp(0.8rem, 1.5vw, 0.9rem);
            line-height: 1.3;
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 8px 0;
      margin-bottom: 8px;

      .info-content {
        .degree-date,
        .top-row {
          flex-direction: row !important;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          width: 100%;
          
          .item-title {
            flex: 1;
            margin-right: 10px;
          }

          .item-subtitle,
          .item-duration {
            text-align: right;
            white-space: nowrap;
          }
        }

        .bottom-row {
          flex-direction: row !important;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;

          .item-company {
            flex: 1;
          }

          .item-location {
            text-align: right;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.section .education-item, 
.section .experience-item, 
.section .project-item {
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 20px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    @media (max-width: 768px) {
        padding: 20px 0px !important;
    }

    &.editing {
        border: 2px solid #f1f1f1;
        background-color: #ffffff;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    }

    .info-content {
        border: none;
    }

    .input-field {
        border: none;
    }
}

.update-button {
  width: 100%;
  padding: 15px;
  font-size: clamp(0.875rem, 2vw, 0.95rem);
  margin-top: 30px;
  background-color: #0033A0;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-weight: 500;
  letter-spacing: -0.01em;

  &:hover {
    background-color: #007BFF;
  }
}

.error-message, .success-message {
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.success-message {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.edit-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;

  .modal-content {
    padding: 20px;

    h2 {
      margin-bottom: 20px;
      font-size: 1.8rem;
      color: #444;
    }

    .input-field {
      margin-bottom: 15px;

      label {
        font-size: 1rem;
        color: #555;
        margin-bottom: 5px;
        display: block;
      }

      input,
      select {
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        border: 2px solid #ddd;
        border-radius: 5px;
        box-sizing: border-box;
        transition: border-color 0.3s ease;

        &:focus {
          border-color: #007bff;
        }
      }
    }

    .modal-actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;

      .cancel-button,
      .save-button {
        padding: 10px 20px;
        font-size: 1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;
      }

      .cancel-button {
        background-color: #fff;
        color: #6c757d;
        border: 1px solid #ccc;

        &:hover {
          background-color: #f8f9fa;
          border-color: #b3b3b3;
        }
      }

      .save-button {
        background-color: #0033A0;
        color: white;
        border: 1px solid #0033A0;

        &:hover {
          background-color: #007BFF;
          border-color: #007BFF;
        }
      }
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #999;
      transition: color 0.3s ease;

      &:hover {
        color: #000;
      }
    }
  }
}

/* Update for dialog popup styling */
.dialog-content {
  border-radius: 15px;
  padding: 2em;
  max-width: 50%;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
  margin: 0 auto;

  @media (max-width: 1024px) {
    max-width: 60%;
  }

  @media (max-width: 768px) {
    max-width: 80%;
    padding: 1.5em;
  }

  @media (max-width: 480px) {
    max-width: 90%;
    padding: 1em;
  }
}

.dialog-header {
  margin-bottom: 1.5em;
  text-align: left;
}

.dialog-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
}

.dialog-description {
  font-size: 1rem;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
}

/* Flexbox for input field and label alignment in Shadcn dialog */
.dialog-input-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;

  label {
    font-size: 1rem;
    color: #333;
    margin-right: 1em;
    width: 30%;
    text-align: left;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }

    @media (max-width: 480px) {
      font-size: 0.75rem;
      margin-right: 0.5em;
    }
  }

  input,
  select {
    width: 65%;
    padding: 0.75em;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #007BFF;
      box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
      outline: none;
    }

    @media (max-width: 768px) {
      font-size: 0.875rem;
      padding: 0.625em;
    }

    @media (max-width: 480px) {
      font-size: 0.75rem;
      padding: 0.5em;
    }
  }
}

/* Styling for Shadcn dialog buttons */
.dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 1.5em;

  .cancel-button,
  .save-button {
    padding: 0.75em 1.5em;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    border: none;

    @media (max-width: 768px) {
      font-size: 0.875rem;
      padding: 0.625em 1.25em;
    }

    @media (max-width: 480px) {
      font-size: 0.75rem;
      padding: 0.5em 1em;
    }
  }

  .cancel-button {
    background-color: #f8f9fa;
    color: #6c757d;

    &:hover {
      background-color: #e2e6ea;
    }
  }

  .save-button {
    background-color: #0033A0;
    color: white;

    &:hover {
      background-color: #007BFF;
    }
  }
}

/* Floating edit button */
.floating-edit-button {
  position: absolute;
  top: -25px;
  right: -25px;
  background-color: #0033A0;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }

  svg {
    font-size: 1.2rem;
  }
}

.skills-container,
.interests-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0 20px 20px 20px;
  padding: 0;
  min-height: 50px;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin: 0 0 20px 0 !important;
    padding: 20px 0 !important;
  }

  .skill-badge {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #0033A0;
    color: #0033A0;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease, opacity 0.2s ease;
    font-size: clamp(0.75rem, 2vw, 0.875rem);
    letter-spacing: -0.005em;
    cursor: grab;
    line-height: 1.2;
    white-space: nowrap;
    will-change: transform;
    height: 37px;

    &:active {
      cursor: grabbing;
    }

    &.dragging {
      cursor: grabbing;
      opacity: 0.7;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    .drag-handle {
      margin-right: 4px;
      color: #0033A0;
      opacity: 0.5;
      cursor: grab;
      
      &:active {
        cursor: grabbing;
      }
    }

    .skill-input {
      cursor: text;
    }
  }

  .add-button {
    padding: 8px 12px;
    height: 37px;
    font-size: 0.875rem;
    background-color: #0033A0;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    margin: 0;

    &:hover {
      background-color: #007BFF;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Remove react-datepicker styles
.react-datepicker,
.react-datepicker__input-container,
.react-datepicker-wrapper,
.react-datepicker__month-container,
.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker__day,
.react-datepicker__day-name {
  display: none !important;
}

.custom-toast {
  opacity: 1;
  transform: none;

  &.Toastify__toast--enter {
    opacity: 1;
    transform: none;
  }

  &.Toastify__toast--enter-active {
    opacity: 1;
    transform: none;
  }

  &.Toastify__toast--exit {
    opacity: 0;
    display: none;
  }

  &.Toastify__toast--exit-active {
    opacity: 0;
    display: none;
  }

  .toast-close-button {
    position: relative;
    z-index: 9999;
    
    &:hover {
      opacity: 1;
    }
  }
}

/* Override any default toast transitions */
.Toastify__toast {
  transform: none !important;
  border-radius: 4px !important;
}

.Toastify__toast-container {
  transform: none !important;
}

.date-error-message {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 0.875rem;
}

/* Skeleton Loading & Empty States */
.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.section {
  &.empty-state {
    .empty-placeholder {
      padding: 30px;
      border: 2px dashed rgba(0, 51, 160, 0.15);
      border-radius: 8px;
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      background: rgba(0, 51, 160, 0.02);
      transition: all 0.2s ease;

      .placeholder-icon {
        width: 40px;
        height: 40px;
        color: rgba(0, 51, 160, 0.3);
      }

      .placeholder-text {
        color: #666;
        font-size: 14px;
        text-align: center;
      }

      &:hover {
        border-color: rgba(0, 51, 160, 0.3);
        background: rgba(0, 51, 160, 0.04);
      }
    }
  }

  .empty-state {
    height: 0;
    margin: 0;
  }

  .loading-placeholder {
    margin: 10px 20px;
    
    .title-skeleton {
      height: 24px;
      width: 200px;
      @extend .skeleton;
      margin-bottom: 15px;
    }

    .content-skeleton {
      height: 100px;
      @extend .skeleton;
      margin-bottom: 20px;
    }

    .bullet-skeleton {
      height: 16px;
      width: 80%;
      @extend .skeleton;
      margin-bottom: 8px;

      &:last-child {
        width: 60%;
      }
    }
  }
}

.education-item {
  .info-content {
    .grad-input,
    .gpa-input {
      width: 150px;  // Same width as duration inputs
      text-align: left;
    }

    .graduation-date,
    .gpa {
      width: 150px;  // Same width as duration inputs
      text-align: left;
    }
  }
}

.input-field {
  input[name="gpa"],
  input[placeholder="GPA"],
  .gpa-input {
    width: 180px !important;
    min-width: 180px !important;
    max-width: 180px !important;
    flex: none;
  }
}

@media (max-width: 969px) {
  .account-info-container {
    flex-direction: column;

    .profile-header {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .left-section {
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

      .profile-picture {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }

    .input-field {
      margin-bottom: 20px;
    }

    .section {
      padding: 15px;
    }
  }
}

/* Custom Dropdown Styling */
.custom-dropdown-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  label {
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    color: #444;
  }

  .custom-dropdown {
    position: relative;
    width: 100%;
  }

  .dropdown-trigger {
    width: 100%;
    padding: 12px 16px;
    font-size: 0.95rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    text-align: left;
    color: #1a202c;
    font-weight: 450;
    letter-spacing: -0.01em;
    transition: all 0.15s ease;
    position: relative;
    font-family: inherit;
    height: 48.79px;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      right: 14px;
      top: 50%;
      width: 14px;
      height: 14px;
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.open {
      background-color: #fff;
      border-color: #0033A0;
      box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);

      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &:hover {
      border-color: #0033A0;
      background-color: #fff;
    }

    &:focus {
      outline: none;
      border-color: #0033A0;
      box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
      background-color: #fff;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.05);
    z-index: 1000;
    overflow: hidden;
    transform-origin: top center;
    animation: dropdownFadeIn 0.12s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .dropdown-item {
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.12s ease;
    color: #1a202c;
    font-size: 0.95rem;
    background: #fff;
    border-left: 2px solid transparent;
    
    &:hover {
      background-color: #f8fafc;
      border-left-color: #0033A0;
    }

    &.selected {
      background-color: #f1f5f9;
      color: #0033A0;
      font-weight: 500;
      border-left-color: #0033A0;

      &:hover {
        background-color: #f1f5f9;
      }
    }
  }
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: scaleY(0.95);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

/* Custom Date Picker */
.custom-date-picker {
  position: relative;
  width: 100%;

  .date-input {
    width: 100%;
    padding: 12px 16px;
    font-size: 0.95rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    color: #1a202c;
    font-weight: 450;
    letter-spacing: -0.01em;
    transition: all 0.15s ease;
    height: 48.79px;
    cursor: pointer;

    &:hover {
      border-color: #0033A0;
      background-color: #fff;
    }

    &:focus {
      outline: none;
      border-color: #0033A0;
      box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
      background-color: #fff;
    }
  }

  .calendar-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 300px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #e5e7eb;
    z-index: 1000;
    padding: 16px;
    animation: fadeIn 0.2s ease;

    .calendar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 0 8px;

      .year-display {
        font-size: 16px;
        font-weight: 600;
        color: #1a202c;
        min-width: 70px;
        text-align: center;
      }

      .year-nav {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        color: #1a202c;
        font-size: 18px;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.15s ease;
        padding: 0;
        margin: 0 2px;

        &.prev-fast,
        &.next-fast {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -1px;
        }

        &:hover:not(:disabled) {
          background-color: #f3f4f6;
          color: #0033A0;
          transform: scale(1.1);
        }

        &:disabled {
          color: #d1d5db;
          cursor: not-allowed;
          transform: none;
        }

        &:focus {
          outline: none;
        }

        &.prev, &.prev-fast {
          margin-right: 2px;
        }

        &.next, &.next-fast {
          margin-left: 2px;
        }
      }
    }

    .month-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      padding-top: 8px;

      .month-item {
        padding: 10px;
        text-align: center;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        color: #1a202c;
        transition: all 0.15s ease;
        font-weight: 500;

        &:hover {
          background-color: #f3f4f6;
          color: #0033A0;
        }

        &.selected {
          background-color: #0033A0;
          color: white;
        }
      }
    }
  }
}

/* Custom Edit Profile Modal */
.custom-edit-profile-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: overlayFadeIn 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  /* Prevent background scrolling */
  overflow: hidden;
  
  /* This will be applied to the body when modal is open */
  &.modal-open {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  &.closing {
    animation: overlayFadeOut 0.3s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    pointer-events: none;
    
    .custom-edit-profile-modal {
      animation: modalFadeOut 0.3s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
  }
}

.custom-edit-profile-modal {
  background-color: #fff;
  border-radius: 10px;
  padding: 2.5rem;
  width: 95%;
  max-width: 800px;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: modalFadeIn 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  max-height: 90vh;
  overflow-y: auto;
  transform-origin: center center;

  /* Custom scrollbar for the modal */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  h2 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    color: #111;
    letter-spacing: -0.02em;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0 0 2rem 0;
    font-size: 0.95rem;
    color: #666;
  }

  .fields-row {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    .custom-modal-field {
      flex: 1;
      margin-bottom: 0;
    }
  }

  .modal-close-button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    border-radius: 50%;
    transition: all 0.2s ease;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #f1f5f9;
      color: #000;
    }
  }
}

.custom-modal-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 2rem;
  }

  label {
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    color: #444;
  }

  input,
  select {
    padding: 12px;
    font-size: 0.95rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: all 0.3s ease;
    background-color: #f9f9f9;
    
    &:focus {
      outline: none;
      border-color: #0033A0;
      box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
      background-color: #fff;
    }
  }

  select {
    cursor: pointer;
    appearance: none;
    padding-right: 2.5rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7rem center;
    background-size: 1.2rem;
  }

  .input-with-prefix {
    position: relative;
    display: flex;
    align-items: center;

    .input-prefix {
      position: absolute;
      left: 12px;
      color: #666;
      font-size: 0.95rem;
      pointer-events: none;
      height: 48.79px;
      display: flex;
      align-items: center;
    }

    input {
      padding-left: 28px;
      height: 48.79px;
      width: 100%;
      font-size: 0.95rem;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 6px;
      transition: all 0.15s ease;

      &:focus {
        outline: none;
        border-color: #0033A0;
        box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
        background-color: #fff;
      }
    }
  }
}

.custom-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;

  .cancel-button,
  .save-button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .cancel-button {
    background-color: #fff;
    color: #6c757d;
    border: 1px solid #ccc;

    &:hover {
      background-color: #f8f9fa;
      border-color: #b3b3b3;
    }
  }

  .save-button {
    background-color: #0033A0;
    color: white;
    border: 1px solid #0033A0;

    &:hover {
      background-color: #007BFF;
      border-color: #007BFF;
    }
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes overlayFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Update all border-radius values */
.edit-buttons button,
.edit-buttons [role="button"],
.section-add-button,
.custom-modal-field input,
.custom-modal-field select,
.dropdown-trigger,
.dropdown-menu,
.cancel-button,
.save-button {
  border-radius: 6px !important;
}

/* Remove any !important border-radius rules since we've unified them above */
.edit-buttons button, [role="button"] {
  border-radius: 6px;
}

.section-add-button {
  border-radius: 6px;
}

.custom-modal-field {
  input, select {
    border-radius: 6px;
  }
}

/* Custom Checkbox Styling */
.current-position-checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 15px;
  cursor: pointer;

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #ddd;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    position: relative;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.23, 1);
    background-color: #fff;
    flex-shrink: 0;

    &:hover {
      border-color: #0033A0;
      background-color: rgba(0, 51, 160, 0.04);
      transform: scale(1.02);
    }

    &:checked {
      background-color: #0033A0;
      border-color: #0033A0;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.23, 1);
      }

      &:hover {
        background-color: #007BFF;
        border-color: #007BFF;
        transform: scale(1.02);
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  label {
    font-size: 0.95rem;
    color: #333;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    transition: color 0.2s ease;
    white-space: nowrap;

    &:hover {
      color: #0033A0;
    }
  }
}

.date-range-container {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 8px;

  .custom-date-picker {
    width: 180px;
    flex-shrink: 0;
  }

  .current-position-checkbox {
    width: 180px;
    margin: 0;
    flex-shrink: 0;
    justify-content: flex-start;
  }
}

.custom-date-picker {
  position: relative;

  .date-input {
    width: 100%;
    padding: 12px 16px;
    font-size: 0.95rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    color: #1a202c;
    font-weight: 450;
    letter-spacing: -0.01em;
    transition: all 0.15s ease;
    height: 48.79px;
    cursor: pointer;

    &:hover {
      border-color: #0033A0;
      background-color: #fff;
    }

    &:focus {
      outline: none;
      border-color: #0033A0;
      box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
      background-color: #fff;
    }
  }
}

/* Custom Checkbox Styling */
.current-position-checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  height: 48.79px; /* Match the height of date inputs */

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #ddd;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    position: relative;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.23, 1);
    background-color: #fff;
    flex-shrink: 0;

    &:hover {
      border-color: #0033A0;
      background-color: rgba(0, 51, 160, 0.04);
      transform: scale(1.02);
    }

    &:checked {
      background-color: #0033A0;
      border-color: #0033A0;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.23, 1);
      }

      &:hover {
        background-color: #007BFF;
        border-color: #007BFF;
        transform: scale(1.02);
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  label {
    font-size: 0.95rem;
    color: #333;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    transition: color 0.2s ease;
    white-space: nowrap;

    &:hover {
      color: #0033A0;
    }
  }
}

.resume-button {
  &.loading {
    opacity: 0.7;
    cursor: not-allowed;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      border: 2px solid transparent;
      border-top-color: currentColor;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }
}

@keyframes spin {
  0% { transform: translateY(-50%) rotate(0deg); }
  100% { transform: translateY(-50%) rotate(360deg); }
}

@keyframes pulseIndicator {
  0% {
    opacity: 0.4;
    transform: scaleY(0.8);
  }
  50% {
    opacity: 1;
    transform: scaleY(1.2);
  }
  100% {
    opacity: 0.4;
    transform: scaleY(0.8);
  }
}

/* Global Interactive Element Styles */
button,
[type="button"],
[type="submit"],
[type="reset"],
[role="button"],
.btn,
*[class*="button"],
*[class*="btn"],
.share-portfolio,
.resume-btn,
.edit-btn,
.add-btn,
.remove-btn,
.save-btn,
.cancel-btn,
.delete-btn,
.close-btn,
.modal-btn,
a[href],
.clickable,
[onclick],
[tabindex="0"],
.icon-button,
.action-button,
.control-button,
.add-education-button,
.add-experience-button,
.add-project-button,
.section-add-button,
.floating-edit-button,
.share-button,
.resume-button:not(.loading),
.edit-button,
.delete-icon,
.add-icon,
.action-icon {
  cursor: pointer !important;
}

/* Ensure elements remain clickable in edit mode */
.editing {
  button,
  [role="button"],
  .btn,
  *[class*="button"],
  *[class*="btn"],
  .icon-button,
  .action-icon,
  .delete-icon,
  .add-icon {
    cursor: pointer !important;
  }
}

/* Responsive Layout Improvements */
@media (max-width: 768px) {
  .section {
    .education-item,
    .experience-item,
    .project-item {
      padding: 8px 0;
      margin-bottom: 8px;

      .info-content {
        .degree-date,
        .top-row {
          flex-direction: row !important;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          width: 100%;
          
          .item-title {
            flex: 1;
            margin-right: 10px;
          }

          .item-subtitle,
          .item-duration {
            text-align: right;
            white-space: nowrap;
          }
        }

        .bottom-row {
          flex-direction: row !important;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;

          .item-company {
            flex: 1;
          }

          .item-location {
            text-align: right;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .date-range-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .custom-date-picker,
    .current-position-checkbox {
      width: 100%;
    }
  }
}

/* Mobile Menu Button */
.mobile-menu-button {
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1001;
  background: #0033A0;
  color: white;
  border: none;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  @media (max-width: 768px) {
    display: flex !important;
  }

  &:hover {
    background: #007BFF;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
    color: white;
  }
}

/* Sidebar Overlay */
.sidebar-overlay {
  display: block;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  
  @media (max-width: 768px) {
    &.visible {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
}

/* Remove the expanded margin adjustment for mobile */
.sidebar.expanded + .account-info {
  @media (min-width: 769px) {
    margin-left: 250px;
  }
  
  @media (max-width: 768px) {
    margin-left: 0 !important;
    transform: none !important;
  }
}

/* Add button styles for mobile */
.section-add-button,
.add-education-button,
.add-experience-button,
.add-project-button {
  @media (max-width: 768px) {
    margin: 20px 0 20px 0 !important; /* Added top margin to match skills container */
    padding: 8px 12px;
    font-size: 0.875rem;
    height: 37px; /* Match the height of add-button */
    display: inline-flex;
  }
}

/* Adjust input fields for mobile */
.input-field {
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 15px;
    
    label {
      font-size: clamp(0.8rem, 2.5vw, 0.9rem);
      margin-bottom: 4px;
    }

    input,
    select,
    textarea {
      padding: 8px;
      font-size: clamp(0.85rem, 2.5vw, 0.95rem);
      height: 40px;
    }
  }
}

.profile-empty-notification {
  background: rgba(0, 51, 160, 0.1);
  border: 1px solid rgba(0, 51, 160, 0.2);
  padding: 20px;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  transition: all 0.3s ease;

  &.error-state {
    background: rgba(220, 53, 69, 0.1);
    border: 1px solid rgba(220, 53, 69, 0.2);

    .empty-profile-message strong {
      color: #dc3545;
    }

    .quick-import-button {
      background-color: #dc3545;

      &:hover {
        background-color: #c82333;
      }
    }
  }

  &.success-state {
    background: rgba(40, 167, 69, 0.1);
    border: 1px solid rgba(40, 167, 69, 0.2);

    .empty-profile-message strong {
      color: #28a745;
    }

    .quick-import-button {
      background-color: #28a745;

      &:hover {
        background-color: #218838;
      }
    }
  }

  .empty-profile-wrapper {
    flex: 1;
    min-width: 0;

    .empty-profile-message {
      strong {
        display: block;
        color: #0033A0;
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
      }

      p {
        color: #555;
        margin: 0;
        font-size: 14px;
        line-height: 1.5;
        font-family: 'Inter', sans-serif;
      }
    }
  }

  .quick-import-button {
    background-color: #0033A0;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    min-width: fit-content;

    &:hover {
      background-color: #007BFF;
    }

    &:active {
      transform: scale(0.98);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .spinner {
      animation: rotate 2s linear infinite;
      width: 20px;
      height: 20px;
      
      .path {
        stroke: #ffffff;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  @media (max-width: 768px) {
    padding: 16px;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .empty-profile-wrapper {
      .empty-profile-message {
        strong {
          font-size: 15px;
        }

        p {
          font-size: 13px;
        }
      }
    }

    .quick-import-button {
      width: 100%;
      justify-content: center;
      padding: 12px 20px;
    }
  }
}