.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 24px 0;
  min-height: calc(100% + 48px);
  position: relative;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
  margin: -24px 0;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    min-height: 100%;
    transform: none;
  }

  &.showingModal {
    .jobGrid {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: scale(0.95);
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.jobDetailsModal {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%) scale(1.02);
  z-index: 999;
  width: 95%;
  max-width: 1500px;
  height: auto;
  max-height: 95vh;
  background: transparent;
  border-radius: 8px;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0;
  opacity: 0;
  pointer-events: none;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  
  &.show {
    opacity: 1;
    transform: translate(0, -50%) scale(0.97);
    pointer-events: auto;
  }
  
  img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    object-position: right;
    display: block;
    background: transparent;
  }

  &.fadeOut {
    opacity: 0;
    transform: translate(0, -50%) scale(0.95);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .companyLogo {
    width: auto !important;
    height: 80px !important;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    opacity: 0;
    transform: scale(0.9);
    animation: logoEnter 0.5s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    padding: 20px;
    
    img {
      width: 100%;
      height: auto;
      max-height: none;
      object-fit: contain;
    }

    &.show {
      transform: translate(0, -50%) scale(1);
    }
  }
}

@keyframes logoEnter {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.jobGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 130px));
  gap: 8px;
  justify-content: center;
  align-content: center;
  width: fit-content;
  margin: 0 auto;
  position: relative;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: visible;
  opacity: 1;
  transform: scale(1);

  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, minmax(0, 120px));
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0, 110px));
    gap: 6px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    width: 100%;
    max-width: none;
    padding: 0;
  }
}

.tileWrapper {
  position: relative;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  overflow: hidden;
  
  .jobTile {
    width: 100%;
    height: auto;
    aspect-ratio: 353/159;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
  }

  @media (max-width: 768px) {
    transform-origin: center;
    
    &.selected {
      transform: scale(1.02);
    }
    
    &.unselected {
      transform: scale(0.98);
    }
  }
}

.initialEnter {
  opacity: 0;
  transform: scale(0.9) translateY(20px);
  animation: tileEnter 0.5s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  will-change: transform, opacity;

  @for $i from 0 through 15 {
    &:nth-child(#{$i + 1}) {
      animation-delay: #{$i * 0.06}s;
    }
  }
}

@keyframes tileEnter {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.selected {
  transform: scale(1.03);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  z-index: 1;

  .jobTile {
    filter: brightness(1.02);
  }

  &.manySelected {
    transform: scale(1.01);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  }
}

.unselected {
  transform: scale(0.97);
  opacity: 0.25;
  z-index: 0;

  .jobTile {
    filter: grayscale(0.9) blur(0.5px);
  }
}

.cursor {
  position: fixed;
  width: 24px;
  height: 24px;
  pointer-events: none;
  z-index: 9999;
  opacity: 0;
  transform: translate(-50px, 0);
  transition: all 1.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  &.show {
    opacity: 1;
  }

  &.clicking {
    transform: scale(0.9);
    transition: transform 0.15s ease-in-out;
  }
}

.label {
  font-size: 14px;
  color: #6B7280;
  font-weight: 500;
  white-space: nowrap;
  transition: color 0.3s ease;
  position: relative;
  z-index: 2;

  .active + & {
    color: #111827;
  }
}

@media (max-width: 768px) {
  .label {
    font-size: 12px;
  }
}

@media (max-width: 590px) {
  .container {
    padding: 0;
    min-height: 100%;
    margin: 0;
    transform: none;
  }

  .jobDetailsModal {
    width: 100%;
    padding: 16px;
    
    img {
      width: 100%;
      max-height: none;
    }
  }

  .jobGrid {
    grid-template-columns: repeat(4, 1fr);
    gap: 6px;
    width: 100%;
    transform: none;
  }
} 