@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');

// Add these breakpoint variables if not already imported
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == small {
    @media (max-width: $breakpoint-sm) { @content; }
  }
  @if $breakpoint == medium {
    @media (max-width: $breakpoint-md) { @content; }
  }
}

.company-logos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  width: 100%;
  padding: 12px 100px 40px 100px;
  margin-top: 0;

  @media screen and (max-width: 1440px) {
    padding: 40px 160px;
  }

  @media screen and (max-width: 1370px) {
    padding: 40px 120px;
  }

  @media screen and (max-width: 1280px) {
    padding: 40px 80px;
  }

  @media screen and (max-width: 1200px) {
    padding: 40px 50px;
  }

  @media screen and (max-width: 1100px) {
    padding: 40px 40px;
  }

  @media screen and (max-width: 1024px) {
    padding: 40px 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }
}

.company-logos-heading {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #2B2B2B;
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    font-size: 17px;
    width: 95%;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
    width: 100%;
  }
}

.company-logos-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  width: 100%;
  // padding-top: 30px;
  // margin-top: 20px;

  @media screen and (max-width: 768px) {
    gap: 15px;
  }

  &.fade-in .logo-wrapper {
    opacity: 0;
    animation: fadeInScale 1.2s cubic-bezier(0.23, 1, 0.32, 1) forwards;
    
    // Stagger animation for each logo in a row
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.15}s;
      }
    }
  }

  &.fade-out .logo-wrapper {
    animation: fadeOutScale 1.2s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  }

  .logos-row {
    display: flex;
    justify-content: center;
    gap: 65px;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1440px) {
      gap: 50px;
    }

    @media screen and (max-width: 1024px) {
      gap: 35px;
    }

    @media screen and (max-width: 768px) {
      gap: 25px;
    }

    @media screen and (max-width: 480px) {
      gap: 20px;
    }

    .logo-wrapper {
      flex-shrink: 0;
      width: 50px;
      height: 50px;

      @media screen and (max-width: 1440px) {
        width: 48px;
        height: 48px;
      }

      @media screen and (max-width: 1024px) {
        width: 45px;
        height: 45px;
      }

      @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
      }

      @media screen and (max-width: 480px) {
        width: 35px;
        height: 35px;
      }
    }

    .logo-item {
      font-size: 3rem;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }

      @media screen and (max-width: 1024px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }

    &:first-child {
      margin-top: 10px;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    &.fade-in .logo-wrapper {
      animation-duration: 0.5s;
    }
    
    &.fade-out .logo-wrapper {
      animation-duration: 0.5s;
    }
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(20px);
  }
  60% {
    transform: scale(1.05) translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  100% {
    opacity: 0;
    transform: scale(0.8) translateY(20px);
  }
}

.feature-highlight-section {
  font-family: 'Inter', sans-serif;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  position: relative;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px; // Consistent margin
  }

  p {
    font-size: 1.25rem;
    font-weight: 400;
    color: #63727E;
    text-align: left;
    line-height: 1.5;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-size: 1.75rem;
    }

    p {
      font-size: 1.125rem;
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

.logo-item {
  width: 100%;
  height: 100%;

  &.special-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    padding: 0;
  }
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  @media screen and (max-width: 1440px) {
    width: 48px;
    height: 48px;
  }

  @media screen and (max-width: 1024px) {
    width: 45px;
    height: 45px;
  }

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 480px) {
    width: 35px;
    height: 35px;
  }
}