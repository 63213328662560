.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: width 0.3s ease;
  z-index: 1001;
  background: #0033A0;

  @media (max-width: 768px) {
    transform: translateX(-100%);
    width: 250px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  }
}

.sidebar.expanded {
  width: 250px;

  @media (max-width: 768px) {
    transform: translateX(0);
  }
}

.pricing-container-wrapper {
  display: flex;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  background-color: #ffffff;
}

.pricing-content {
  margin-left: 80px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 40px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px;
  }
}

.sidebar.expanded + .pricing-container-wrapper .pricing-content {
  margin-left: 250px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.pricing-page-second {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;

  h1 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #0F0F0F;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .description {
    text-align: center;
    color: #64748b;
    margin-bottom: 3rem;
    font-size: 1.125rem;
    line-height: 1.6;
  }

  .billing-toggle {
    width: fit-content;
    display: inline-flex;
    background: #F5F5F5;
    padding: 0.25rem;
    border-radius: 6rem;
    margin: 0 auto 4rem auto;
    display: block;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      height: calc(100% - 8px);
      width: calc(50% - 4px);
      background: #0033A0;
      border-radius: 5rem;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateX(0);
      box-shadow: 0 2px 4px rgba(0, 51, 160, 0.2);
    }

    &[data-active="monthly"]::before {
      transform: translateX(0);
    }

    &[data-active="quarterly"]::before {
      transform: translateX(100%);
    }

    .toggle-btn {
      position: relative;
      padding: 0.75rem 2rem;
      border: none;
      background: none;
      color: #64748b;
      cursor: pointer;
      transition: all 0.3s ease;
      font-weight: 500;
      font-size: 0.95rem;
      z-index: 1;
      width: 50%;
      white-space: nowrap;
      min-width: 120px;

      &.active {
        color: #ffffff;
        font-weight: 600;
      }

      &:hover:not(.active) {
        color: #0f172a;
      }
    }
  }

  .pricing-cards {
    display: flex;
    gap: 2rem;
    justify-content: center;
    
    .pricing-card {
      background: white;
      border-radius: 0.5rem;
      padding: 2.5rem;
      width: 100%;
      max-width: 380px;
      text-align: left;
      border: 1px solid #e2e8f0;
      position: relative;
      display: flex;
      flex-direction: column;

      &.pro {
        background: #ffffff;
        border: 1px solid #0033A0;
        box-shadow: 0 4px 6px -1px rgba(0, 51, 160, 0.05), 0 2px 4px -1px rgba(0, 51, 160, 0.03);

        .features {
          li:first-child {
            color: #0033A0;
            font-weight: 500;
          }
        }
      }

      .card-header {
        margin-bottom: 0;
        padding-bottom: 2.5rem;
        border-bottom: 1px solid #f1f5f9;
        display: flex;
        flex-direction: column;
        height: 400px;

        .pricing-tier-title {
          font-size: 1.75rem;
          color: #0F0F0F;
          font-weight: 700;
          margin-bottom: 0.5rem;
        }

        .description {
          text-align: left;
          color: #64748b;
          margin-bottom: 1.5rem;
          font-size: 1rem;
        }

        .price {
          margin-bottom: 0.75rem;
          display: flex;
          align-items: flex-end;
          gap: 0.25rem;

          .amount {
            font-size: 3.5rem;
            font-weight: 700;
            color: #0f172a;
            line-height: 1;
          }

          .period {
            color: #64748b;
            font-size: 1.125rem;
            margin-bottom: 0.5rem;
          }
        }

        .billing-info {
          color: #64748b;
          font-size: 0.875rem;
          margin-bottom: 3rem;
          min-height: 1.25rem;
        }

        .button-container {
          margin-top: auto;
          padding-bottom: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.75rem;

          .signup-button {
            width: 100%;
            height: 48px;
            padding: 0.875rem;
            border-radius: 0.375rem;
            font-weight: 500;
            transition: all 0.2s ease;
            text-align: center;
            text-decoration: none;
            font-size: 0.95rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;

            &.primary {
              background: #0033A0;
              color: white;
              border: 1px solid #0033A0;

              &:hover:not(:disabled) {
                background: #002880;
                border-color: #002880;
              }
            }

            &.secondary {
              background: white;
              color: #0f172a;
              border: 1px solid #e2e8f0;

              &:hover:not(:disabled) {
                border-color: #0033A0;
                color: #0033A0;
              }
            }

            &.disabled {
              background-color: #E2E8F0;
              color: #64748B;
              cursor: not-allowed;
              border: none;
              width: 100%;
            }
          }

          .no-card-text, .placeholder-text {
            color: #64748b;
            font-size: 0.875rem;
            text-align: center;
            height: 20px;
            margin: 0;
          }

          .manage-subscription-link {
            color: #64748b;
            font-size: 0.875rem;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            height: 20px;
            margin: 0;

            &:hover {
              color: #0033A0;
            }
          }
        }
      }

      .features {
        list-style: none;
        padding: 0;
        margin: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-top: 2.5rem;

        li {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          color: #475569;
          font-size: 1rem;

          .check-icon {
            color: #0033A0;
            font-size: 1rem;
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            stroke-width: 2px;

            &.gift {
              color: #0033A0;
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
}
  
  