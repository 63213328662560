@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Roboto:wght@400;700&display=swap");

$navbar-height: 88px;

/* Smooth scrolling for entire page */
html {
  scroll-behavior: smooth;
}

.about-page-container {
  width: 100%;
  padding-top: $navbar-height; /* offset from navbar */
  padding-left: 200px;
  padding-right: 200px;
  overflow-x: hidden;
  margin-bottom: 0;
  transition: padding 0.3s ease;
  background: #ffffff !important;

  @media (max-width: 1440px) {
    padding-left: 160px;
    padding-right: 160px;
  }
  @media (max-width: 1370px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (max-width: 1280px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (max-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1100px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}



/* --- MISSION SECTION (center-aligned text) --- */
.mission-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  padding: 100px 0 0;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.animate {
    opacity: 0;
    transform: translateY(20px);
  }
  &.animate.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .about-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 40px;
      line-height: 1.2;
      color: #202124;

      @media (max-width: 768px) {
        font-size: 32px;
        margin-bottom: 32px;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.6;
      color: #5f6368;
      margin-bottom: 24px;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 1.5;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 50px 0 0;
  }
}

/* --- FOUNDER SECTION --- */
.founder-section {
  margin-bottom: 120px;
  width: 100%;
  position: relative;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.animate {
    opacity: 0;
    transform: translateY(20px);
  }
  &.animate.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .founder-title-container {
    width: 100%;
    text-align: center;
    margin-bottom: 48px;

    .founder-title {
      font-size: 48px;
      font-weight: 700;
      line-height: 1.2;
      margin: 0;
      color: #202124;

      @media (max-width: 768px) {
        font-size: 32px;
        margin-bottom: 32px;
      }
    }
  }
  .founder-content {
    flex: 1 1 auto;
    max-width: 1000px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .message-box {
      position: relative;
      background: linear-gradient(135deg, #0033a0 0%, #0056d2 100%);
      padding: 32px 40px;
      border-radius: 24px;
      color: white;
      box-shadow: 0 8px 32px rgba(0, 51, 160, 0.08);
      margin: 0 0 0 20px;
      width: calc(100% - 20px);
      max-width: 1000px;
      opacity: 0;
      transform: translateY(30px);
      transition: opacity 0.5s ease-out, transform 0.5s cubic-bezier(0.2, 0.6, 0.35, 1);

      &.animate.visible {
        opacity: 1;
        transform: translateY(0);
      }

      p {
        margin-bottom: 28px;
        font-size: 19px;
        line-height: 1.7;
        text-align: left;
        letter-spacing: 0.2px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.95);

        &:last-of-type {
          margin-bottom: 32px;
        }

        @media (max-width: 768px) {
          font-size: 17px;
          line-height: 1.6;
          margin-bottom: 24px;
        }
      }

      .founder-signature {
        margin-top: 0;
        padding-top: 28px;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        text-align: left;
        max-width: 700px;

        .name {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 6px;
          letter-spacing: 0.3px;
          color: #ffffff;
        }
        .title {
          font-size: 16px;
          opacity: 0.85;
          letter-spacing: 0.2px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: -8px;
        height: 22px;
        width: 20px;
        background: #003fb1;
        border-bottom-right-radius: 15px 14px;
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: -10px;
        width: 10px;
        height: 22px;
        background: #ffffff;
        border-bottom-right-radius: 10px;
      }

      @media (max-width: 768px) {
        padding: 24px 28px;
        margin: 0 0 0 16px;
        width: calc(100% - 16px);
        border-radius: 20px;

        &::before {
          bottom: 0;
          left: -6px;
          width: 15px;
          height: 15px;
        }

        &::after {
          bottom: 0;
          left: -8px;
          width: 8px;
          height: 15px;
        }

        .founder-signature {
          padding-top: 24px;

          .name {
            font-size: 18px;
            margin-bottom: 4px;
          }
          .title {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
}

/* --- COFOUNDERS SECTION --- */
.cofounders-section {
  margin-bottom: 120px;
  width: 100%;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.animate {
    opacity: 0;
    transform: translateY(20px);
  }
  &.animate.visible {
    opacity: 1;
    transform: translateY(0);
  }

  h2 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    color: #202124;

    @media (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }

  .cofounders-grid {
    display: flex;
    gap: 60px;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }

    .cofounder-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 240px;

      .cofounder-photo-container {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 16px;
        border: 2px solid #ddd;

        .cofounder-photo {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h3 {
        font-size: 20px;
        margin: 0 0 4px;
        color: #202124;
      }

      p {
        font-size: 16px;
        color: #5f6368;
        margin: 0;
        line-height: 1.6;
      }

      /* Example styling for LinkedIn link/icon */
      .linkedin-link {
        margin-top: 8px;
        display: inline-block;

        .linkedin-icon {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }
    }
  }
}

/* (Optional) fadeInUp keyframes if you're using .animate/.visible logic */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes messageAppear {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
