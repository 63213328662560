.jobTileContainer {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  :global(.jobTileBackground) {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// Add smooth transitions for SVG elements
:global {
  .jobTileBackground {
    svg {
      g {
        transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
        
        path, line {
          transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
        }
      }
    }
  }
}
