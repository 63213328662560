.progress-bar-container {
      width: 100%;
      height: 6px;
      background-color: white;
      margin-bottom: 1rem;
      border-radius: 10px;
      overflow: hidden;
}

.progress-bar-filled {
      height: 100%;
      background-color: #0033A0;
      transition: width 0.6s ease-in-out;
      border-radius: 10px;
}