// main.scss

@import "utilities/variables";
@import "base/reset";
@import "base/typography";
@import 'components/landing-pages/landingPage';
@import 'components/bringYourJob/bringYourJob';
@import 'components/bringYourJob/emptyJobDetails';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Theme Adjustments
$app-bg-start: #ffffff;
$app-bg-end: #ffffff;
$app-header-color: #e4e4e4;
$btn-color: #1da54a;
$card-bg: rgba(255, 255, 255, 0.1);
$font-main: 'Inter', sans-serif;

// General Styles
body {
  font-family: $font-main;
  background: linear-gradient(to bottom, $app-bg-start, $app-bg-end);
  color: #c9d1d9;
  height: 100vh;
}

body::-webkit-scrollbar-thumb{
  background-color: #0033A0;
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid orange;
}

.app-container, .landing-container, .resume-form {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to bottom, $app-bg-start, $app-bg-end);
  min-height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-header, .landing-header {
  text-align: center;
  margin-bottom: 50px;

  h1 {
    color: $app-header-color;
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
  }
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.main-content {
  background: $card-bg;
  padding: 2rem 4rem;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 800px;

  .file-section {
    margin-bottom: 30px;

    label {
      display: block;
      margin-bottom: 10px;
      color: #555;
    }

    input[type="file"] {
      width: 100%;
      padding: 15px;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
    }
  }

  .action-section {
    text-align: center;

    .tailor-btn {
      background-color: $btn-color;
      color: white;
      padding: 15px 30px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($btn-color, 10%);
      }
    }
  }
}

.app-footer {
  margin-top: auto;
  text-align: center;
  padding: 20px 0;
  font-size: 0.9rem;
  color: #eee;

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span.heart {
      color: red;
    }
  }
}

// Add this override at the end of the file

.sidebar {
  background-color: #0033A0 !important;
}

html {
  scroll-behavior: auto !important;
}
