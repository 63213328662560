.welcome-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease;
  padding: 20px;
}

.welcome-modal-content {
  background: #ffffff;
  width: 90%;
  max-width: 600px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: slideUp 0.3s ease;
  overflow: hidden;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 1;
  border-radius: 50%;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    color: #333;
  }
}

.welcome-header {
  text-align: center;
  padding: 40px 40px 20px;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  position: relative;

  .trial-badge {
    background: linear-gradient(45deg, #0033A0, #00297A);
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 16px;
    border-radius: 20px;
    display: inline-block;
    margin-bottom: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    animation: pulse 2s infinite;
  }

  .title-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 12px;

    .celebration-icon {
      font-size: 32px;
      display: inline-block;
    }

    h2 {
      font-size: 32px;
      color: #0033A0;
      font-weight: 700;
      margin: 0;
    }
  }

  p {
    font-size: 16px;
    color: #666;
    margin: 0;
    line-height: 1.5;
  }
}

.features-list {
  padding: 20px 40px;

  .feature-item {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    margin-bottom: 16px;
    background: #f8f9fa;
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid transparent;

    &.premium {
      background: #f8f9fa;
      border-left: 3px solid #0033A0;
    }

    &.free {
      background: #ffffff;
      border: 1px solid #e5e7eb;
      padding: 16px;
      margin-bottom: 12px;

      .feature-details h3 {
        color: #374151;
      }
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 51, 160, 0.2);
      background: white;
    }

    .feature-icon {
      font-size: 24px;
      margin-right: 16px;
      margin-top: 2px;
    }

    .feature-details {
      flex: 1;

      h3 {
        font-size: 18px;
        color: #0033A0;
        margin: 0 0 4px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        color: #666;
        margin: 0;
        line-height: 1.4;
      }
    }
  }
}

.features-divider {
  text-align: center;
  margin: 32px 0 24px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: #e5e7eb;
  }

  span {
    position: relative;
    background: #fff;
    padding: 0 16px;
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
  }
}

.free-features {
  .feature-item {
    opacity: 0.85;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}

.modal-footer {
  padding: 32px 40px 40px;
  text-align: center;
  background: #ffffff;
  border-top: 1px solid #f3f4f6;

  .trial-note {
    margin-top: 12px;
    font-size: 14px;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.get-started-btn {
  background: #0033A0;
  color: white;
  border: none;
  padding: 16px 36px;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  min-width: 240px;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:hover {
    background: #00297A;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 51, 160, 0.3);
  }

  &:active {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 51, 160, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 51, 160, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 51, 160, 0);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .welcome-modal-content {
    width: 95%;
    margin: 0;
  }

  .welcome-header {
    padding: 30px 20px 15px;

    .trial-badge {
      font-size: 12px;
      padding: 4px 12px;
      margin-bottom: 20px;
    }

    .title-row {
      gap: 8px;

      .celebration-icon {
        font-size: 28px;
      }

      h2 {
        font-size: 26px;
      }
    }

    p {
      font-size: 15px;
    }
  }

  .features-list {
    padding: 15px 20px;

    .feature-item {
      padding: 15px;

      &.free {
        padding: 12px;
      }

      .feature-details {
        h3 {
          font-size: 16px;
        }

        p {
          font-size: 13px;
        }
      }
    }
  }

  .features-divider {
    margin: 24px 0 20px;
  }

  .modal-footer {
    padding: 24px 20px 32px;
  }
}

@media (max-width: 480px) {
  .welcome-header {
    .title-row {
      gap: 6px;

      .celebration-icon {
        font-size: 24px;
      }

      h2 {
        font-size: 24px;
      }
    }
  }

  .get-started-btn {
    width: 100%;
    padding: 14px 20px;
    font-size: 14px;
    min-width: 0;
  }

  .trial-note {
    font-size: 12px;
  }
} 