.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  background-color: #0033A0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 12px 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: visible;
  z-index: 1000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-right: none;
  will-change: transform, width;

  body.modal-open & {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-100%);
  }

  @media (max-width: 768px) {
    transform: translateX(-100%);
    width: 250px;
    position: fixed;
    background-color: #0033A0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
    will-change: transform !important;
    
    &.expanded {
      transform: translateX(0);
      pointer-events: all !important;
      
      body.modal-open & {
        visibility: hidden;
        opacity: 0;
        pointer-events: none !important;
        transform: translateX(-100%);
      }
    }

    .sidebar-icon .icon-text,
    .profile-trigger .user-info,
    .profile-trigger .trigger-icon {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-10px);
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &.expanded, &.hovered {
    width: 250px;
    background-color: #0033A0;

    @media (max-width: 768px) {
      width: 250px;
      position: fixed;
      background-color: #0033A0;
    }

    .sidebar-icon .icon-text {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      width: auto;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      transition-delay: 0.1s;
    }

    .profile-trigger {
      .user-info {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
        width: auto !important;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }

      .trigger-icon {
        opacity: 1 !important;
        visibility: visible !important;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  .sidebar-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 28px;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.2rem;
    margin-bottom: 24px;
    height: 36px;

    svg {
      stroke-width: 1.75;
    }
  }

  .sidebar-top,
  .sidebar-bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .sidebar-icon {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 1.2rem;
    padding: 12px 28px;
    cursor: pointer;
    transition: transform 0.2s ease;
    width: 100%;
    position: relative;
    margin: 1px 0;
    white-space: nowrap;
    pointer-events: all !important;
    z-index: 1002 !important;

    svg {
      min-width: 22px;
      height: 22px;
      transition: transform 0.2s ease;
      stroke-width: 1.5;
      flex-shrink: 0;
      color: #ffffff;

      &.lucide-icon {
        stroke-width: 1.75;
      }

      path {
        stroke: #ffffff;
      }
    }

    .custom-icon {
      min-width: 18px;
      height: 18px;
      transition: transform 0.2s ease;
      flex-shrink: 0;
      margin: 2px;

      &[alt="Job Search"],
      &[alt="Bring Your Own Job"],
      &[alt="Application Tracker"] {
        min-width: 21px;
        height: 21px;
        margin: 0.5px;
      }
    }

    &:hover {
      svg, .custom-icon {
        transform: scale(1.05);
      }
    }

    &.active {
      font-weight: 500;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 0 3px 3px 0;
      }
    }

    .icon-text {
      margin-left: 16px;
      font-size: 0.925rem;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transform: translateX(-10px);
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
      width: 0;
      overflow: hidden;
    }
  }

  .sidebar-top {
    margin-top: 8px;
  }

  .sidebar-bottom {
    margin-top: auto;
    position: relative;
    padding-top: 8px;
    margin-bottom: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 16px;
      right: 16px;
      height: 1px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 20%,
        rgba(255, 255, 255, 0.1) 80%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .profile-section {
    position: relative;
    cursor: pointer;
    color: #ffffff;
    transition: all 0.2s ease;
    height: 48px;
    display: flex;
    align-items: center;
    pointer-events: all !important;
    z-index: 1002 !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    .profile-trigger {
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 12px 28px;
      cursor: pointer;
      width: 100%;
      position: relative;
      white-space: nowrap;
      pointer-events: all !important;

      .avatar-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        height: 28px;
        flex-shrink: 0;
        position: relative;
        pointer-events: all !important;
        
        .avatar {
          width: 28px;
          height: 28px;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8125rem;
          font-weight: 500;
          border: 1px solid rgba(255, 255, 255, 0.1);
          transition: background-color 0.2s ease, border-color 0.2s ease;
          position: absolute;
          left: 0;
          pointer-events: all !important;

          &:hover {
            background-color: rgba(255, 255, 255, 0.15);
            border-color: rgba(255, 255, 255, 0.2);
          }
        }
      }

      .user-info {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        min-width: 0;
        flex: 1;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-10px);
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        width: 0;
        overflow: hidden;
        pointer-events: all !important;

        .user-name {
          font-size: 0.875rem;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.4;
          pointer-events: all !important;
        }

        .user-email {
          font-size: 0.75rem;
          opacity: 0.7;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.4;
          pointer-events: all !important;
        }
      }

      .trigger-icon {
        width: 16px;
        height: 16px;
        transition: transform 0.2s ease;
        opacity: 0;
        visibility: hidden;
        margin-left: auto;
        flex-shrink: 0;
        pointer-events: all !important;

        &.rotated {
          transform: rotate(180deg);
        }
      }
    }

    .profile-dropdown {
      position: absolute;
      bottom: calc(100% + 8px);
      left: 28px;
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
      min-width: 220px;
      overflow: hidden;
      z-index: 1003 !important;
      border: 1px solid rgba(0, 0, 0, 0.08);
      transform-origin: bottom left;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.95);
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

      &.show {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }

      &.hide {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.95);
      }

      .dropdown-section {
        padding: 6px;

        & + .dropdown-section {
          padding-top: 6px;
          margin-top: 6px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.08);
          }
        }
      }

      .dropdown-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 10px;
        color: #1a1a1a;
        transition: all 0.15s ease;
        background: transparent;
        border-radius: 4px;
        font-size: 0.9375rem;
        margin: 2px 0;

        svg {
          width: 16px;
          height: 16px;
          color: #666;
          transition: color 0.2s ease;
          
          &.lucide-icon {
            stroke-width: 1.75;
          }
        }

        span {
          font-weight: 450;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);

          svg {
            color: #0033A0;
          }
        }
      }
    }
  }
}

.sidebar-overlay {
  z-index: 999;
  
  body.modal-open & {
    display: none;
  }
}

/* Mobile menu button */
.mobile-menu-button {
  z-index: 1000;
  pointer-events: all;
  
  body.modal-open & {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}