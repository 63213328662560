@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.application-confirm-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  opacity: 1;

  &.closing {
    animation: fadeOut 0.16s ease-out forwards;
    pointer-events: none;

    .application-confirm-content {
      animation: fadeOut 0.16s ease-out forwards;
    }
  }

  .application-confirm-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 1.75rem 2rem;
    width: 95%;
    max-width: 380px;
    box-shadow: 
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);

    h2 {
      font-size: 1.375rem;
      font-weight: 600;
      color: #1a1a1a;
      margin: 0 0 0.875rem 0;
      letter-spacing: -0.02em;
      line-height: 1.3;
    }

    p {
      font-size: 0.9375rem;
      color: #4b5563;
      margin: 0 0 1.75rem 0;
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: -0.01em;
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
      flex-direction: row;
      align-items: center;

      button {
        padding: 0.6875rem 1.25rem;
        font-size: 0.9375rem;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 500;
        min-width: 76px;
        letter-spacing: -0.01em;
        font-family: inherit;
        margin: 0;

        &.keep-button {
          background-color: #fff;
          border: 1px solid #e5e7eb;
          color: #4b5563;
          order: 1;

          &:hover {
            background-color: #f9fafb;
            border-color: #d1d5db;
            color: #1f2937;
          }

          &:active {
            background-color: #f3f4f6;
            transform: translateY(1px);
          }
        }

        &.discard-button {
          background-color: #0033A0;
          color: white;
          border: none;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          order: 2;

          &:hover {
            background-color: #0047B3;
          }

          &:active {
            background-color: #002D8C;
            transform: translateY(1px);
          }
        }
      }
    }
  }
} 