@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

// Variables
$tile-gap: 20px;
$tile-padding: 20px;
$tile-border-radius: 8px;
$tile-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
$tile-border: 1px solid #cccccc;

.appTrackerContainer {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: width 0.3s ease;
  z-index: 100;
}

.sidebar.expanded {
  width: 250px;
}

.appTrackerMain {
  margin-left: 80px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
}

.dashboardHeader {
  text-align: left;
  padding: 40px 0 40px 100px;
  width: 100%;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
}

.greeting {
  font-size: 36px;
  font-weight: 700;
  color: #333333;
  margin: 0;
}

:global(.sidebar.expanded) ~ .appTrackerMain {
  margin-left: 250px;
}

.kanbanBoard {
  flex: 1;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.error {
  width: 100%;
  padding: 20px;
  text-align: center;
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: $tile-border-radius;
  margin: 20px 0;
}

.noApplications {
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: $tile-border-radius;
  box-shadow: $tile-box-shadow;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 16px;
  }

  p {
    color: #666;
    margin-bottom: 24px;
  }

  .searchButton {
    padding: 12px 24px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: $tile-border-radius;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background: #0056b3;
    }
  }
}

.appTrackerTilesContainer {
  display: flex;
  gap: $tile-gap;
  align-items: flex-start;
  width: calc(100% - 92.16px);
  margin: 20px auto 0;
  height: calc(100vh - 120px);
}

.kanbanColumn {
  flex: 1;
  width: calc((100% - (2 * #{$tile-gap})) / 3);
  background: transparent;
  border-radius: $tile-border-radius;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 15.36px 0 16px;
    border-bottom: 2px solid #e9ecef;
    background: #ffffff;
    position: sticky;
    top: 120px;
    z-index: 5;
  }
}

.columnContent {
  flex: 1;
  overflow-y: auto;
  padding-bottom: $tile-gap;
  position: relative;
  transition: background-color 0.2s ease;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  :global(.job-tile) {
    width: 100% !important;
    max-width: 100% !important;
    background: #fff;
    padding: $tile-padding;
    border-radius: $tile-border-radius;
    box-shadow: $tile-box-shadow;
    border: $tile-border;
    position: relative;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    height: 261.8px;
    display: flex;
    flex-direction: column;
    user-select: none;
    cursor: grab;
    will-change: transform, box-shadow;
    margin-bottom: $tile-gap;

    &[data-rbd-dragging-draggable-id] {
      opacity: 0.4;
      transform: none !important;
      box-shadow: none;
    }

    &[data-rbd-dragging="true"] {
      background: #ffffff;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      transform: scale(1.02) translateZ(0);
      z-index: 999;

      > * {
        opacity: 0.9;
      }
    }

    &:hover {
      transform: translateY(-2px) translateZ(0);
      box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
    }

    &:active {
      cursor: grabbing;
    }
  }
}

.count {
  font-size: 14px;
  color: #666;
  font-weight: normal;
  background: #e9ecef;
  padding: 2px 8px;
  border-radius: 4px;
}

[data-rbd-placeholder-context-id] {
  height: 261.8px !important;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  box-sizing: border-box;
  margin-bottom: $tile-gap !important;
}

// Single drop indicator line
.dropIndicator {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(35, 131, 226, 0.28);
  box-shadow: 0 0 4px rgba(35, 131, 226, 0.28);
  pointer-events: none;
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);

  &.visible {
    opacity: 1;
  }
}

// Remove dragging over effects
.draggingOver {
  &::after {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .kanbanColumns {
    flex-direction: column;
  }

  .kanbanColumn {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .appTrackerMain {
    margin-left: 0;
  }

  .appTrackerHeader {
    padding: 20px;
  }

  .kanbanBoard {
    width: 100%;
    padding: 0 20px;
  }
}

.draggableContainer {
  height: 261.8px;
  margin-bottom: $tile-gap;
  box-sizing: border-box;

  &.dragging {
    margin-bottom: 0;
  }
}

[data-rbd-placeholder-context-id] {
  height: 261.8px !important;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  box-sizing: border-box;
  margin-bottom: $tile-gap !important;
}

// Add at the top with other animations
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

.jobTileSkeleton {
  width: 100%;
  height: 261.8px;
  border-radius: $tile-border-radius;
  margin-bottom: $tile-gap;
}

// Add to existing styles
.greeting {
  &.skeleton {
    width: 300px;
    height: 44px;
    border-radius: 4px;
  }
}

.kanbanColumn {
  h2.skeleton {
    width: 150px;
    height: 28px;
    border-radius: 4px;
  }
} 