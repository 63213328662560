.metrics {
    width: 100%;  // Full width
    min-height: 100vh;  // Full height
    padding: 40px;  // Adjust padding as needed
    background-color: #f5f5f5;  // Light background color
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Karla', sans-serif; // Consistent font
  
    .metrics-content {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    h1 {
      font-size: 2rem;
      color: #333;
      margin-bottom: 20px;
    }
  
    .stats {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      width: 100%;
    }
  
    .stat {
      background: white;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
  
    .stat h2 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 10px;
    }
  
    .stat p {
      font-size: 1.2rem;
      color: #666;
      margin-bottom: 5px;
    }
  
    .stat span {
      font-size: 0.9rem;
      color: #999;
    }
  }
  