.delete-confirm-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);

  &.closing {
    animation: fadeOut 0.2s ease-in-out forwards;
  }
}

.delete-confirm-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: left;
  z-index: 1000000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;

  h2 {
    margin: 0 0 12px 0;
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    text-align: left;
  }

  p {
    margin: 0 0 16px 0;
    color: #6B7280;
    font-size: 15px;
    line-height: 1.5;
    text-align: left;

    &.warning {
      color: #991B1B;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;

    button {
      padding: 8px 16px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      font-family: 'Inter', sans-serif;
      min-width: 80px;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.cancel-button {
        background: white;
        color: #374151;
        border: 1px solid #D1D5DB;

        &:hover:not(:disabled) {
          background: #F9FAFB;
          border-color: #9CA3AF;
        }

        &:active:not(:disabled) {
          background: #F3F4F6;
        }
      }

      &.delete-button {
        background: #EF4444;
        color: white;
        border: none;

        &:hover:not(:disabled) {
          background: #DC2626;
        }

        &:active:not(:disabled) {
          background: #B91C1C;
        }
      }
    }
  }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
} 