/* base/reset.scss */

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, button {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

body {
    line-height: 1;
}
