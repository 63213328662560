.bring-your-job-container {
  display: flex;
  min-height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1001;
  background: #0033A0;

  @media (max-width: 768px) {
    transform: translateX(-100%);
    width: 250px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  }
}

.sidebar.expanded {
  width: 250px;

  @media (max-width: 768px) {
    transform: translateX(0);
  }
}

.bring-your-job {
  margin-left: 80px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px;
    width: 100%;
    overflow-x: hidden;
    min-height: calc(100vh - 80px);
    justify-content: flex-end;
    margin-top: auto;
  }

  .header-section {
    width: 100%;
    max-width: 800px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      padding: 0 20px;
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #333;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
}

.sidebar.expanded + .bring-your-job {
  margin-left: 250px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  p {
    font-size: 16px;
    color: #666;
  }

  .error-message {
    color: #dc3545;
  }
}

.job-form-container {
  width: 100%;
  max-width: 600px;
  
  @media (max-width: 768px) {
    margin-top: auto;
    padding: 0 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    @media (max-width: 768px) {
      gap: 16px;
    }
    
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      @media (max-width: 768px) {
        gap: 6px;
      }
      
      label {
        font-size: 14px;
        font-weight: 500;
        color: #374151;
      }
      
      input, textarea {
        padding: 12px;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 14px;
        
        @media (max-width: 768px) {
          padding: 10px;
          font-size: 16px; // Better for mobile input
        }
        
        &:focus {
          outline: none;
          border-color: #0033A0;
          box-shadow: 0 0 0 1px #0033A0;
        }
      }

      textarea {
        @media (max-width: 768px) {
          min-height: 120px; // Taller on mobile for better visibility
        }
      }
    }
    
    .submit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 10px 20px;
      background: #0033A0 !important;
      color: white;
      border: none;
      border-radius: 8px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.15s ease;
      white-space: nowrap;
      font-family: 'Inter', sans-serif;
      
      @media (max-width: 768px) {
        padding: 14px 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
      }
      
      &:hover:not(:disabled) {
        background: #0044CC !important;
        transform: scale(1.02);
        box-shadow: 0 2px 8px rgba(0, 51, 160, 0.3);
      }
      
      &:disabled {
        background: #9CA3AF !important;
        cursor: not-allowed;
        transform: none;
        box-shadow: none;
      }
      
      .loading-spinner {
        width: 20px;
        height: 20px;
        border: 2px solid #ffffff;
        border-top-color: transparent;
        border-radius: 50%;
        animation: spin 0.8s linear infinite;
      }
    }
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

// Job Details Modal content styling
.content-container {
  display: flex;
  gap: 40px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
  
  .left-column {
    flex: 2;
    padding-right: 20px;

    h2, h3 {
      margin-top: 0;
      font-weight: bold;
      font-family: 'Inter', sans-serif;
      color: #333;
      margin-bottom: 12px;
      font-size: 18px;
    }

    p, ul {
      margin-top: 0;
      margin-bottom: 24px;
      font-family: 'Inter', sans-serif;
      color: #000000;
      line-height: 1.5;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-left: 0;

      li {
        position: relative;
        padding-left: 24px;
        margin-bottom: 12px;
        color: #000000;
        line-height: 1.5;
      }
    }

    .skills {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 24px;
    }

    .skill-badge {
      background: #e0e0e0;
      border-radius: 20px;
      padding: 5px 10px;
      margin: 0;
      color: #000000;
      font-weight: 500;
    }
  }

  .right-column {
    flex: 1;
    padding-left: 20px;
    margin-top: 0;
    margin-left: auto;

    .job-info-box {
      width: 100%;
      padding: 15px;
      background: white;
      border: 1px solid black;
      border-radius: 8px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;

      h4 {
        margin: 0;
        font-size: 0.9em;
        font-weight: bold;
        font-family: 'Inter', sans-serif;
        color: grey;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      p {
        margin: 0.5rem 0 1rem;
        color: #000000;
        font-size: 1.2em;
        font-weight: bold;
        font-family: 'Inter', sans-serif;
      }

      .about-company-text {
        font-size: 1em;
        font-weight: normal;
        font-family: 'Inter', sans-serif;
        color: #000000;
      }

      .about-company {
        margin-top: 40px;
      }
    }
  }
}
  