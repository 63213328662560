/* Typography Scale */
:root {
  --font-scale-xs: clamp(0.75rem, 1.5vw, 0.875rem);
  --font-scale-sm: clamp(0.875rem, 2vw, 0.95rem);
  --font-scale-base: clamp(1rem, 2.5vw, 1.1rem);
  --font-scale-lg: clamp(1.1rem, 2.5vw, 1.25rem);
  --font-scale-xl: clamp(1.25rem, 3vw, 1.75rem);
  --font-scale-2xl: clamp(1.75rem, 4vw, 2.25rem);
}

.font-sans {
    font-family: var(--font-geist-sans), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80px;
    transition: width 0.3s ease;
    z-index: 100;
  }
  
  .sidebar.expanded {
    width: 250px;
  }
  
  .sidebar.expanded + .account-info {
    margin-left: 250px;
  }
  
  .account-info-container {
    display: flex;
    min-height: 100vh;
  }
  
  .account-info {
    margin-left: 80px;
    flex-grow: 1;
    transition: margin-left 0.3s ease;
    padding: 40px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'Karla', sans-serif;
    position: relative;
  }
  
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background: linear-gradient(to right, #ffffff 0%, #d6d8ff 95%);
    margin-bottom: 20px;
    border-radius: 10px;
    width: 100%;
    position: relative;
    border: 1px solid #000;
  }
  
  .left-section {
    display: flex;
    align-items: flex-end;
    gap: 30px;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  
    .profile-picture {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      border: 4px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f0f0f0;
      margin-bottom: 0;
      cursor: pointer;
      position: relative;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      .default-icon {
        font-size: 3rem;
        color: #888;
      }
    }
  
    h1 {
      font-size: 2rem;
      margin: 0;
      font-weight: bold;
      font-family: 'Inter', sans-serif;
      color: #000;
    }
  
    input {
      font-size: 1rem;
      margin-bottom: 10px;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  
    button {
      background-color: #007BFF;
      color: white;
      padding: 8px 16px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 10px;
    }
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
  
    .skills-section {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
  
      .skill-badge {
        background-color: #fff;
        border: 2px solid #0033A0;
        padding: 8px 12px;
        border-radius: 20px;
        font-size: 0.9rem;
        color: #0033A0;
        font-weight: bold;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
      }
    }
  
    .experience-location {
      display: flex;
      align-items: center;
      justify-content: flex-start;
  
      .info-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
  
        .info-value {
          font-weight: bold;
          color: #000; /* Changed text color to black */
          margin-bottom: 2px;
        }
  
        .info-label {
          font-weight: normal;
          color: #000; /* Changed text color to black */
        }
      }
  
      .divider {
        height: 40px;
        width: 2px;
        background-color: #000;
        margin: 0 10px;
        align-self: center;
      }
    }
  
    @media (max-width: 768px) {
      padding-bottom: 15px;
    }
  }
  
  .right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    .social-icons {
      display: flex;
      justify-content: center;
      gap: 15px;
  
      a {
        font-size: 1.8rem;
        color: #0033A0;
        // transition: color 0.3s ease, transform 0.3s ease;
  
        // &:hover {
        //   color: #0056b3;
        //   transform: scale(1.1);
        // }
      }
    }
  
    .share-button,
    .resume-button {
      width: 100%;
      padding: 10px 20px;
      font-size: 1.1rem;
      color: #0033A0;
      background-color: #fff;
      border: 2px solid #0033A0;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
      margin-top: 15px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg {
        margin-right: 10px;
        font-size: 1.3rem;
      }
    }
  }
  
  form {
    padding: 20px;
    width: 100%;
    overflow-y: auto;
  }
  
  .input-field {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    label {
      font-size: 1.2rem;
      color: #555;
      margin-bottom: 8px;
      font-weight: bold;
      width: 100%;
    }
  
    input,
    select,
    textarea {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-sizing: border-box;
      transition: all 0.3s ease;
      background-color: #f9f9f9;
      resize: none; /* Prevents resizing */
      height: 44px; /* Ensures all input fields and text areas have consistent height */
      overflow: hidden; /* Hides the scrollbar */
    }
  
    textarea {
      overflow-y: hidden;
    }
  }
  
  .description-input {
    resize: none;
    height: 44px; /* Matches the height of input fields */
    overflow-y: hidden; /* Hides the scrollbar */
    min-height: 44px;
  }
  
  /* Auto-grow textarea height based on content */
  textarea.description-input {
    max-height: 150px;
  }
  
  .edit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
  
    button {
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #ccc;
      transition: background-color 0.3s ease, color 0.3s ease;
  
      &.save-button {
        background-color: #0033A0;
        color: white;
  
        // &:hover {
        //   background-color: #007BFF;
        // }
      }
  
      &.cancel-button {
        background-color: #f8f9fa;
        color: #6c757d;
  
        // &:hover {
        //   background-color: #e2e6ea;
        // }
      }
    }
  }
  
  .description-item {
    display: flex;
    align-items: center;  /* Aligns icons and text vertically in the center */
    position: relative;
    width: 100%;  /* Ensure the item takes up the full width */
    margin-bottom: 25px;  /* Matches the margin-bottom of the input-field class */
    padding: 0;  /* Reset padding to avoid additional spacing issues */
    
    .drag-icon {
      color: #666;
      cursor: grab; /* Show move cursor when hovering over the icon */
      margin-right: 10px;
      flex-shrink: 0;  /* Prevent the icon from shrinking */
      font-size: 1.2rem;
      height: 44px;  /* Ensure the icon height matches the input field */
      display: flex;
      align-items: center;  /* Vertically center the icon */
    }
  
    .description-input {
      flex-grow: 1;  /* Allow the input to take up available space */
      min-width: 0;  /* Prevent overflow */
      width: 100%;  /* Ensure input spans full width */
      padding: 12px;  /* Match padding with other input fields */
      font-size: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-sizing: border-box;
      transition: all 0.3s ease;
      background-color: #f9f9f9;
      height: 44px;  /* Matches the height of other input fields */
      line-height: 20px;  /* Adjusts line height to better align text */
      overflow: hidden; /* Hides the scrollbar */
      color: #000; /* Changed text color to black */
    }
  
    .delete-icon {
      color: red;
      cursor: pointer;
      margin-left: 10px;
      flex-shrink: 0;  /* Prevent the icon from shrinking */
      height: 44px;  /* Ensure the icon height matches the input field */
      display: flex;
      align-items: center;  /* Vertically center the icon */
    }
  
    &[draggable] {
      cursor: move;
    }
    
    &.dragging {
      opacity: 0.5;
    }
  }
  
  .section-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    padding-bottom: 5px;
    position: relative;
    font-weight: 600;
  
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, transparent 0%, #ccc 2%, #ccc 98%, transparent 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  
  .section {
    margin-bottom: 30px; /* Reduced gap between sections */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    .education-item,
    .experience-item,
    .project-item {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 10px;
      background-color: transparent;
      margin-bottom: 10px; /* Further reduced gap between items */
      transition: box-shadow 0.3s ease, transform 0.3s ease;
  
    //   &:hover {
    //     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    //     transform: translateY(-5px); /* Elevate on hover */
    //   }
  
      .info-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: none;
  
        /* Degree and Graduation Date on the Same Line */
        .degree-date {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 5px; /* Reduced space between degree and date */
  
          .item-title {
            font-size: 1.4rem;
            font-weight: 600;
            color: #000; /* Changed text color to black */
            margin-bottom: 2px; /* Reduced space between title and other elements */
          }
  
          .item-subtitle {
            font-size: 1.1rem;
            color: #000; /* Changed text color to black */
            text-align: right;
          }
        }
  
        .item-subtitle, .bottom-row {
          font-size: 1.1rem;
          color: #000; /* Changed text color to black */
          margin-bottom: 5px; /* Reduced space between items */
        }
  
        /* Experience Section Layout */
        .top-row {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 2px; /* Reduced space between title and company name */
  
          .item-title {
            font-size: 1.4rem;
            font-weight: 600;
            color: #000; /* Changed text color to black */
          }
  
          .item-duration {
            font-size: 1.1rem;
            font-weight: bold;
            color: #000; /* Changed text color to black */
            text-align: right;
          }
        }
  
        .bottom-row {
          display: flex;
          justify-content: space-between;
          width: 100%;
  
          .item-company {
            font-size: 1.1rem;
            color: #000; /* Changed text color to black */
          }
  
          .item-location {
            font-size: 1.1rem;
            color: #000; /* Changed text color to black */
            text-align: right;
          }
        }
  
        ul.item-subtitle {
          padding-left: 1em;
          list-style-type: disc;
        }
      }
  
      .input-field {
        display: none;
        flex-direction: column;
        gap: 15px;
  
        input,
        textarea {
          width: 100%;
        }
  
        .grad-input,
        .duration-input {
          width: 150px;
        }
      }
  
      &.editing .info-content {
        display: none;
      }
  
      &.editing .input-field {
        display: flex;
      }
    }
  }
  
  .section .education-item, 
  .section .experience-item, 
  .section .project-item {
      background-color: transparent; /* Maintains the original transparent background */
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px; /* Further reduced gap between items */
      padding: 20px;
      transition: box-shadow 0.3s ease, transform 0.3s ease;
  
      &.editing {
          border: 2px solid #f1f1f1; /* Persistent border matching the lighter box color */
          background-color: #ffffff; /* Lighter background for the editing section */
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.08); /* Subtle shadow for focus */
      }
      
      .info-content {
          border: none; /* Ensure the static view remains clean */
      }
  
      .input-field {
          border: none; /* Ensure input fields themselves do not have a border */
      }
  }
  
  .update-button {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    margin-top: 30px;
    background-color: #0033A0;
    color: white;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  
    // &:hover {
    //   background-color: #007BFF;
    // }
  }
  
  .error-message, .success-message {
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .error-message {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
  }
  
  .success-message {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }
  
  .edit-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background-color: white;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  
    .modal-content {
      padding: 20px;
  
      h2 {
        margin-bottom: 20px;
        font-size: 1.8rem;
        color: #444;
      }
  
      .input-field {
        margin-bottom: 15px;
  
        label {
          font-size: 1rem;
          color: #555;
          margin-bottom: 5px;
          display: block;
        }
  
        input,
        select {
          width: 100%;
          padding: 10px;
          font-size: 1rem;
          border: 2px solid #ddd;
          border-radius: 5px;
          box-sizing: border-box;
          transition: border-color 0.3s ease;
  
          &:focus {
            border-color: #007bff;
          }
        }
      }
  
      .modal-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;
  
        .cancel-button,
        .save-button {
          padding: 10px 20px;
          font-size: 1rem;
          border-radius: 5px;
          cursor: pointer;
          border: none;
        }
  
        .cancel-button {
          background-color: #f8f9fa;
          color: #6c757d;
  
        //   &:hover {
        //     background-color: #e2e6ea;
        //   }
        }
  
        .save-button {
          background-color: #0033A0;
          color: white;
  
        //   &:hover {
        //     // background-color: #0033A0;
        //     background-color: #007BFF;
        //   }
        }
      }
  
      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #999;
        transition: color 0.3s ease;
  
        // &:hover {
        //   color: #000;
        // }
      }
    }
  }
  
  /* Update for dialog popup styling */
  .dialog-content {
    border-radius: 15px;
    padding: 2em;
    max-width: 50%;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    margin: 0 auto;
  
    @media (max-width: 1024px) {
      max-width: 60%;
    }
  
    @media (max-width: 768px) {
      max-width: 80%;
      padding: 1.5em;
    }
  
    @media (max-width: 480px) {
      max-width: 90%;
      padding: 1em;
    }
  }
  
  .dialog-header {
    margin-bottom: 1.5em;
    text-align: left;
  }
  
  .dialog-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  
    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }
  
  .dialog-description {
    font-size: 1rem;
    color: #666;
  
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  
    @media (max-width: 480px) {
      font-size: 0.75rem;
    }
  }
  
  /* Flexbox for input field and label alignment */
  .dialog-input-field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1em;
  
    label {
      font-size: 1rem;
      color: #333;
      margin-right: 1em;
      width: 30%;
      text-align: left;
      font-weight: bold;
  
      @media (max-width: 768px) {
        font-size: 0.875rem;
      }
  
      @media (max-width: 480px) {
        font-size: 0.75rem;
        margin-right: 0.5em;
      }
    }
  
    input,
    select {
      width: 65%;
      padding: 0.75em;
      font-size: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-sizing: border-box;
      transition: border-color 0.3s ease;
  
      &:focus {
        border-color: #007BFF;
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
        outline: none;
      }
  
      @media (max-width: 768px) {
        font-size: 0.875rem;
        padding: 0.625em;
      }
  
      @media (max-width: 480px) {
        font-size: 0.75rem;
        padding: 0.5em;
      }
    }
  }
  
  /* Styling for buttons */
  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    margin-top: 1.5em;
  
    .cancel-button,
    .save-button {
      padding: 0.75em 1.5em;
      font-size: 1rem;
      border-radius: 8px;
      cursor: pointer;
      border: none;
  
      @media (max-width: 768px) {
        font-size: 0.875rem;
        padding: 0.625em 1.25em;
      }
  
      @media (max-width: 480px) {
        font-size: 0.75rem;
        padding: 0.5em 1em;
      }
    }
  
    .cancel-button {
      background-color: #f8f9fa;
      color: #6c757d;
  
    //   &:hover {
    //     background-color: #e2e6ea;
    //   }
    }
  
    .save-button {
      background-color: #0033A0;
      color: white;
  
    //   &:hover {
    //     // background-color: #0056b3;
    //     background-color: #007BFF;
    //   }
    }
  }
  
  /* Floating edit button */
  .floating-edit-button {
    position: absolute;
    top: -25px;
    right: -25px;
    background-color: #0033A0;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
  
    // &:hover {
    //   background-color: #0056b3;
    //   transform: scale(1.1);
    // }
  
    svg {
      font-size: 1.2rem;
    }
  }
  .skills-container,
  .interests-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  
    .skill-badge {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 8px 12px;
      border-radius: 20px;
      background-color: #fff;
      border: 2px solid #0033A0;
      color: #0033A0;
      font-weight: bold;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease;
      font-size: 0.9rem;
      cursor: pointer;
      line-height: 1.2;
      white-space: nowrap;
  
      .skill-input {
        background-color: transparent;
        border: none;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        outline: none;
        cursor: text;
        padding: 0;
        margin-right: 5px;
        width: auto;
        min-width: 50px;
        text-align: center;
      }
  
      .delete-button {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: #fff;
        border-radius: 50%;
        font-size: 1rem;
        color: red;
        cursor: pointer;
        padding: 0;
        margin: 0;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }
  
    //   &:hover {
    //     background-color: #e6e6e6;
    //   }
    }
  }
  
  .add-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    background-color: #0033A0;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    // &:hover {
    //   background-color: #0056b3;
    // }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .account-info-container {
      flex-direction: column;
  
      .profile-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
  
        .left-section {
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }
  
        .profile-picture {
          margin-bottom: 15px;
          margin-right: 0;
        }
      }
  
      .input-field {
        margin-bottom: 20px;
      }
  
      .section {
        padding: 15px;
      }
    }
  }
  
  .date-range-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  
    .react-datepicker-wrapper {
      width: auto;
    }
  
    .current-position-checkbox {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      
      input[type="checkbox"] {
        width: auto;
      }
      
      label {
        margin: 0;
        font-size: 0.9rem;
      }
    }
  }
  
.portfolio-container {
  display: flex;
  min-height: 100vh;
  background-color: #fff;
  font-family: var(--font-geist-sans), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: "ss01" 1, "ss02" 1, "cv01" 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.011em;
}

.portfolio-content {
  flex-grow: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.portfolio-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background: linear-gradient(to right, #ffffff 0%, #d6d8ff 95%);
  margin-bottom: 40px;
  border-radius: 10px;
  width: 100%;
  position: relative;
  border: 1px solid #000;

  .portfolio-left-section {
    display: flex;
    align-items: flex-end;
    gap: 50px;

    .portfolio-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;

      .portfolio-picture {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        border: 4px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e6e6ff;
        margin-bottom: 0;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .default-icon {
          font-size: var(--font-scale-2xl);
          color: #333;
          font-weight: 450;
          font-family: 'Inter', sans-serif !important;
          user-select: none;
          line-height: 1;
          letter-spacing: -0.025em;
          font-feature-settings: "ss01" 1, "ss02" 1, "cv01" 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
        }
      }

      h1 {
        font-size: var(--font-scale-2xl);
        margin: 0;
        margin-top: 8px;
        font-weight: 450 !important;
        letter-spacing: -0.02em;
        line-height: 1.2;
        color: #000;
        font-family: 'Inter', sans-serif !important;
      }
    }

    .portfolio-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 0;

      .portfolio-skills-section {
        display: flex;
        gap: 10px;
        margin-bottom: 16px;
      }

      .portfolio-experience-location {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 4px;

        .portfolio-info-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .portfolio-info-value {
            font-weight: bold;
            color: #000;
            margin-bottom: 2px;
          }

          .portfolio-info-label {
            font-weight: normal;
            color: #000;
          }
        }

        .portfolio-divider {
          height: 40px;
          width: 2px;
          background-color: #000;
          margin: 0 20px;
          align-self: center;
        }
      }
    }
  }

  .portfolio-right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-width: 200px;
    padding-bottom: 10px;
    align-self: flex-end;
    margin-top: auto;

    .portfolio-social-icons {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      width: 100%;
      margin-bottom: 15px;

      a {
        font-size: 1.8rem;
        color: #0033A0;
        text-decoration: none;
      }
    }

    .resume-button {
      width: 100%;
      min-width: 200px;
      max-width: 200px;
      padding: 12px 20px;
      font-size: var(--font-scale-base);
      color: #0033A0;
      background-color: #fff;
      border: 2px solid #0033A0;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-weight: 500;
      letter-spacing: -0.01em;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: #0033A0;
        color: #ffffff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      &.loading {
        opacity: 0.7;
        cursor: not-allowed;
        position: relative;
        
        &:after {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
          border: 2px solid transparent;
          border-top-color: currentColor;
          border-radius: 50%;
          animation: spin 0.8s linear infinite;
        }
      }
    }
  }
}

.portfolio-section {
  margin-bottom: 30px;
  width: 100%;

  .portfolio-section-title {
    font-size: var(--font-scale-xl);
    color: #333;
    margin-bottom: 20px;
    padding: 0 20px 8px 20px;
    position: relative;
    font-weight: 600;
    letter-spacing: -0.015em;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, transparent 0%, #ccc 2%, #ccc 98%, transparent 100%);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.portfolio-education-item,
.portfolio-experience-item,
.portfolio-project-item {
  margin-bottom: 25px;
  padding: 0 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .portfolio-info-content {
    .portfolio-item-title {
      font-size: var(--font-scale-lg);
      font-weight: 600;
      color: #000;
      letter-spacing: -0.01em;
      line-height: 1.3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;

      .portfolio-item-date {
        font-size: var(--font-scale-base) !important;
        font-weight: 600;
        color: #000;
      }
    }

    .portfolio-item-subtitle {
      font-size: var(--font-scale-base);
      color: #000;
      line-height: 1.4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .portfolio-item-gpa {
        font-size: var(--font-scale-base) !important;
        font-weight: 600;
        color: #000;
      }

      &.portfolio-item-location {
        font-size: var(--font-scale-base) !important;
        font-weight: 600;
        color: #000;
        text-align: right;
      }
    }

    ul.portfolio-item-subtitle {
      padding-left: 24px;
      margin-top: 10px;
      list-style-type: disc;
      display: block;
      
      li {
        margin-bottom: 8px;
        line-height: 1.5;
        font-size: var(--font-scale-base);
        color: #000;

        @media (max-width: 768px) {
          font-size: var(--font-scale-sm);
          line-height: 1.3;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.portfolio-skill-badge {
  background-color: #fff;
  border: 2px solid #0033A0;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: var(--font-scale-xs);
  color: #0033A0;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: -0.005em;
  line-height: 1.2;
}

.portfolio-skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .portfolio-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;

    .portfolio-left-section {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 100%;

      .portfolio-info {
        margin-bottom: 15px;
      }

      .portfolio-details {
        width: 100%;
        align-items: center;

        .portfolio-skills-section {
          justify-content: center;
        }

        .portfolio-experience-location {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .portfolio-right-section {
      justify-content: center;
      margin-top: 15px;
      
      .portfolio-social-icons {
        justify-content: center;
        margin-bottom: 15px;
      }

      .resume-button {
        width: auto;
        min-width: 160px;
        max-width: 200px;
      }
    }
  }

  .portfolio-section {
    .portfolio-section-title {
      padding: 0 0 8px 0;

      &::after {
        width: 100%;
        left: 0;
      }
    }
  }

  .portfolio-education-item,
  .portfolio-experience-item,
  .portfolio-project-item {
    padding: 0;

    .portfolio-info-content {
      .portfolio-item-title {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        .portfolio-item-date {
          font-size: var(--font-scale-sm) !important;
        }
      }

      .portfolio-item-subtitle {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }
    }
  }

  .portfolio-skills-container {
    padding: 0;
  }
}

@keyframes spin {
  0% { transform: translateY(-50%) rotate(0deg); }
  100% { transform: translateY(-50%) rotate(360deg); }
}
  