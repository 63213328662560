// buttons.scss

/* Button styles */
.cta-btn {
  padding: 18px 50px;
  border-radius: 10px;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.15);

  /* White button styles */
  &.white {
    background-color: #f6f8fa;
    color: #0d1117;

    &:hover {
      transform: scale(1.08);
      box-shadow: 0 12px 25px rgba(255, 255, 255, 0.2);
    }
  }

  /* Bordered button styles */
  &.bordered {
    background: transparent;
    border: 2px solid #c9d1d9;
    color: #c9d1d9;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      transform: scale(1.02);
      box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
    }
  }
}


.primary-btn{
  background-color: "#0033a0";
  border-radius: 20px;
}