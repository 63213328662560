.landing-pricing-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem;
  margin-top: 80px;
  background: #ffffff;
}

.landing-pricing-wrapper {
  width: 100%;

  h1 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #0F0F0F;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .landing-pricing-tier-title {
    font-size: 1.75rem;
    color: #0F0F0F;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .landing-pricing-description {
    text-align: center;
    color: #64748b;
    margin-bottom: 3rem;
    font-size: 1.125rem;
    line-height: 1.6;
  }

  .landing-billing-toggle {
    width: fit-content;
    display: inline-flex;
    background: #F5F5F5;
    padding: 0.25rem;
    border-radius: 6rem;
    margin: 0 auto 4rem auto;
    display: block;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      height: calc(100% - 8px);
      width: calc(50% - 4px);
      background: #0033A0;
      border-radius: 5rem;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateX(0);
      box-shadow: 0 2px 4px rgba(0, 51, 160, 0.2);
    }

    &[data-active="monthly"]::before {
      transform: translateX(0);
    }

    &[data-active="quarterly"]::before {
      transform: translateX(100%);
    }

    .landing-toggle-btn {
      position: relative;
      padding: 0.75rem 2rem;
      border: none;
      background: none;
      color: #64748b;
      cursor: pointer;
      transition: all 0.3s ease;
      font-weight: 500;
      font-size: 0.95rem;
      z-index: 1;
      width: 50%;
      white-space: nowrap;
      min-width: 120px;

      &.active {
        color: #ffffff;
        font-weight: 600;
      }

      &:hover:not(.active) {
        color: #0f172a;
      }
    }
  }

  .landing-pricing-cards {
    display: flex;
    gap: 2rem;
    justify-content: center;
    
    .landing-pricing-card {
      background: white;
      border-radius: 0.5rem;
      padding: 2.5rem;
      width: 100%;
      max-width: 380px;
      text-align: left;
      border: 1px solid #e2e8f0;
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 650px;

      &.landing-pro {
        background: #ffffff;
        border: 1px solid #0033A0;
        box-shadow: 0 4px 6px -1px rgba(0, 51, 160, 0.05), 0 2px 4px -1px rgba(0, 51, 160, 0.03);
      }

      .landing-card-header {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #f1f5f9;

        .landing-billing-info {
          color: #64748b;
          font-size: 0.875rem;
          margin-bottom: 2rem;
        }

        .landing-signup-button {
          margin-top: 1rem;
        }

        .landing-button-container {
          margin-top: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.75rem;

          .landing-no-card-text {
            color: #64748b;
            font-size: 0.875rem;
          }

          .landing-placeholder-text {
            display: block;
            height: 1.25rem;
            visibility: hidden;
          }
        }
      }

      .landing-pricing-description {
        text-align: left;
        color: #64748b;
        margin-bottom: 1.5rem;
        font-size: 1rem;
      }

      .landing-price {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: flex-end;
        gap: 0.25rem;

        .landing-amount {
          font-size: 3.5rem;
          font-weight: 700;
          color: #0f172a;
          line-height: 1;
        }

        .landing-period {
          color: #64748b;
          font-size: 1.125rem;
          margin-bottom: 0.5rem;
        }
      }

      .landing-billing-info {
        color: #64748b;
        font-size: 0.875rem;
        min-height: 1.25rem;
        margin-bottom: 0.75rem;
      }

      .landing-features {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          color: #475569;
          margin-bottom: 1rem;
          font-size: 1rem;

          &.landing-placeholder-feature {
            visibility: hidden;
            height: 24px;
            margin-bottom: 1rem;
          }

          .landing-check-icon {
            color: #0033A0;
            font-size: 1rem;
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            stroke-width: 2px;

            &.landing-gift {
              color: #0033A0;
              font-size: 1.125rem;
            }
          }

          &:first-child {
            color: #0033A0;
            font-weight: 500;

            &.landing-placeholder-feature {
              color: transparent;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .landing-signup-button {
        display: inline-block;
        width: 100%;
        padding: 0.875rem;
        border-radius: 0.375rem;
        text-align: center;
        font-weight: 500;
        transition: all 0.2s ease;
        text-decoration: none;

        &.landing-primary {
          background: #0033A0;
          color: white;
          border: 1px solid #0033A0;

          &:hover {
            background: #002880;
            border-color: #002880;
          }
        }

        &.landing-secondary {
          background: white;
          color: #0f172a;
          border: 1px solid #e2e8f0;

          &:hover {
            border-color: #0033A0;
            color: #0033A0;
          }
        }
      }
    }
  }
}

// Responsive design
@media (max-width: 768px) {
  .landing-pricing-page {
    padding: 2rem 1rem;

    .landing-pricing-wrapper {
      h1 {
        font-size: 2.5rem;
      }

      .landing-pricing-cards {
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .landing-pricing-card {
          max-width: 100%;
          min-height: auto;
          grid-template-rows: auto auto auto auto;
        }
      }

      .landing-billing-toggle {
        .landing-toggle-btn {
          padding: 0.75rem 2rem;
        }
      }
    }
  }
}

