.newJobBenefitsContainer {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 165/84;
  margin: 0 auto;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  max-width: 400px;

  @media screen and (max-width: 1024px) {
    max-width: 350px;
  }

  @media screen and (max-width: 768px) {
    aspect-ratio: 16/9;
    max-width: 300px;
  }

  :global(.newJobBenefitsBackground) {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(0.9);
  }
}

// Add smooth transitions for SVG elements
:global {
  .newJobBenefitsBackground {
    svg {
      g {
        transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
        transform-origin: center center;
        will-change: transform, opacity;
        
        path {
          transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
        }
      }
    }
  }
} 