@import './landingPage.scss'; // Import landingPage.scss

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Roboto:wght@300&display=swap'); // Added Roboto for the right-side text

/* sign-up section */
.signup-section {
  background-color: #0033a0;
  color: #ffffff;
  padding: 44px 0;
  margin: 72px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  width: 100%;
  position: relative;
  
  @media screen and (min-width: 1025px) {
    min-height: 300px;
    overflow: hidden;
    padding-right: 0;
  }
  
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 48px 0;
    position: static;
  }
  
  @media screen and (max-width: 768px) {
    padding: 32px 0;
    border-radius: 16px;
  }
}

.signup-content {
  padding: 0 80px;
  flex: 1;

  @media screen and (min-width: 1025px) {
    max-width: 60%;
    padding-right: 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 40px;
    text-align: center;
    
    .cta-btn {
      margin: 16px auto 8px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 24px 0;
    
    .cta-btn {
      margin: 16px auto 8px;
    }
  }

  h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.1;
    margin-bottom: 16px;
    max-width: 600px;

    @media screen and (max-width: 1024px) {
      font-size: 40px;
      margin: 0 auto 16px;
    }

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 12px;
    }
  }

  p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    margin-bottom: 32px;
    max-width: 600px;
    opacity: 0.9;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
      margin: 0 auto 24px;
    }

    @media screen and (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 20px;
      line-height: 1.5;
    }
  }

  .cta-btn {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    padding: 0.875rem 1.75rem;
    background-color: #0033a0;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 40px;
    margin-bottom: 32px;
    display: inline-block;

    &:hover {
      background-color: #ffffff;
      color: #0033a0;
    }

    @media screen and (max-width: 1024px) {
      margin: 16px auto;
      display: inline-block;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 0.75rem 1.5rem;
      margin-bottom: 0;
    }
  }
}

/* Right-side carousel for feature rotation */
.features-carousel {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: relative;
  padding-right: 80px;
  max-width: 600px;

  @media screen and (min-width: 1025px) {
    position: absolute;
    right: 0;
    width: 50%;
    max-width: none;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 48px 20px 16px;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    position: relative;
    margin-top: 16px;
    transform: translateY(20px);
  }

  @media screen and (max-width: 768px) {
    padding: 24px 20px 0;
    transform: translateY(20px);
  }

  .feature-item {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 32px;
    opacity: 0;
    position: absolute;
    transition: all 0.8s ease-in-out;
    white-space: nowrap;
    
    @media screen and (min-width: 1025px) {
      right: 80px;
      text-align: right;
      width: auto;
      font-size: 36px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    
    @media screen and (max-width: 1370px) and (min-width: 1025px) {
      right: 60px;
      font-size: 32px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 28px;
      position: relative;
      margin-bottom: 0;
    }

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .feature-item.active {
    opacity: 1;
  }

  .feature-item.inactive {
    opacity: 0;
  }
}

/* Desktop animations */
@media screen and (min-width: 1025px) {
  .features-carousel {
    .feature-item.active {
      animation: desktopScrollIn 0.6s forwards;
    }
    
    .feature-item.inactive {
      animation: desktopScrollOut 0.6s forwards;
    }
  }
  
  @keyframes desktopScrollIn {
    0% {
      opacity: 0;
      transform: translateY(-150%);
    }
    100% {
      opacity: 1;
      transform: translateY(-50%);
    }
  }
  
  @keyframes desktopScrollOut {
    0% {
      opacity: 1;
      transform: translateY(-50%);
    }
    100% {
      opacity: 0;
      transform: translateY(50%);
    }
  }
}

/* Mobile animations */
@media screen and (max-width: 1024px) {
  .features-carousel {
    height: auto;
    min-height: 80px;
    overflow: visible;
    width: 100%;
    padding: 48px 20px 16px;
    position: relative;
    margin-top: 16px;
    user-select: none; /* Prevent text selection on mobile */
    
    .feature-item {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      text-align: center;
      padding: 0 16px;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none; /* Prevent text selection on each feature item */
      border-bottom: none !important; /* Force remove any bottom border */
      text-decoration: none !important; /* Force remove any text decoration */
      -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
      
      &.active {
        animation: mobileScrollIn 0.6s forwards;
        position: absolute;
        top: 0;
        border-bottom: none !important; /* Force remove any bottom border in active state */
      }
      
      &.inactive {
        animation: mobileScrollOut 0.6s forwards;
        position: absolute;
        top: 0;
      }
    }
  }

  @keyframes mobileScrollIn {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes mobileScrollOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
}

/* Animations for scrolling effect */
@keyframes scrollIn {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scrollOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}
