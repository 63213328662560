@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibri.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibrib.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibrii.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('../../../fonts/calibriz.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

.application-resume {
  width: 8.5in;
  max-width: 95%;
  margin: 0 auto;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 20px 40px;
  height: auto;
  overflow: visible;
  min-height: fit-content;
  font-family: 'Calibri', Helvetica, Arial, sans-serif;
  background: white;

  .resume-header {
    text-align: center;
    margin-bottom: 20px;
    word-break: break-word;

    h1 {
      font-size: 18pt;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 1.2;
      color: #000;
    }

    .contact-info {
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      margin-top: 5px;
      flex-wrap: wrap;

      .divider {
        margin: 0 8px;
        color: #000;
        display: inline-block;
        transform: scaleY(1.5);
        line-height: 1;
      }
    }
  }

  section {
    margin-bottom: 20px;

    h2 {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: uppercase;
      border-bottom: 1px solid #000000;
      padding-bottom: 0px;
    }
  }

  .education-section {
    .education-item {
      margin-bottom: 20px;

      .education-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        h3 {
          font-size: 15px;
          font-weight: bold;
        }

        .gpa {
          font-size: 15px;
          text-align: right;
        }
      }

      .degree-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2px 0;

        span {
          font-size: 15px;
        }

        .graduation-date {
          text-align: right;
        }
      }
    }
  }

  .experience-section {
    .experience-item {
      margin-bottom: 20px;

      .experience-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        h3 {
          font-size: 15px;
          font-weight: bold;
        }

        .dates {
          font-size: 15px;
          text-align: right;
        }
      }

      .experience-subheader {
        display: flex;
        justify-content: space-between;
        margin: 2px 0;

        .company-name {
          font-size: 15px;
        }

        .location {
          font-size: 15px;
          text-align: right;
        }
      }

      .bullet-points {
        list-style: disc;
        padding-left: 0;
        margin-top: 5px;
        margin-left: 0.75rem;

        li {
          font-size: 15px;
          line-height: 1.4;
          margin-bottom: 4px;
          color: #000;
          padding-left: 0.2rem;
          list-style-position: outside;
          text-indent: -0.2rem;
        }
      }
    }
  }

  .projects-section {
    .project-item {
      margin-bottom: 20px;

      .project-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        .project-title-container {
          display: flex;
          align-items: center;
          
          h3 {
            font-size: 15px;
            font-weight: bold;
            margin-right: 4px;
          }

          .project-name-separator {
            margin: 0 4px;
            color: #000;
          }

          .project-name {
            font-size: 15px;
            &::before {
              content: "-";
              margin: 0 4px;
            }
          }
        }

        .dates {
          font-size: 15px;
          text-align: right;
        }
      }

      .bullet-points {
        list-style: disc;
        padding-left: 0;
        margin-top: 5px;
        margin-left: 0.75rem;

        li {
          font-size: 15px;
          line-height: 1.4;
          margin-bottom: 4px;
          color: #000;
          padding-left: 0.2rem;
          list-style-position: outside;
          text-indent: -0.2rem;
        }
      }
    }
  }

  .skills-section {
    .skills-content {
      font-size: 15px;
      line-height: 1.4;
      color: #000;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 15px 25px;
    
    .resume-header {
      .contact-info {
        gap: 8px;
        padding: 0 10px;
        
        .divider {
          margin: 0 4px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 10px 15px;
  }
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #666;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #666;

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #0033A0;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 