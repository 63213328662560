@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.navbar {
  position: fixed;
  width: 100%;
  height: 88px;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 200px;

  &.scrolled {
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 1440px) {
    padding: 0 160px;
  }

  @media screen and (max-width: 1370px) {
    padding: 0 120px;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 1100px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

.navbar-container {
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 30px;
  transition: opacity 0.3s ease;

  @media screen and (max-width: 1024px) {
    display: none; // Hide Login and Get Started on smaller screens, they will show in the menu
  }
}

.logo {
  height: 40px;
  width: auto;
  margin-right: 20px;
  transition: height 0.3s ease;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  transition: all 0.3s ease;

  @media screen and (max-width: 1024px) {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: fixed;
      top: 88px;
      left: 0;
      width: 100%;
      height: calc(100vh - 88px);
      background: white;
      overflow: hidden !important;
      -webkit-overflow-scrolling: touch;
      z-index: 999;
    }
  }
}

.nav-list {
  display: flex;
  align-items: center;
  list-style: none;

  @media screen and (max-width: 1024px) {
    display: none;

    &.active {
      display: block;
      width: 100%;
      padding: 0 32px;

      .nav-item {
        width: 100%;
        text-align: left;
        padding: 16px 0;
        margin-left: 0;

        &:not(.mobile-auth-link) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          width: min(436px, calc(100% - 20px));
          margin: 0 auto;
        }
      }

      // Special styling for auth links
      .mobile-auth-link {
        border-bottom: none;
        margin-top: auto;
        text-align: center;
        
        .login-link,
        .get-started {
          width: 100%;
          margin: 8px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.mobile-auth-link {
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 88px;
    left: 0;
    padding: 20px 32px;
    z-index: 1000;
    background: white;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 10%;
      right: 10%;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
    }

    .login-link {
      width: min(436px, calc(100% - 20px));
      margin: 4px auto;
    }

    .get-started {
      width: min(436px, calc(100% - 20px));
      margin: 16px auto 8px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.nav-item {
  margin: 0 15px;

  &.dropdown-container {
    margin-right: 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem;
  }
}

.nav-link {
  color: #0F0F0F;  // Changed from #161617
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 16px;

  &:hover {
    color: #0056d2;
  }
}

.login-link {
  color: #0033A0;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: darken(#0033A0, 20%);
  }
}

.get-started {
  background: transparent;
  border: 2px solid #0033A0;
  color: #0033A0;
  width: 144px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  transition: all 0.3s ease;

  &:hover {
    background: #0033A0;
    color: #ffffff;
  }
}

.menu-icon {
  display: none;
  width: 24px;
  height: 24px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hamburger-line {
    display: block;
    width: 18px;
    height: 2px;
    background-color: #0F0F0F;
    margin: 2px 0;
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
    transform-origin: center;

    &:nth-child(1) {
      transform: translateY(0);
    }

    &:nth-child(2) {
      opacity: 1;
    }

    &:nth-child(3) {
      transform: translateY(0);
    }
  }

  &.open {
    .hamburger-line {
      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
      }
    }
  }
}

.dropdown-container {
  position: relative;

  .dropdown-trigger {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    
    .dropdown-arrow {
      position: relative;
      top: 0;
      display: inline-block;
      margin-left: 12px;
      transform-origin: center;
      font-size: 14px;
      transition: transform 0.2s ease;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: calc(100% + 8px);
    left: -16px;
    background: white;
    min-width: 220px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.2s ease;
    pointer-events: none;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      pointer-events: auto;
    }

    .dropdown-item {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 14px 16px;
      color: #0F0F0F;
      text-decoration: none;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      transition: color 0.2s ease;

      &:hover {
        background: transparent;
        color: #0056d2;
      }

      .coming-soon-tag {
        position: relative;
        font-size: 0.65rem;
        padding: 1px 6px;
        border-radius: 8px;
        background-color: rgba(0, 51, 160, 0.1);
        color: #0033A0;
        font-weight: 500;
        white-space: nowrap;
        width: fit-content;
        margin-bottom: 4px;
      }

      &.coming-soon {
        cursor: not-allowed;
        pointer-events: none;
        color: #9CA3AF;
        background: transparent;
        
        &::after {
          display: none;
        }
        
        .coming-soon-tag {
          background-color: rgba(0, 51, 160, 0.1);
          color: #0033A0;
          opacity: 1;
        }

        &:hover {
          color: #9CA3AF;
          background: transparent;
        }
      }
    }

    // Default vertical layout for all dropdowns
    display: flex;
    flex-direction: column;
    min-width: 220px;

    .dropdown-item {
      width: 100%;
      padding: 12px 16px;
    }

    // Special two-column layout ONLY for Features dropdown
    &.features-dropdown {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      min-width: 440px;
      gap: 4px;
      padding: 16px;
      
      .dropdown-item {
        white-space: nowrap;
        padding: 12px;
      }
    }

    // Mobile layout adjustments
    @media screen and (max-width: 1024px) {
      &, &.features-dropdown {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        padding: 0;
        
        .dropdown-item {
          padding: 12px 24px 12px 32px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;

    .dropdown-trigger {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .dropdown-arrow {
        margin-left: auto;
        font-size: 16px;
      }
    }

    .dropdown-menu {
      position: static;
      box-shadow: none;
      background: transparent;
      margin: 0;
      padding: 0;
      border-radius: 0;
      border: none;
      width: 100%;
      min-width: unset;
      transform: none;
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease;
      
      &.active {
        height: auto;
        opacity: 1;
        visibility: visible;
      }

      .dropdown-item {
        padding: 12px 24px 12px 32px;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        opacity: 0.9;
        background: transparent;
        transition: color 0.2s ease;

        &:hover {
          background: transparent;
          color: #0056d2;
        }

        &:first-child {
          padding-top: 20px;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
