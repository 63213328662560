.legal-container {
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  color: #374151;
  
  .legal-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: white;
    border-bottom: 1px solid #E5E7EB;
    display: flex;
    align-items: center;
    
    .auth-logo {
      height: 40px;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 16px;
    margin-top: 80px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #1F2937;
    margin: 32px 0 16px;
  }

  p {
    margin-bottom: 16px;
    line-height: 1.6;
    font-size: 16px;
  }

  ul {
    margin: 16px 0;
    padding-left: 24px;

    li {
      margin-bottom: 12px;
      line-height: 1.6;
    }
  }

  a {
    color: #0033A0;
    text-decoration: none;
    transition: color 0.15s ease;

    &:hover {
      color: #002370;
      text-decoration: underline;
    }
  }

  em {
    color: #6B7280;
    font-style: italic;
  }

  strong {
    font-weight: 600;
    color: #111827;
  }
} 