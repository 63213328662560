@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.tutorials-page-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
}

.tutorials-main-content {
  flex: 1;
  overflow-y: auto;
  padding: 24px 48px;
  background-color: #fff;
  margin-left: 80px;
  transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  .sidebar.expanded + & {
    margin-left: 250px;
  }
  
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 16px;
  }
}

.features-tab-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;

  .tab-container {
    padding: 16px 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin-bottom: 24px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tab-list {
    display: flex;
    padding: 4px;
    margin: 0 auto;
    list-style: none;
    align-items: center;
    background-color: #f1f3f4;
    border-radius: 28px;
    width: fit-content;
    justify-content: center;
    box-sizing: border-box;

    @media (max-width: 768px) {
      padding: 3px;
      width: auto;
      overflow-x: auto;
      justify-content: flex-start;
    }
  }

  .tab {
    position: relative;
    padding: 8px 24px;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: #5f6368;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.2s ease;
    font-family: 'Inter', sans-serif;
    border-radius: 100px !important;
    outline: none;
    min-width: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }

    .tab-text {
      position: relative;
      text-align: center;
      width: 100%;
    }

    &:hover {
      color: #202124;
      background-color: rgba(60, 64, 67, 0.08);
      border-radius: 100px !important;
    }

    &.active {
      color: #202124;
      font-weight: 500;
      background-color: white;
      border-radius: 100px !important;
      box-shadow: 0 1px 3px rgba(60, 64, 67, 0.12);
      padding: 8px 24px;
    }

    @media (max-width: 768px) {
      padding: 6px 16px;
      font-size: 13px;
      
      &.active {
        padding: 6px 16px;
      }
    }
  }

  .content-box {
    flex: 1;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    
    &.active {
      display: block;
      opacity: 1;
    }

    &[id="panel-resume"] .video-container,
    &[id="panel-coverLetter"] .video-container,
    &[id="panel-jobSearch"] .video-container,
    &[id="panel-applicationTracker"] .video-container,
    &[id="panel-portfolio"] .video-container {
      background-color: #ffffff;
    }

    .video-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      
      .coming-soon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 400px;
        background-color: #f8f9fa;
        border-radius: 12px;
        margin-top: 20px;
        
        h3 {
          font-size: 24px;
          font-weight: 500;
          color: #202124;
          margin-bottom: 12px;
        }
        
        p {
          font-size: 16px;
          color: #5f6368;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .features-tab-section {
    .tab-list {
      gap: 3px;
      padding: 3px;
    }

    .tab {
      padding: 6px 14px;
      font-size: 12px;
      
      &.active {
        padding: 6px 14px;
      }
    }
  }
}

@media (max-width: 768px) {
  .tutorials-main-content {
    padding: 16px;
    margin-left: 0;
    transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .features-tab-section {
    .tab-container {
      padding: 12px 0 20px;
      justify-content: flex-start;
    }

    .tab {
      padding: 6px 12px;
      font-size: 13px;
    }
  }
  
  .sidebar.expanded + .tutorials-main-content {
    margin-left: 0;
  }
} 